export default class Url {
  static homePath(): string {
    return "/";
  }

  static billPath(billId: string | number): string {
    return `/bills/${billId}`;
  }

  static billNotificationsPath(billId: string | number): string {
    return `/bills/${billId}/bill-notifications`;
  }

  static billsPath(): string {
    return "/my-bills";
  }

  static profilePath(): string {
    return "/profile";
  }

  static referralsPath(): string {
    return "/referrals";
  }

  static logoutPath(): string {
    return "/logout";
  }

  static loginPath(possibleEmail?: string): string {
    if (possibleEmail) {
      return `/?possibleEmail=${possibleEmail}`;
    }
    return "/";
  }

  static signupPath(): string {
    return "/sign-up";
  }

  static insurancePath(providerId: string | number): string {
    return `/insurance?providerId=${providerId}`;
  }

  static paymentsPath(): string {
    return "/payments";
  }

  static paymentsPreferencesPath(): string {
    return "/payments/preferences";
  }

  static paymentMethodPath(): string {
    return `/payment-method`;
  }

  static paymentSetupPath(): string {
    return `/payment-setup`;
  }

  static paymentPlansPath(): string {
    return `/payment-plans`;
  }

  static notificationsPath(): string {
    return `/notifications`;
  }

  static connectedAccountsPath(): string {
    return `/connected-accounts`;
  }

  static connectAnAccountPath(): string {
    return `/connect-an-account`;
  }
  static getStartedPath(): string {
    return `/get-started`;
  }

  static connectedAccountPath(plaidItemId: string | number): string {
    return `/connected-accounts/${plaidItemId}`;
  }

  static connectedAccountProvidersPath(plaidItemId: string | number): string {
    return `/connected-accounts/${plaidItemId}/providers`;
  }

  static onboardingPlaidPath(): string {
    return `/onboarding`;
  }

  static onboardingStandardPath(): string {
    return `/onboarding-2`;
  }
}

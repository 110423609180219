import {
  CameraIcon,
  CheckCircleIcon,
  UploadIcon
} from "@heroicons/react/outline";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import Color from "src/definitions/enums/Color";
import DocumentUploader from "../DocumentUploader";
import { IBillFragment, useUpdateBillMutation } from "../graphql";
interface IProps {
  bill: IBillFragment;
  refetch?: string[];
  helpText?: string;
  subHelpText?: string;
  onComplete?: () => void;
  showExistingDocuments?: boolean;
}

export default function DocumentUploadArea(props: IProps) {
  console.log({ isMobile });

  const {
    bill,
    refetch,
    helpText = isMobile
      ? "Tap here to take a picture or upload a screenshot of your bill"
      : `Drag & drop a ${bill.provider.name} bill statement here`,
    subHelpText = isMobile
      ? "Make sure to include the account holder’s name, account number, and service address, so we can access the account."
      : "Or click anywhere in this box to open a file picker.",
    onComplete,
    showExistingDocuments
  } = props;

  const [documentUrls, setDocumentUrls] = useState<string[]>([]);
  const [updateBillMutation] = useUpdateBillMutation();

  const documentUploaded = async (documentUrl: string) => {
    setDocumentUrls([...documentUrls, documentUrl]);
    const result = await updateBillMutation({
      variables: {
        id: bill.id,
        bill: {
          documentUrls: [documentUrl]
        }
      },
      refetchQueries: refetch
    });

    if (result.data?.payload?.success) {
      console.log("uploaded document to bill");
    }
    // bill.id,
    //   {
    //     documentUrls: [documentUrl]
    //   },
    //   updateBillMutation({variables: {id: bill.id}}),
    //   [...(refetch || [])]
    if (onComplete) {
      onComplete();
    }
  };

  const documentList = documentUrls.map((du) => (
    <li key={du}>
      <div className="flex items-center">
        <CheckCircleIcon className="h-6 w-6 text-bf-green-500" />
        <div>
          <a href={du} className="break-all">
            {du.split("/").pop()}
          </a>
        </div>
      </div>
    </li>
  ));

  const existingDocumentList = bill.documents.map((doc) => (
    <li key={doc.id}>
      <div className="flex items-center">
        <CheckCircleIcon className="h-6 w-6 text-bf-green-500 mr-2" />
        <div className="flex-1">
          <a href={doc.url} className="break-all">
            {doc.filename}
          </a>
        </div>
      </div>
    </li>
  ));

  return (
    <DocumentUploader onUpload={documentUploaded}>
      <motion.div
        id={`documentUploader_${bill.id}`}
        style={{
          height: "200px",
          width: "100%",
          backgroundColor: Color.white700 as any,
          border: "2px dashed var(--text-grey)",
          borderRadius: "8px"
        }}
        className="flex flex-col justify-center items-center p-1 cursor-pointer"
      >
        {!showExistingDocuments && documentUrls.length > 0 && (
          <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
            {documentList}
          </ul>
        )}
        {showExistingDocuments && (
          <React.Fragment>
            <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
              {existingDocumentList}
            </ul>
          </React.Fragment>
        )}
        {documentUrls.length === 0 && bill.documentCount <= 0 && (
          <div className="flex flex-col justify-center items-center p-2">
            {isMobile ? (
              <CameraIcon className="h-12 w-12 text-bf-green-500" />
            ) : (
              <UploadIcon className="h-12 w-12 text-bf-green-500" />
            )}
            <span className={`text-center ${isMobile && "text-blue-500"}`}>
              {helpText}
            </span>
            {subHelpText && (
              <span className="text-sm text-gray-pogo-gray text-center">
                {subHelpText}
              </span>
            )}
          </div>
        )}
      </motion.div>
    </DocumentUploader>
  );
}

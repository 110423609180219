import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  sunken?: boolean;
}

export default function Well({
  children,
  className,
  onClick,
  sunken = false
}: IProps) {
  return (
    <div
      className={`overflow-hidden rounded-lg ${
        sunken && "shadow-inner bg-cool-gray-50"
      } ${className}`}
      onClick={onClick}
    >
      <div className="px-4 py-5 sm:p-3">{children}</div>
    </div>
  );
}

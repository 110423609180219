import { useFindCurrentCustomerQuery } from "src/components/graphql";

export default function useCurrentCustomer() {
  // const authenticationToken = localStorage.getItem("token") || "";

  const { data, loading } = useFindCurrentCustomerQuery({
    // variables: {
    //   authenticationToken
    // }
  });

  return { currentCustomer: data?.customer, loading };
}

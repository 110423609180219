import { ExclamationIcon, LockClosedIcon } from "@heroicons/react/outline";
import pick from "lodash/pick";
import queryString from "query-string";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CombinedLogo from "src/CombinedLogo";
// import { useDispatch } from "react-redux";
// import { Link, useHistory } from "react-router-dom";
// import Errors from "src/components/Errors";
import { required } from "src/components/Forms/InputValidations";
import TextInputField from "src/components/Forms/TextInputField";
import NewButton from "src/components/NewButton";
import NewContainer from "src/components/NewContainer";
import Segment from "src/components/Segment";
import { host, protocol } from "src/config";
import { login } from "src/redux/actions/session";
import SecureRandomHex from "src/utils/SecureRandomHex";

export default function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const [errors, setErrors] = useState<string[] | []>([]);
  const [emailTakenError, setEmailTakenError] = useState(false);
  const [possibleEmail, setPossibleEmail] = useState();

  const initialValues = {
    email: query.email,
    firstName: query.firstName,
    lastName: query.lastName,
    password: `${SecureRandomHex()}Abc!123`,
    partner_referral_code: query.partner_referral_code
  };

  const onSubmit = async (values: any) => {
    setPossibleEmail(values.email);
    const url = `${protocol}://${host}/api/customers`;
    const customerPayload = pick(values, [
      "email",
      "password",
      "referral_data",
      "referred_by_code",
      "partner_referral_code",
      "b2b",
      "used_savings_estimator",
      "estimated_provider",
      "current_monthly_payment",
      "estimated_annual_savings"
    ]);

    const payload = {
      customer: customerPayload,
      traffic_referral_url:
        localStorage.getItem("originalReferrer") || document.referrer
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const result = await response.json();

    if (result.customer) {
      setEmailTakenError(false);
      const { email, authentication_token } = result.customer;
      dispatch(login(email as string, authentication_token as string));

      // dispatch(login(email as string, authentication_token as string));
      history.push("/add-bill");
      // history.push("/get-started");
      if (process.env.NODE_ENV === "production") {
        ReactGA.event({
          category: "Customers",
          action: "create_account",
          label: "email"
        });
        (window as any).fathom.trackGoal("TECO7VN7", 0);
      }
    } else if (result.errors) {
      if (
        result.errors.includes("Email Something went wrong during sign up.")
      ) {
        setEmailTakenError(true);
        setErrors(["Something went wrong during sign up."]);
      } else {
        setErrors(result.errors);
      }
    }
  };

  return (
    <NewContainer className="w-full md:w-5/6">
      <div className="flex items-center justify-between mt-8">
        <div className="flex justify-center md:hidden w-full">
          <CombinedLogo />
        </div>
        <div className="hidden md:block w-full">
          <CombinedLogo />
        </div>
      </div>
      <Segment className="mt-8">
        <div className="grid md:grid-cols-5">
          <div className="col-span-5 flex flex-col">
            <div className="text-lg md:text-xl text-gray-pogo-gray mb-2 md:mb-4">
              Save yourself time and money by having our experts negotiate your
              bills. Get started with no upfront cost.
            </div>
            {emailTakenError && (
              <div className="mt-2 mb-4 text-gray-bf-text-medium flex items-start space-x-2">
                <ExclamationIcon className="h-5 w-5 text-red-600" />
                <div className="flex-1">
                  <span>
                    Something went wrong during sign up. If you already have an
                    account, you can try{" "}
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${protocol}://${process.env.REACT_APP_CUSTOMER_APP_URL}/login?possibleEmail=${possibleEmail}`}
                    className="text-blue-500"
                  >
                    logging in
                  </a>{" "}
                  instead.
                </div>
              </div>
            )}
            {/* {errors.length > 0 && (
              <React.Fragment>

              </React.Fragment>
            )} */}
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <TextInputField
                      label="Email"
                      name="email"
                      placeholder="Type your email here"
                      type="email"
                      validate={required}
                      className="mb-4"
                    />
                    <Field
                      name="password"
                      value={SecureRandomHex()}
                      type="hidden"
                      component="input"
                    />
                    <Field name="firstName" type="hidden" component="input" />
                    <Field name="lastName" type="hidden" component="input" />
                    <Field
                      name="partner_referral_code"
                      type="hidden"
                      component="input"
                    />
                    <Field
                      name="referral_data"
                      type="hidden"
                      component="input"
                    />
                    <NewButton type="submit" fullWidth className="mt-8">
                      Get Started
                    </NewButton>
                  </form>
                );
              }}
            />
            <div className="flex justify-center items-center mt-6">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${protocol}://${process.env.REACT_APP_CUSTOMER_APP_URL}/login?possibleEmail=${possibleEmail}`}
                className="text-blue-500"
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </Segment>
      <div className="flex flex-col items-center justify-center mt-6">
        <span className="text-xs text-gray-bf-text-light mb-4">
          By signing up, you're agreeing to
          <a
            href="https://billfixers.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500"
          >
            &nbsp;our terms
          </a>
          .
        </span>
        <div className="flex items-center">
          <LockClosedIcon className="h-6 w-6 text-gray-bf-text-light mr-2" />
          <span className="text-gray-bf-text-light text-center text-sm">
            We use bank-level 256 bit encryption and we will never sell your
            personal information.
          </span>
        </div>
      </div>
    </NewContainer>
  );
}

import { PlusIcon } from "@heroicons/react/outline";
import React from "react";
import Logo from "./components/Logo";
import PogoLogo from "./PogoLogo";

export default function CombinedLogo() {
  return (
    <div className="flex items-center space-x-4">
      <Logo width={100} />
      <PlusIcon className="h-6 w-6 text-gray-600" />
      <PogoLogo width={100 * 0.677} />
    </div>
  );
}

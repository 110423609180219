import React from "react";
import { Field } from "react-final-form";
import "./TextAreaField.css";
// import Errors from "src/components/Errors";

interface IProps {
  name: string;
  className?: string;
  style?: any;
  validate?: any;
  label?: string;
  placeholder?: string;
}

export default function TextAreaField(props: IProps) {
  const { name, className, style, validate, label, placeholder } = props;

  return (
    <Field
      name={name}
      validate={validate}
      render={({ input, meta }) => {
        return (
          <div className="TextAreaField">
            {/* {meta.error && <Errors className="mb-4" errors={[meta.error]} />} */}
            {label && <label>{label}</label>}
            <textarea
              className={`${className}`}
              style={style}
              placeholder={placeholder}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              onChange={(value) => input.onChange(value)}
              value={input.value}
            />
          </div>
        );
      }}
    />
  );
}

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents a date in ISO 8601 format */
  Date: string;
  /** Represents date/time in iso8601 format */
  DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
  /** Represents money value */
  Money: number;
};

/** Autogenerated input type of AcceptOffer */
export type IAcceptOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of AcceptOffer */
export type IAcceptOfferPayload = {
  __typename: "AcceptOfferPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of AcceptPitch */
export type IAcceptPitchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of AcceptPitch */
export type IAcceptPitchPayload = {
  __typename: "AcceptPitchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  pitch?: Maybe<IPitch>;
  success: Scalars["Boolean"];
};

export type IAccount = {
  __typename: "Account";
  balance: Scalars["Money"];
  createdAt: Scalars["DateTime"];
  endsOn: Scalars["Date"];
  id: Scalars["ID"];
  item?: Maybe<INegotiationItem>;
  itemAnnualSavings: Scalars["Money"];
  itemMonthlySavings: Scalars["Money"];
  monthlyPaymentAmount: Scalars["Money"];
  monthlyPaymentAmountWithDiscounts: Scalars["Money"];
  monthsRemaining: Scalars["Int"];
  name: Scalars["String"];
  providerId?: Maybe<Scalars["ID"]>;
  providerLogo?: Maybe<IImage>;
  providerName?: Maybe<Scalars["String"]>;
  shortName: Scalars["String"];
  transactions: Array<IAccountTransaction>;
  updatedAt: Scalars["DateTime"];
};

/** The connection type for Account. */
export type IAccountConnection = {
  __typename: "AccountConnection";
  /** A list of edges. */
  edges: Array<IAccountEdge>;
  /** A list of nodes. */
  nodes: Array<IAccount>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
  totalItemAnnualSavings: Scalars["Money"];
  totalItemMonthlySavings: Scalars["Money"];
  totalOtcSavings: Scalars["Money"];
};

/** An edge in a connection. */
export type IAccountEdge = {
  __typename: "AccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<IAccount>;
};

export type IAccountTransaction = {
  __typename: "AccountTransaction";
  amount: Scalars["Money"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  transactionType: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type IAmortizationStat = {
  __typename: "AmortizationStat";
  billfixersCharge: Scalars["Money"];
  month: Scalars["String"];
  totalSavings: Scalars["Money"];
};

export type IArcadiaPowerReferral = {
  __typename: "ArcadiaPowerReferral";
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  providerId: Scalars["ID"];
};

export type IBankAccount = {
  __typename: "BankAccount";
  accountHolderName?: Maybe<Scalars["String"]>;
  acknowledged: Scalars["Boolean"];
  bankName: Scalars["String"];
  customerId: Scalars["ID"];
  default: Scalars["Boolean"];
  fingerprint: Scalars["String"];
  id: Scalars["ID"];
  last4: Scalars["String"];
  plaidItemId?: Maybe<Scalars["ID"]>;
  plaidItemInstitutionLogo?: Maybe<Scalars["String"]>;
  plaidItemInstitutionName?: Maybe<Scalars["String"]>;
  stripeId: Scalars["String"];
};

export type IBill = {
  __typename: "Bill";
  allowsContract: Scalars["Boolean"];
  assumedPlaidTransactionAccountName?: Maybe<Scalars["String"]>;
  autoRenegotiate: Scalars["Boolean"];
  badDocument: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  currentFixer?: Maybe<IUser>;
  currentSavings: Scalars["Money"];
  deliveringReminderEmails: Scalars["Boolean"];
  documentCount: Scalars["Int"];
  documentless: Scalars["Boolean"];
  documents: Array<IDocument>;
  earnedSavings: Scalars["Money"];
  futureSavings: Scalars["Money"];
  grossEarnedSavings: Scalars["Money"];
  grossSavings: Scalars["Money"];
  hasDocumentOrEquivalentInfo: Scalars["Boolean"];
  id: Scalars["ID"];
  informationRequests: Array<IInformationRequest>;
  initialNotes?: Maybe<Scalars["String"]>;
  items: Array<IItem>;
  last4ssn?: Maybe<Scalars["String"]>;
  limitedSavingsOpportunity: Scalars["Boolean"];
  messages: Array<IMessage>;
  monthlySavings: Scalars["Money"];
  needsWirelessScreener: Scalars["Boolean"];
  negotiations: Array<INegotiation>;
  negotiationsCount: Scalars["Int"];
  nextExpectedSavings?: Maybe<Scalars["Money"]>;
  nextRenegotiateOn?: Maybe<Scalars["Date"]>;
  nextSavingsDate?: Maybe<Scalars["Date"]>;
  offers: Array<IOffer>;
  otcOnly: Scalars["Boolean"];
  otcSavings: Scalars["Money"];
  passcode?: Maybe<Scalars["String"]>;
  plaidItemId?: Maybe<Scalars["ID"]>;
  plaidItemInstitutionLogo?: Maybe<Scalars["String"]>;
  plaidItemInstitutionName?: Maybe<Scalars["String"]>;
  provider: IProvider;
  providerBillFields: Array<IProviderBillField>;
  providerId: Scalars["ID"];
  readyToNegotiate: Scalars["Boolean"];
  requestingConsentMessage?: Maybe<IMessage>;
  savingsEndOn?: Maybe<Scalars["Date"]>;
  savingsStartOn?: Maybe<Scalars["Date"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
  title: Scalars["String"];
  totalSavings: Scalars["Money"];
  updatedAt: Scalars["DateTime"];
  wirelessScreener?: Maybe<IWirelessScreener>;
};

/** Attributes for creating or updating a bill */
export type IBillAttributes = {
  allowsContract?: Maybe<Scalars["Boolean"]>;
  autoRenegotiate?: Maybe<Scalars["Boolean"]>;
  badDocument?: Maybe<Scalars["Boolean"]>;
  createdVia?: Maybe<Scalars["String"]>;
  documentUrls?: Maybe<Array<Scalars["String"]>>;
  documentless?: Maybe<Scalars["Boolean"]>;
  documents?: Maybe<Array<IDocumentAttributes>>;
  initialNotes?: Maybe<Scalars["String"]>;
  last4ssn?: Maybe<Scalars["String"]>;
  passcode?: Maybe<Scalars["String"]>;
  providerId?: Maybe<Scalars["ID"]>;
  providerSpecificFields?: Maybe<Scalars["JSON"]>;
  readyToNegotiate?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated input type of CancelBill */
export type ICancelBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  explanation?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CancelBill */
export type ICancelBillPayload = {
  __typename: "CancelBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of ChangeCustomerPassword */
export type IChangeCustomerPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

/** Autogenerated return type of ChangeCustomerPassword */
export type IChangeCustomerPasswordPayload = {
  __typename: "ChangeCustomerPasswordPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of ConnectPlaidAch */
export type IConnectPlaidAchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  plaidItemId: Scalars["ID"];
};

/** Autogenerated return type of ConnectPlaidAch */
export type IConnectPlaidAchPayload = {
  __typename: "ConnectPlaidAchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateArcadiaPowerReferral */
export type ICreateArcadiaPowerReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  providerId: Scalars["ID"];
  zipcode: Scalars["String"];
};

/** Autogenerated return type of CreateArcadiaPowerReferral */
export type ICreateArcadiaPowerReferralPayload = {
  __typename: "CreateArcadiaPowerReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateBillFromPlaid */
export type ICreateBillFromPlaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  createdVia: Scalars["String"];
  plaidItemId: Scalars["ID"];
  providerId: Scalars["ID"];
};

/** Autogenerated return type of CreateBillFromPlaid */
export type ICreateBillFromPlaidPayload = {
  __typename: "CreateBillFromPlaidPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateBill */
export type ICreateBillInput = {
  allowDuplicate?: Maybe<Scalars["Boolean"]>;
  bill: IBillAttributes;
  checkForDuplicates?: Maybe<Scalars["Boolean"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateBill */
export type ICreateBillPayload = {
  __typename: "CreateBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  duplicateBillId?: Maybe<Scalars["ID"]>;
  duplicateFound?: Maybe<Scalars["Boolean"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateBillsFromPlaid */
export type ICreateBillsFromPlaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  plaidItemId: Scalars["ID"];
  providerIds: Array<Scalars["ID"]>;
};

/** Autogenerated return type of CreateBillsFromPlaid */
export type ICreateBillsFromPlaidPayload = {
  __typename: "CreateBillsFromPlaidPayload";
  bills?: Maybe<Array<IBill>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateCreditCardAndMakePayment */
export type ICreateCreditCardAndMakePaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  creditCard: ICreditCardAttributes;
  generateAndPayInvoice?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateCreditCardAndMakePayment */
export type ICreateCreditCardAndMakePaymentPayload = {
  __typename: "CreateCreditCardAndMakePaymentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  creditCard?: Maybe<ICreditCard>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateCreditCard */
export type ICreateCreditCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  creditCard: ICreditCardAttributes;
  paymentPlan?: Maybe<Scalars["String"]>;
  paymentPlanChosen?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateCreditCard */
export type ICreateCreditCardPayload = {
  __typename: "CreateCreditCardPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  creditCard?: Maybe<ICreditCard>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateCustomerAcquisitionSurvey */
export type ICreateCustomerAcquisitionSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customerAcquisitionSurvey: ICustomerAcquisitionSurveyAttributes;
};

/** Autogenerated return type of CreateCustomerAcquisitionSurvey */
export type ICreateCustomerAcquisitionSurveyPayload = {
  __typename: "CreateCustomerAcquisitionSurveyPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomer;
  customerAcquisitionSurvey?: Maybe<ICustomerAcquisitionSurvey>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateDocument */
export type ICreateDocumentInput = {
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  document: IDocumentAttributes;
};

/** Autogenerated return type of CreateDocument */
export type ICreateDocumentPayload = {
  __typename: "CreateDocumentPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  document?: Maybe<IDocument>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateElectricityReferral */
export type ICreateElectricityReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  providerId: Scalars["ID"];
  referredTo: Scalars["String"];
  successful?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateElectricityReferral */
export type ICreateElectricityReferralPayload = {
  __typename: "CreateElectricityReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  referral?: Maybe<IElectricityReferral>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateInsuranceReferral */
export type ICreateInsuranceReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  providerId: Scalars["ID"];
  referredTo: Scalars["String"];
  successful?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateInsuranceReferral */
export type ICreateInsuranceReferralPayload = {
  __typename: "CreateInsuranceReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  referral?: Maybe<IInsuranceReferral>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateMessage */
export type ICreateMessageInput = {
  billId: Scalars["ID"];
  body: Scalars["String"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateMessage */
export type ICreateMessagePayload = {
  __typename: "CreateMessagePayload";
  bill: IBill;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  message?: Maybe<IMessage>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreatePayment */
export type ICreatePaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  payment: IPaymentAttributes;
};

/** Autogenerated return type of CreatePayment */
export type ICreatePaymentPayload = {
  __typename: "CreatePaymentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  payment?: Maybe<ICreditCard>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreatePlaidItem */
export type ICreatePlaidItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  metaData: IPlaidItemAttributes;
  publicToken: Scalars["String"];
};

/** Autogenerated return type of CreatePlaidItem */
export type ICreatePlaidItemPayload = {
  __typename: "CreatePlaidItemPayload";
  accessToken?: Maybe<Scalars["String"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  duplicateItem: Scalars["Boolean"];
  errors: Array<Scalars["String"]>;
  plaidItem?: Maybe<IPlaidItem>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreatePlaidLinkToken */
export type ICreatePlaidLinkTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreatePlaidLinkToken */
export type ICreatePlaidLinkTokenPayload = {
  __typename: "CreatePlaidLinkTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  linkToken: Scalars["String"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateSurvey */
export type ICreateSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  survey: ISurveyAttributes;
};

/** Autogenerated return type of CreateSurvey */
export type ICreateSurveyPayload = {
  __typename: "CreateSurveyPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  survey?: Maybe<ISurvey>;
};

/** Autogenerated input type of CreateWirelessScreener */
export type ICreateWirelessScreenerInput = {
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  wirelessScreener: IWirelessScreenerAttributes;
};

/** Autogenerated return type of CreateWirelessScreener */
export type ICreateWirelessScreenerPayload = {
  __typename: "CreateWirelessScreenerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  wirelessScreener?: Maybe<IWirelessScreener>;
};

export type ICreditCard = {
  __typename: "CreditCard";
  brand: Scalars["String"];
  customerId: Scalars["ID"];
  default: Scalars["Boolean"];
  expMonth: Scalars["Int"];
  expYear: Scalars["Int"];
  expired: Scalars["Boolean"];
  id?: Maybe<Scalars["ID"]>;
  last4: Scalars["String"];
  name: Scalars["String"];
  obfuscatedNumber: Scalars["String"];
  stripePaymentMethodId: Scalars["String"];
  token?: Maybe<Scalars["String"]>;
};

/** Attributes for updating or creating a credit card */
export type ICreditCardAttributes = {
  brand: Scalars["String"];
  expMonth: Scalars["Int"];
  expYear: Scalars["Int"];
  last4: Scalars["String"];
  stripePaymentMethodId: Scalars["String"];
  token?: Maybe<Scalars["String"]>;
};

export type ICustomer = {
  __typename: "Customer";
  allTimeEarnedSavings: Scalars["Money"];
  allTimeGrossSavings: Scalars["Money"];
  allTimeSavings: Scalars["Money"];
  amortizationStats: Array<IAmortizationStat>;
  answeredAcquisitionSurvey: Scalars["Boolean"];
  apiId: Scalars["ID"];
  arcadiaPowerReferrals: Array<IArcadiaPowerReferral>;
  b2b: Scalars["Boolean"];
  balance: Scalars["Money"];
  bankAccounts: Array<IBankAccount>;
  billCount: Scalars["Int"];
  billServices: Array<Scalars["String"]>;
  bills: Array<IBill>;
  creditCards: Array<ICreditCard>;
  defaultBankAccount?: Maybe<IBankAccount>;
  defaultCreditCard?: Maybe<ICreditCard>;
  defaultPaymentMethodType?: Maybe<Scalars["String"]>;
  discount: Scalars["Float"];
  discountedRate: Scalars["Float"];
  electricityReferrals: Array<IElectricityReferral>;
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  hasAllReadyBills: Scalars["Boolean"];
  hasAtLeastOnePayment: Scalars["Boolean"];
  hasBills: Scalars["Boolean"];
  hasBillsMissingInfo: Scalars["Boolean"];
  hasDefaultPaymentMethod: Scalars["Boolean"];
  hasExpiredDefaultCard: Scalars["Boolean"];
  hasInvoices: Scalars["Boolean"];
  hasNegotiableBill: Scalars["Boolean"];
  hasPlaidItems: Scalars["Boolean"];
  hasPlaidTransactions: Scalars["Boolean"];
  hasPotentialAchPaymentMethod: Scalars["Boolean"];
  hasReadyBills: Scalars["Boolean"];
  hasUnpaidInvoices: Scalars["Boolean"];
  id: Scalars["ID"];
  insuranceReferrals: Array<IInsuranceReferral>;
  lastName?: Maybe<Scalars["String"]>;
  lastSurveyedOn?: Maybe<Scalars["Date"]>;
  legacyBilling: Scalars["Boolean"];
  monthlySavings: Scalars["Money"];
  name?: Maybe<Scalars["String"]>;
  nextMonthlyInvoiceAmount: Scalars["Money"];
  nextPaymentDate?: Maybe<Scalars["String"]>;
  onboarding: Scalars["Boolean"];
  partner?: Maybe<IPartner>;
  paymentPlan: Scalars["String"];
  paymentPlanChosen: Scalars["Boolean"];
  phoneNumber?: Maybe<Scalars["String"]>;
  plaidItems: Array<IPlaidItem>;
  readyBillCount: Scalars["Int"];
  referralAmountPaid: Scalars["Money"];
  referralCode?: Maybe<Scalars["String"]>;
  referralCount: Scalars["Int"];
  referredByCode?: Maybe<Scalars["String"]>;
  regularMonthlyPaymentAmount: Scalars["Money"];
  requirePaymentMethod: Scalars["Boolean"];
  stripeBankAccountToken?: Maybe<Scalars["String"]>;
  stripeId?: Maybe<Scalars["String"]>;
  trafficReferralUrl?: Maybe<Scalars["String"]>;
  unpaidInvoiceAmount: Scalars["Money"];
  unpaidInvoices: Array<IInvoice>;
  vip: Scalars["Boolean"];
};

export type ICustomerAcquisitionSurvey = {
  __typename: "CustomerAcquisitionSurvey";
  channel: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["ID"];
  details?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

/** Attributes for creating or updating a customer acquisition survey */
export type ICustomerAcquisitionSurveyAttributes = {
  channel: Scalars["String"];
  details?: Maybe<Scalars["String"]>;
};

/** Attributes for updating a customer */
export type ICustomerAttributes = {
  avatarUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  nextPaymentDate?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  passwordConfirmation?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

/** Autogenerated input type of DeleteBankAccount */
export type IDeleteBankAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the bank account to be deleted */
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteBankAccount */
export type IDeleteBankAccountPayload = {
  __typename: "DeleteBankAccountPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id: Scalars["ID"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of DeleteCreditCard */
export type IDeleteCreditCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the credit card to be deleted */
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteCreditCard */
export type IDeleteCreditCardPayload = {
  __typename: "DeleteCreditCardPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id: Scalars["ID"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of DeleteElectricityReferral */
export type IDeleteElectricityReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the electricity referral to be deleted */
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteElectricityReferral */
export type IDeleteElectricityReferralPayload = {
  __typename: "DeleteElectricityReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id: Scalars["ID"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of DeleteInsuranceReferral */
export type IDeleteInsuranceReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the insurance referral to be deleted */
  id: Scalars["ID"];
};

/** Autogenerated return type of DeleteInsuranceReferral */
export type IDeleteInsuranceReferralPayload = {
  __typename: "DeleteInsuranceReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  id: Scalars["ID"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of DiscardBill */
export type IDiscardBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** String explaining why the bill is being discared */
  discardReason?: Maybe<Scalars["String"]>;
  /** Id of the bill to be discarded */
  id: Scalars["ID"];
};

/** Autogenerated return type of DiscardBill */
export type IDiscardBillPayload = {
  __typename: "DiscardBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type IDocument = {
  __typename: "Document";
  filename: Scalars["String"];
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

/** Attributes for creating or updating a document */
export type IDocumentAttributes = {
  scraped?: Maybe<Scalars["Boolean"]>;
  tempUrl: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
};

export type IElectricityReferral = {
  __typename: "ElectricityReferral";
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  providerId: Scalars["ID"];
  providerLogo?: Maybe<IImage>;
  providerName: Scalars["String"];
  successful: Scalars["Boolean"];
};

export type IImage = {
  __typename: "Image";
  grandeUrl: Scalars["String"];
  iconUrl: Scalars["String"];
  id?: Maybe<Scalars["ID"]>;
  imageProcessed: Scalars["Boolean"];
  largeUrl: Scalars["String"];
  mediumUrl: Scalars["String"];
  originalUrl: Scalars["String"];
  smallUrl: Scalars["String"];
  thumbnailUrl: Scalars["String"];
  tinyUrl: Scalars["String"];
};

export type IInformationRequest = {
  __typename: "InformationRequest";
  content: Scalars["String"];
  contentHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  fields: Array<IInformationRequestField>;
  id: Scalars["ID"];
  negotiationId: Scalars["ID"];
  negotiationNumber: Scalars["Int"];
  respondedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
  user: IUser;
};

/** Attributes for updating an information request */
export type IInformationRequestAttributes = {
  fields: Array<IInformationRequestFieldAttributes>;
};

export type IInformationRequestField = {
  __typename: "InformationRequestField";
  createdAt: Scalars["DateTime"];
  dataType: Scalars["String"];
  id: Scalars["ID"];
  label: Scalars["String"];
  placeholder: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  value?: Maybe<Scalars["String"]>;
};

/** Attributes for udpating information request fields */
export type IInformationRequestFieldAttributes = {
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type IInsuranceReferral = {
  __typename: "InsuranceReferral";
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  providerId: Scalars["ID"];
  providerLogo?: Maybe<IImage>;
  providerName: Scalars["String"];
  successful: Scalars["Boolean"];
};

export type IInvoice = {
  __typename: "Invoice";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["ID"];
  dueOn: Scalars["Date"];
  id: Scalars["ID"];
  issuedOn: Scalars["Date"];
  paid: Scalars["Boolean"];
  paidOn?: Maybe<Scalars["Date"]>;
  path: Scalars["String"];
  paymentDetails?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<Scalars["String"]>;
  total: Scalars["Money"];
  totalAmountDue: Scalars["Money"];
  updatedAt: Scalars["DateTime"];
};

/** The connection type for Invoice. */
export type IInvoiceConnection = {
  __typename: "InvoiceConnection";
  /** A list of edges. */
  edges: Array<IInvoiceEdge>;
  /** A list of nodes. */
  nodes: Array<IInvoice>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IInvoiceEdge = {
  __typename: "InvoiceEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IInvoice;
};

export type IItem = {
  __typename: "Item";
  createdAt: Scalars["DateTime"];
  duration: Scalars["Int"];
  expired: Scalars["Boolean"];
  id: Scalars["ID"];
  informationalAmount: Scalars["Money"];
  monthlySavings: Scalars["Money"];
  name: Scalars["String"];
  postPrice?: Maybe<Scalars["Money"]>;
  prePrice?: Maybe<Scalars["Money"]>;
  previousId?: Maybe<Scalars["ID"]>;
  previousIds: Array<Scalars["ID"]>;
  previousName?: Maybe<Scalars["String"]>;
  replaced: Scalars["Boolean"];
  savings: Scalars["Money"];
  savingsEndOn: Scalars["Date"];
  savingsStartOn: Scalars["Date"];
  type: Scalars["String"];
  underContract: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
};

export type IMessage = {
  __typename: "Message";
  body: Scalars["String"];
  bodyHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  direction: Scalars["String"];
  fromName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  messageType: Scalars["String"];
  sensitive: Scalars["Boolean"];
  sentAt?: Maybe<Scalars["DateTime"]>;
  toName?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  user?: Maybe<IUser>;
};

export type IMutation = {
  __typename: "Mutation";
  /** Accept an offer */
  acceptOffer?: Maybe<IAcceptOfferPayload>;
  /** Accept a pitch */
  acceptPitch?: Maybe<IAcceptPitchPayload>;
  /** Cancel a bill for a customer */
  cancelBill?: Maybe<ICancelBillPayload>;
  /** Change a customer password */
  changeCustomerPassword?: Maybe<IChangeCustomerPasswordPayload>;
  /** Connect a bank account to stripe using a plaid item */
  connectPlaidAch?: Maybe<IConnectPlaidAchPayload>;
  /** Create an Arcadia power referral for a customer */
  createArcadiaPowerReferral?: Maybe<ICreateArcadiaPowerReferralPayload>;
  /** Create a new bill for a customer */
  createBill?: Maybe<ICreateBillPayload>;
  /** Creates a single bill using a provider_id and a plaid_item_id */
  createBillFromPlaid?: Maybe<ICreateBillFromPlaidPayload>;
  /** Creates multiple bills using an array of provider_ids and a plaid_item_id */
  createBillsFromPlaid?: Maybe<ICreateBillsFromPlaidPayload>;
  /** Create a new credit card for a customer */
  createCreditCard?: Maybe<ICreateCreditCardPayload>;
  /** Create a new credit card for a customer and make a payment for their outstanding balance */
  createCreditCardAndMakePayment?: Maybe<ICreateCreditCardAndMakePaymentPayload>;
  /** Create a new customer acquisition survey */
  createCustomerAcquisitionSurvey?: Maybe<ICreateCustomerAcquisitionSurveyPayload>;
  createDocument?: Maybe<ICreateDocumentPayload>;
  /** Create an electricity referral for a customer */
  createElectricityReferral?: Maybe<ICreateElectricityReferralPayload>;
  /** Create an insurance referral for a customer */
  createInsuranceReferral?: Maybe<ICreateInsuranceReferralPayload>;
  /** Create a new message */
  createMessage?: Maybe<ICreateMessagePayload>;
  /** Create a payment for a customer */
  createPayment?: Maybe<ICreatePaymentPayload>;
  /** Creates a plaid item for the current customer */
  createPlaidItem?: Maybe<ICreatePlaidItemPayload>;
  /** Creates a Plaid link token with only the transactions product for the current customer */
  createPlaidLinkToken?: Maybe<ICreatePlaidLinkTokenPayload>;
  /** Create a new survey */
  createSurvey?: Maybe<ICreateSurveyPayload>;
  /** Create a wireless screener for a bill */
  createWirelessScreener?: Maybe<ICreateWirelessScreenerPayload>;
  deleteBankAccount?: Maybe<IDeleteBankAccountPayload>;
  deleteCreditCard?: Maybe<IDeleteCreditCardPayload>;
  deleteElectricityReferral?: Maybe<IDeleteElectricityReferralPayload>;
  deleteInsuranceReferral?: Maybe<IDeleteInsuranceReferralPayload>;
  discardBill?: Maybe<IDiscardBillPayload>;
  /** Pay off the entire balance for all open accounts for the current customer */
  payBalanceInFull?: Maybe<IPayBalanceInFullPayload>;
  /** Creates a Plaid link token with only the transactions product for the current customer */
  reauthenticatePlaidLinkToken?: Maybe<IReauthenticatePlaidLinkTokenPayload>;
  /** Reject an offer */
  rejectOffer?: Maybe<IRejectOfferPayload>;
  /** Reject a pitch */
  rejectPitch?: Maybe<IRejectPitchPayload>;
  renegotiateBill?: Maybe<IRenegotiateBillPayload>;
  respondToInformationRequest?: Maybe<IRespondToInformationRequestPayload>;
  setBankAccountAcknowledged?: Maybe<ISetBankAccountAcknowledgedPayload>;
  setBankAccountDefault?: Maybe<ISetBankAccountDefaultPayload>;
  setBillAllowsContract?: Maybe<ISetBillAllowsContractPayload>;
  setBillAutoRenegotiate?: Maybe<ISetBillAutoRenegotiatePayload>;
  setBillDeliveringReminderEmails?: Maybe<ISetBillDeliveringReminderEmailsPayload>;
  setBillDocumentless?: Maybe<ISetBillDocumentlessPayload>;
  setBillLimitedSavingsOpportunity?: Maybe<ISetBillLimitedSavingsOpportunityPayload>;
  setCreditCardDefault?: Maybe<ISetCreditCardDefaultPayload>;
  /** Sets a customer's avatar */
  setCustomerAvatar?: Maybe<ISetCustomerAvatarPayload>;
  setCustomerLastSurveyedOn?: Maybe<ISetCustomerLastSurveyedOnPayload>;
  setCustomerNextPaymentDay?: Maybe<ISetCustomerNextPaymentDayPayload>;
  setCustomerOnboarding?: Maybe<ISetCustomerOnboardingPayload>;
  setCustomerPaymentPlan?: Maybe<ISetCustomerPaymentPlanPayload>;
  setCustomerStripeBankAccountToken?: Maybe<ISetCustomerStripeBankAccountTokenPayload>;
  /** Set the plaid_access_token on the current customer */
  setPlaidAccessToken?: Maybe<ISetPlaidAccessTokenPayload>;
  /** Changes a plaid item\s needs_reauthentication column to the argument value provided */
  togglePlaidItemNeedsReauthentication?: Maybe<ITogglePlaidItemNeedsReauthenticationPayload>;
  /** Uncancel a bill for a customer */
  uncancelBill?: Maybe<IUncancelBillPayload>;
  /** Update an existing bill for a customer */
  updateBill?: Maybe<IUpdateBillPayload>;
  updateCustomer?: Maybe<IUpdateCustomerPayload>;
  /** Update an existing electricty referral for a customer */
  updateElectricityReferral?: Maybe<IUpdateElectricityReferralPayload>;
  /** Update an existing insurance referral for a customer */
  updateInsuranceReferral?: Maybe<IUpdateInsuranceReferralPayload>;
  /** Update an existing survey */
  updateSurvey?: Maybe<IUpdateSurveyPayload>;
};

export type IMutationacceptOfferArgs = {
  input: IAcceptOfferInput;
};

export type IMutationacceptPitchArgs = {
  input: IAcceptPitchInput;
};

export type IMutationcancelBillArgs = {
  input: ICancelBillInput;
};

export type IMutationchangeCustomerPasswordArgs = {
  input: IChangeCustomerPasswordInput;
};

export type IMutationconnectPlaidAchArgs = {
  input: IConnectPlaidAchInput;
};

export type IMutationcreateArcadiaPowerReferralArgs = {
  input: ICreateArcadiaPowerReferralInput;
};

export type IMutationcreateBillArgs = {
  input: ICreateBillInput;
};

export type IMutationcreateBillFromPlaidArgs = {
  input: ICreateBillFromPlaidInput;
};

export type IMutationcreateBillsFromPlaidArgs = {
  input: ICreateBillsFromPlaidInput;
};

export type IMutationcreateCreditCardArgs = {
  input: ICreateCreditCardInput;
};

export type IMutationcreateCreditCardAndMakePaymentArgs = {
  input: ICreateCreditCardAndMakePaymentInput;
};

export type IMutationcreateCustomerAcquisitionSurveyArgs = {
  input: ICreateCustomerAcquisitionSurveyInput;
};

export type IMutationcreateDocumentArgs = {
  input: ICreateDocumentInput;
};

export type IMutationcreateElectricityReferralArgs = {
  input: ICreateElectricityReferralInput;
};

export type IMutationcreateInsuranceReferralArgs = {
  input: ICreateInsuranceReferralInput;
};

export type IMutationcreateMessageArgs = {
  input: ICreateMessageInput;
};

export type IMutationcreatePaymentArgs = {
  input: ICreatePaymentInput;
};

export type IMutationcreatePlaidItemArgs = {
  input: ICreatePlaidItemInput;
};

export type IMutationcreatePlaidLinkTokenArgs = {
  input: ICreatePlaidLinkTokenInput;
};

export type IMutationcreateSurveyArgs = {
  input: ICreateSurveyInput;
};

export type IMutationcreateWirelessScreenerArgs = {
  input: ICreateWirelessScreenerInput;
};

export type IMutationdeleteBankAccountArgs = {
  input: IDeleteBankAccountInput;
};

export type IMutationdeleteCreditCardArgs = {
  input: IDeleteCreditCardInput;
};

export type IMutationdeleteElectricityReferralArgs = {
  input: IDeleteElectricityReferralInput;
};

export type IMutationdeleteInsuranceReferralArgs = {
  input: IDeleteInsuranceReferralInput;
};

export type IMutationdiscardBillArgs = {
  input: IDiscardBillInput;
};

export type IMutationpayBalanceInFullArgs = {
  input: IPayBalanceInFullInput;
};

export type IMutationreauthenticatePlaidLinkTokenArgs = {
  input: IReauthenticatePlaidLinkTokenInput;
};

export type IMutationrejectOfferArgs = {
  input: IRejectOfferInput;
};

export type IMutationrejectPitchArgs = {
  input: IRejectPitchInput;
};

export type IMutationrenegotiateBillArgs = {
  input: IRenegotiateBillInput;
};

export type IMutationrespondToInformationRequestArgs = {
  input: IRespondToInformationRequestInput;
};

export type IMutationsetBankAccountAcknowledgedArgs = {
  input: ISetBankAccountAcknowledgedInput;
};

export type IMutationsetBankAccountDefaultArgs = {
  input: ISetBankAccountDefaultInput;
};

export type IMutationsetBillAllowsContractArgs = {
  input: ISetBillAllowsContractInput;
};

export type IMutationsetBillAutoRenegotiateArgs = {
  input: ISetBillAutoRenegotiateInput;
};

export type IMutationsetBillDeliveringReminderEmailsArgs = {
  input: ISetBillDeliveringReminderEmailsInput;
};

export type IMutationsetBillDocumentlessArgs = {
  input: ISetBillDocumentlessInput;
};

export type IMutationsetBillLimitedSavingsOpportunityArgs = {
  input: ISetBillLimitedSavingsOpportunityInput;
};

export type IMutationsetCreditCardDefaultArgs = {
  input: ISetCreditCardDefaultInput;
};

export type IMutationsetCustomerAvatarArgs = {
  input: ISetCustomerAvatarInput;
};

export type IMutationsetCustomerLastSurveyedOnArgs = {
  input: ISetCustomerLastSurveyedOnInput;
};

export type IMutationsetCustomerNextPaymentDayArgs = {
  input: ISetCustomerNextPaymentDayInput;
};

export type IMutationsetCustomerOnboardingArgs = {
  input: ISetCustomerOnboardingInput;
};

export type IMutationsetCustomerPaymentPlanArgs = {
  input: ISetCustomerPaymentPlanInput;
};

export type IMutationsetCustomerStripeBankAccountTokenArgs = {
  input: ISetCustomerStripeBankAccountTokenInput;
};

export type IMutationsetPlaidAccessTokenArgs = {
  input: ISetPlaidAccessTokenInput;
};

export type IMutationtogglePlaidItemNeedsReauthenticationArgs = {
  input: ITogglePlaidItemNeedsReauthenticationInput;
};

export type IMutationuncancelBillArgs = {
  input: IUncancelBillInput;
};

export type IMutationupdateBillArgs = {
  input: IUpdateBillInput;
};

export type IMutationupdateCustomerArgs = {
  input: IUpdateCustomerInput;
};

export type IMutationupdateElectricityReferralArgs = {
  input: IUpdateElectricityReferralInput;
};

export type IMutationupdateInsuranceReferralArgs = {
  input: IUpdateInsuranceReferralInput;
};

export type IMutationupdateSurveyArgs = {
  input: IUpdateSurveyInput;
};

export type INegotiation = {
  __typename: "Negotiation";
  billId: Scalars["ID"];
  billProvider: IProvider;
  cancelledAt?: Maybe<Scalars["DateTime"]>;
  compedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  failedAt?: Maybe<Scalars["DateTime"]>;
  fixedAt?: Maybe<Scalars["DateTime"]>;
  fixerIds: Array<Scalars["ID"]>;
  fixers: Array<IUser>;
  id: Scalars["ID"];
  informationRequests: Array<IInformationRequest>;
  invoicedAt?: Maybe<Scalars["DateTime"]>;
  items: Array<IItem>;
  negotiationNumber: Scalars["Int"];
  requestedConsentAt?: Maybe<Scalars["DateTime"]>;
  requestedInformationAt?: Maybe<Scalars["DateTime"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  state: Scalars["String"];
  tags: Array<Scalars["String"]>;
  totalSavings: Scalars["Money"];
  updatedAt: Scalars["DateTime"];
};

export type INegotiationItem = {
  __typename: "NegotiationItem";
  createdAt: Scalars["DateTime"];
  duration: Scalars["Int"];
  expired: Scalars["Boolean"];
  id: Scalars["ID"];
  informationalAmount: Scalars["Money"];
  monthlySavings: Scalars["Money"];
  name: Scalars["String"];
  postPrice?: Maybe<Scalars["Money"]>;
  prePrice?: Maybe<Scalars["Money"]>;
  previousId?: Maybe<Scalars["ID"]>;
  previousIds: Array<Scalars["ID"]>;
  previousName?: Maybe<Scalars["String"]>;
  replaced: Scalars["Boolean"];
  savings: Scalars["Money"];
  savingsEndOn: Scalars["Date"];
  savingsStartOn: Scalars["Date"];
  type: Scalars["String"];
  underContract: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
};

export type IOffer = {
  __typename: "Offer";
  acceptedAt?: Maybe<Scalars["DateTime"]>;
  content: Scalars["String"];
  contentHtml: Scalars["String"];
  createdAt: Scalars["DateTime"];
  daysUntilExpiration: Scalars["Int"];
  id: Scalars["ID"];
  negotiation: INegotiation;
  negotiationId: Scalars["ID"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  state: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  user: IUser;
};

/** Information about pagination in a connection. */
export type IPageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type IPartner = {
  __typename: "Partner";
  id: Scalars["ID"];
  name: Scalars["String"];
  referralCode: Scalars["String"];
};

/** Autogenerated input type of PayBalanceInFull */
export type IPayBalanceInFullInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of PayBalanceInFull */
export type IPayBalanceInFullPayload = {
  __typename: "PayBalanceInFullPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Attributes for creating a payment */
export type IPaymentAttributes = {
  details?: Maybe<Scalars["String"]>;
  invoiceIds?: Maybe<Array<Scalars["String"]>>;
  paymentMethod?: Maybe<Scalars["String"]>;
  stripePaymentMethodId: Scalars["String"];
  token: Scalars["String"];
};

export type IPitch = {
  __typename: "Pitch";
  acceptedAt?: Maybe<Scalars["DateTime"]>;
  autoAcceptedOrRejected?: Maybe<Scalars["Boolean"]>;
  bill: IBill;
  createdAt: Scalars["DateTime"];
  daysUntilExpiration: Scalars["Int"];
  id: Scalars["ID"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  state: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** The connection type for Pitch. */
export type IPitchConnection = {
  __typename: "PitchConnection";
  /** A list of edges. */
  edges: Array<IPitchEdge>;
  /** A list of nodes. */
  nodes: Array<IPitch>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IPitchEdge = {
  __typename: "PitchEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IPitch;
};

export type IPlaidItem = {
  __typename: "PlaidItem";
  accountId?: Maybe<Scalars["String"]>;
  accountMask?: Maybe<Scalars["String"]>;
  accountName?: Maybe<Scalars["String"]>;
  billCount: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["ID"];
  id: Scalars["ID"];
  institutionBrandColor?: Maybe<Scalars["String"]>;
  institutionId: Scalars["String"];
  institutionLogo?: Maybe<Scalars["String"]>;
  institutionName: Scalars["String"];
  institutionUrl?: Maybe<Scalars["String"]>;
  needsReauthentication: Scalars["Boolean"];
  plaidAssignedItemId: Scalars["String"];
  totalSavings: Scalars["Money"];
  transactions: Array<IPlaidTransaction>;
  updatedAt: Scalars["DateTime"];
};

/** Attributes for creating or updating a plaid item */
export type IPlaidItemAttributes = {
  accountId?: Maybe<Scalars["String"]>;
  accountMask?: Maybe<Scalars["String"]>;
  accountName?: Maybe<Scalars["String"]>;
  accountSubtype?: Maybe<Scalars["String"]>;
  accountType?: Maybe<Scalars["String"]>;
  institutionId?: Maybe<Scalars["String"]>;
  institutionName?: Maybe<Scalars["String"]>;
};

/** The connection type for PlaidItem. */
export type IPlaidItemConnection = {
  __typename: "PlaidItemConnection";
  /** A list of edges. */
  edges: Array<IPlaidItemEdge>;
  /** A list of nodes. */
  nodes: Array<IPlaidItem>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IPlaidItemEdge = {
  __typename: "PlaidItemEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IPlaidItem;
};

export type IPlaidTransaction = {
  __typename: "PlaidTransaction";
  accountId: Scalars["String"];
  accountMask?: Maybe<Scalars["String"]>;
  accountName?: Maybe<Scalars["String"]>;
  accountOfficialName?: Maybe<Scalars["String"]>;
  amount: Scalars["Float"];
  authorizedDate?: Maybe<Scalars["DateTime"]>;
  billId?: Maybe<Scalars["ID"]>;
  category: Array<Scalars["String"]>;
  categoryId: Scalars["String"];
  customerId: Scalars["ID"];
  hasPreviousProviderMatch: Scalars["Boolean"];
  id: Scalars["ID"];
  institutionName: Scalars["String"];
  merchantName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  paymentChannel?: Maybe<Scalars["String"]>;
  pending: Scalars["Boolean"];
  pendingTransactionId?: Maybe<Scalars["String"]>;
  plaidItemId: Scalars["ID"];
  plaidTransactionId: Scalars["String"];
  plaidTransactionType?: Maybe<Scalars["String"]>;
  postedDate: Scalars["DateTime"];
  providerId?: Maybe<Scalars["ID"]>;
  providerLogo?: Maybe<IImage>;
  providerName?: Maybe<Scalars["String"]>;
  providerServices?: Maybe<Array<Scalars["String"]>>;
};

/** The connection type for PlaidTransaction. */
export type IPlaidTransactionConnection = {
  __typename: "PlaidTransactionConnection";
  /** A list of edges. */
  edges: Array<IPlaidTransactionEdge>;
  hasProviderMatches: Scalars["Boolean"];
  /** A list of nodes. */
  nodes: Array<IPlaidTransaction>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IPlaidTransactionEdge = {
  __typename: "PlaidTransactionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node?: Maybe<IPlaidTransaction>;
};

export type IProvider = {
  __typename: "Provider";
  active: Scalars["Boolean"];
  billFields: Array<Scalars["String"]>;
  description: Scalars["String"];
  downloadInstructions?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  images: Array<IImage>;
  logo?: Maybe<IImage>;
  name: Scalars["String"];
  providerBillFields: Array<IProviderBillField>;
  scraperEnabled: Scalars["Boolean"];
  services: Array<Scalars["String"]>;
  totalRequiredProviderBillFields: Scalars["Int"];
};

export type IProviderBillField = {
  __typename: "ProviderBillField";
  createdAt: Scalars["DateTime"];
  dataType: Scalars["String"];
  formatHashString?: Maybe<Scalars["String"]>;
  helpText?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  label: Scalars["String"];
  name: Scalars["String"];
  placeholder?: Maybe<Scalars["String"]>;
  providerId: Scalars["ID"];
  required: Scalars["Boolean"];
  requiredResponseLength?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

export type IQuery = {
  __typename: "Query";
  calculateSavingsEstimate: ISavingsEstimate;
  findAmortizationStats: Array<IAmortizationStat>;
  findBill?: Maybe<IBill>;
  findCurrentCustomer: ICustomer;
  findPlaidItem?: Maybe<IPlaidItem>;
  listAccounts: IAccountConnection;
  listBankAccounts: Array<IBankAccount>;
  listBillItems: Array<IItem>;
  listBills: Array<IBill>;
  listCreditCards: Array<ICreditCard>;
  listElectricityReferrals: Array<IElectricityReferral>;
  listInsuranceReferrals: Array<IInsuranceReferral>;
  listInvoices: IInvoiceConnection;
  listMessages: Array<IMessage>;
  listNegotiablePlaidTransactions: Array<IPlaidTransaction>;
  listNegotiations: Array<INegotiation>;
  listPitches: IPitchConnection;
  listPlaidItems: IPlaidItemConnection;
  listPlaidTransactions: IPlaidTransactionConnection;
  listProviderPlaidTransactions: Array<IPlaidTransaction>;
  listProviders: Array<IProvider>;
  pingUptime: Scalars["String"];
  s3SignedPost?: Maybe<IS3SignedPost>;
};

export type IQueryfindBillArgs = {
  id: Scalars["ID"];
};

export type IQueryfindPlaidItemArgs = {
  id: Scalars["ID"];
};

export type IQuerylistAccountsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  open?: Maybe<Scalars["Boolean"]>;
};

export type IQuerylistBillItemsArgs = {
  billId: Scalars["ID"];
};

export type IQuerylistBillsArgs = {
  fromPlaid?: Maybe<Scalars["Boolean"]>;
  plaidItemId?: Maybe<Scalars["ID"]>;
};

export type IQuerylistInvoicesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

export type IQuerylistMessagesArgs = {
  billId: Scalars["ID"];
};

export type IQuerylistNegotiablePlaidTransactionsArgs = {
  plaidItemId: Scalars["ID"];
};

export type IQuerylistNegotiationsArgs = {
  billId: Scalars["ID"];
};

export type IQuerylistPitchesArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  billId?: Maybe<Scalars["ID"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  onlyPending?: Maybe<Scalars["Boolean"]>;
};

export type IQuerylistPlaidItemsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  onlyCheckingAccounts?: Maybe<Scalars["Boolean"]>;
};

export type IQuerylistPlaidTransactionsArgs = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  plaidItemId?: Maybe<Scalars["ID"]>;
};

export type IQuerylistProviderPlaidTransactionsArgs = {
  onlyMostRecent?: Maybe<Scalars["Boolean"]>;
  plaidItemId?: Maybe<Scalars["ID"]>;
  withoutBills?: Maybe<Scalars["Boolean"]>;
};

export type IQuerylistProvidersArgs = {
  excludeElectricity?: Maybe<Scalars["Boolean"]>;
  excludeInsurance?: Maybe<Scalars["Boolean"]>;
};

export type IQuerypingUptimeArgs = {
  apiId: Scalars["ID"];
};

export type IQuerys3SignedPostArgs = {
  acl: Scalars["String"];
  filename: Scalars["String"];
};

/** Autogenerated input type of ReauthenticatePlaidLinkToken */
export type IReauthenticatePlaidLinkTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  plaidItemId: Scalars["ID"];
};

/** Autogenerated return type of ReauthenticatePlaidLinkToken */
export type IReauthenticatePlaidLinkTokenPayload = {
  __typename: "ReauthenticatePlaidLinkTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  linkToken: Scalars["String"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RejectOffer */
export type IRejectOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of RejectOffer */
export type IRejectOfferPayload = {
  __typename: "RejectOfferPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RejectPitch */
export type IRejectPitchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of RejectPitch */
export type IRejectPitchPayload = {
  __typename: "RejectPitchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  pitch?: Maybe<IPitch>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RenegotiateBill */
export type IRenegotiateBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the bill that should be renegotiated */
  id: Scalars["ID"];
};

/** Autogenerated return type of RenegotiateBill */
export type IRenegotiateBillPayload = {
  __typename: "RenegotiateBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of RespondToInformationRequest */
export type IRespondToInformationRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the information request */
  id: Scalars["ID"];
  informationRequest: IInformationRequestAttributes;
};

/** Autogenerated return type of RespondToInformationRequest */
export type IRespondToInformationRequestPayload = {
  __typename: "RespondToInformationRequestPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  informationRequest?: Maybe<IInformationRequest>;
  negotiation?: Maybe<INegotiation>;
  success: Scalars["Boolean"];
};

export type IS3SignedPost = {
  __typename: "S3SignedPost";
  acl: Scalars["String"];
  key: Scalars["String"];
  policy: Scalars["String"];
  successActionStatus: Scalars["String"];
  xAmzAlgorithm: Scalars["String"];
  xAmzCredential: Scalars["String"];
  xAmzDate: Scalars["String"];
  xAmzSignature: Scalars["String"];
};

export type ISavingsEstimate = {
  __typename: "SavingsEstimate";
  estimatedAnnualSavings: Scalars["Money"];
  estimatedMonthlySavings: Scalars["Money"];
  percentageSavings: Scalars["Float"];
};

export type ISavingsEstimateestimatedAnnualSavingsArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

export type ISavingsEstimateestimatedMonthlySavingsArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

export type ISavingsEstimatepercentageSavingsArgs = {
  currentMonthlyAmount: Scalars["Float"];
  providerId: Scalars["ID"];
};

/** Autogenerated input type of SetBankAccountAcknowledged */
export type ISetBankAccountAcknowledgedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the bank account that has been acknowledged */
  id: Scalars["ID"];
};

/** Autogenerated return type of SetBankAccountAcknowledged */
export type ISetBankAccountAcknowledgedPayload = {
  __typename: "SetBankAccountAcknowledgedPayload";
  bankAccount?: Maybe<IBankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetBankAccountDefault */
export type ISetBankAccountDefaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the bank account that should be default */
  id: Scalars["ID"];
};

/** Autogenerated return type of SetBankAccountDefault */
export type ISetBankAccountDefaultPayload = {
  __typename: "SetBankAccountDefaultPayload";
  bankAccount?: Maybe<IBankAccount>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetBillAllowsContract */
export type ISetBillAllowsContractInput = {
  allowsContract: Scalars["Boolean"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the bill that should allow contracts or not */
  id: Scalars["ID"];
};

/** Autogenerated return type of SetBillAllowsContract */
export type ISetBillAllowsContractPayload = {
  __typename: "SetBillAllowsContractPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetBillAutoRenegotiate */
export type ISetBillAutoRenegotiateInput = {
  autoRenegotiate: Scalars["Boolean"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the bill that should be auto renegotiated or not */
  id: Scalars["ID"];
};

/** Autogenerated return type of SetBillAutoRenegotiate */
export type ISetBillAutoRenegotiatePayload = {
  __typename: "SetBillAutoRenegotiatePayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetBillDeliveringReminderEmails */
export type ISetBillDeliveringReminderEmailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  deliveringReminderEmails: Scalars["Boolean"];
  /** Id of the bill changing delivering_reminder_emails */
  id: Scalars["ID"];
};

/** Autogenerated return type of SetBillDeliveringReminderEmails */
export type ISetBillDeliveringReminderEmailsPayload = {
  __typename: "SetBillDeliveringReminderEmailsPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetBillDocumentless */
export type ISetBillDocumentlessInput = {
  /** Id of the bill changing documentless */
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  documentless: Scalars["Boolean"];
};

/** Autogenerated return type of SetBillDocumentless */
export type ISetBillDocumentlessPayload = {
  __typename: "SetBillDocumentlessPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetBillLimitedSavingsOpportunity */
export type ISetBillLimitedSavingsOpportunityInput = {
  /** Id of the bill changing documentless */
  billId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  limitedSavingsOpportunity: Scalars["Boolean"];
};

/** Autogenerated return type of SetBillLimitedSavingsOpportunity */
export type ISetBillLimitedSavingsOpportunityPayload = {
  __typename: "SetBillLimitedSavingsOpportunityPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCreditCardDefault */
export type ISetCreditCardDefaultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Id of the credit card that should be default */
  id: Scalars["ID"];
};

/** Autogenerated return type of SetCreditCardDefault */
export type ISetCreditCardDefaultPayload = {
  __typename: "SetCreditCardDefaultPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  creditCard?: Maybe<ICreditCard>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerAvatar */
export type ISetCustomerAvatarInput = {
  avatarUrl: Scalars["String"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of SetCustomerAvatar */
export type ISetCustomerAvatarPayload = {
  __typename: "SetCustomerAvatarPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerLastSurveyedOn */
export type ISetCustomerLastSurveyedOnInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of SetCustomerLastSurveyedOn */
export type ISetCustomerLastSurveyedOnPayload = {
  __typename: "SetCustomerLastSurveyedOnPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerNextPaymentDay */
export type ISetCustomerNextPaymentDayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  nextPaymentDay: Scalars["Int"];
};

/** Autogenerated return type of SetCustomerNextPaymentDay */
export type ISetCustomerNextPaymentDayPayload = {
  __typename: "SetCustomerNextPaymentDayPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerOnboarding */
export type ISetCustomerOnboardingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The value to set onboarding to */
  onboarding: Scalars["Boolean"];
};

/** Autogenerated return type of SetCustomerOnboarding */
export type ISetCustomerOnboardingPayload = {
  __typename: "SetCustomerOnboardingPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerPaymentPlan */
export type ISetCustomerPaymentPlanInput = {
  attemptToPay?: Maybe<Scalars["Boolean"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  paymentPlan: Scalars["String"];
};

/** Autogenerated return type of SetCustomerPaymentPlan */
export type ISetCustomerPaymentPlanPayload = {
  __typename: "SetCustomerPaymentPlanPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetCustomerStripeBankAccountToken */
export type ISetCustomerStripeBankAccountTokenInput = {
  bankAccountId: Scalars["String"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  publicToken: Scalars["String"];
};

/** Autogenerated return type of SetCustomerStripeBankAccountToken */
export type ISetCustomerStripeBankAccountTokenPayload = {
  __typename: "SetCustomerStripeBankAccountTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of SetPlaidAccessToken */
export type ISetPlaidAccessTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  publicToken: Scalars["String"];
};

/** Autogenerated return type of SetPlaidAccessToken */
export type ISetPlaidAccessTokenPayload = {
  __typename: "SetPlaidAccessTokenPayload";
  accessToken?: Maybe<Scalars["String"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type ISurvey = {
  __typename: "Survey";
  customer: ICustomer;
  customerId: Scalars["ID"];
  feedback?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  rating: Scalars["Int"];
};

/** Attributes for creating or updating a survey */
export type ISurveyAttributes = {
  feedback?: Maybe<Scalars["String"]>;
  rating?: Maybe<Scalars["Int"]>;
};

/** Autogenerated input type of TogglePlaidItemNeedsReauthentication */
export type ITogglePlaidItemNeedsReauthenticationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  needsReauthentication: Scalars["Boolean"];
  plaidItemId: Scalars["ID"];
};

/** Autogenerated return type of TogglePlaidItemNeedsReauthentication */
export type ITogglePlaidItemNeedsReauthenticationPayload = {
  __typename: "TogglePlaidItemNeedsReauthenticationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  plaidItem: IPlaidItem;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UncancelBill */
export type IUncancelBillInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of UncancelBill */
export type IUncancelBillPayload = {
  __typename: "UncancelBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateBill */
export type IUpdateBillInput = {
  bill: IBillAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of UpdateBill */
export type IUpdateBillPayload = {
  __typename: "UpdateBillPayload";
  bill?: Maybe<IBill>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateCustomer */
export type IUpdateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomerAttributes;
};

/** Autogenerated return type of UpdateCustomer */
export type IUpdateCustomerPayload = {
  __typename: "UpdateCustomerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer?: Maybe<ICustomer>;
  errors: Array<Scalars["String"]>;
};

/** Autogenerated input type of UpdateElectricityReferral */
export type IUpdateElectricityReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  successful: Scalars["Boolean"];
};

/** Autogenerated return type of UpdateElectricityReferral */
export type IUpdateElectricityReferralPayload = {
  __typename: "UpdateElectricityReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  referral?: Maybe<IElectricityReferral>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateInsuranceReferral */
export type IUpdateInsuranceReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  successful: Scalars["Boolean"];
};

/** Autogenerated return type of UpdateInsuranceReferral */
export type IUpdateInsuranceReferralPayload = {
  __typename: "UpdateInsuranceReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  referral?: Maybe<IInsuranceReferral>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateSurvey */
export type IUpdateSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  survey: ISurveyAttributes;
  surveyId: Scalars["ID"];
};

/** Autogenerated return type of UpdateSurvey */
export type IUpdateSurveyPayload = {
  __typename: "UpdateSurveyPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  customer: ICustomer;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  survey?: Maybe<ISurvey>;
};

export type IUser = {
  __typename: "User";
  email: Scalars["String"];
  firstName: Scalars["String"];
  firstNameLastInitial: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type IWirelessScreener = {
  __typename: "WirelessScreener";
  ableToNegotiate: Scalars["Boolean"];
  billId: Scalars["ID"];
  hdStreamingQuality?: Maybe<Scalars["String"]>;
  hotspot?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  unlimited?: Maybe<Scalars["Boolean"]>;
};

/** Attributes for creating or updating a wireless screener */
export type IWirelessScreenerAttributes = {
  hdStreamingQuality?: Maybe<Scalars["Boolean"]>;
  hotspot?: Maybe<Scalars["Boolean"]>;
  unlimited?: Maybe<Scalars["Boolean"]>;
};

export type IAccountFragment = { __typename: "Account" } & Pick<
  IAccount,
  | "id"
  | "balance"
  | "createdAt"
  | "endsOn"
  | "monthsRemaining"
  | "monthlyPaymentAmount"
  | "monthlyPaymentAmountWithDiscounts"
  | "name"
  | "shortName"
  | "itemMonthlySavings"
  | "itemAnnualSavings"
  | "providerName"
  | "providerId"
> & {
    providerLogo?: Maybe<{ __typename: "Image" } & IImageFragment>;
    item?: Maybe<
      { __typename: "NegotiationItem" } & Pick<
        INegotiationItem,
        "id" | "name" | "type"
      >
    >;
  };

export type IAccountTransactionFragment = {
  __typename: "AccountTransaction";
} & Pick<
  IAccountTransaction,
  "id" | "transactionType" | "amount" | "description"
>;

export type IBankAccountFragment = { __typename: "BankAccount" } & Pick<
  IBankAccount,
  | "id"
  | "customerId"
  | "last4"
  | "stripeId"
  | "accountHolderName"
  | "bankName"
  | "fingerprint"
  | "default"
  | "plaidItemId"
  | "plaidItemInstitutionName"
  | "plaidItemInstitutionLogo"
  | "acknowledged"
>;

export type IBillFragment = { __typename: "Bill" } & Pick<
  IBill,
  | "id"
  | "createdAt"
  | "updatedAt"
  | "startedAt"
  | "providerId"
  | "autoRenegotiate"
  | "allowsContract"
  | "last4ssn"
  | "passcode"
  | "initialNotes"
  | "totalSavings"
  | "currentSavings"
  | "monthlySavings"
  | "earnedSavings"
  | "grossEarnedSavings"
  | "grossSavings"
  | "futureSavings"
  | "nextExpectedSavings"
  | "otcSavings"
  | "otcOnly"
  | "nextSavingsDate"
  | "savingsStartOn"
  | "savingsEndOn"
  | "documentCount"
  | "negotiationsCount"
  | "status"
  | "title"
  | "deliveringReminderEmails"
  | "plaidItemId"
  | "plaidItemInstitutionName"
  | "plaidItemInstitutionLogo"
  | "assumedPlaidTransactionAccountName"
  | "readyToNegotiate"
  | "documentless"
  | "hasDocumentOrEquivalentInfo"
> & {
    currentFixer?: Maybe<
      { __typename: "User" } & Pick<IUser, "firstNameLastInitial">
    >;
    provider: { __typename: "Provider" } & IProviderFragment;
    documents: Array<{ __typename: "Document" } & IDocumentFragment>;
    offers: Array<{ __typename: "Offer" } & IOfferFragment>;
    informationRequests: Array<
      { __typename: "InformationRequest" } & IInformationRequestFragment
    >;
    providerBillFields: Array<
      { __typename: "ProviderBillField" } & IProviderBillFieldFragment
    >;
  };

export type ICreditCardFragment = { __typename: "CreditCard" } & Pick<
  ICreditCard,
  | "id"
  | "last4"
  | "token"
  | "expMonth"
  | "expYear"
  | "brand"
  | "default"
  | "name"
  | "expired"
  | "stripePaymentMethodId"
>;

export type ICustomerFragment = { __typename: "Customer" } & Pick<
  ICustomer,
  | "id"
  | "email"
  | "name"
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "allTimeSavings"
  | "allTimeGrossSavings"
  | "referralCode"
  | "referredByCode"
  | "referralAmountPaid"
  | "referralCount"
  | "requirePaymentMethod"
  | "vip"
  | "b2b"
  | "legacyBilling"
  | "paymentPlan"
  | "paymentPlanChosen"
  | "unpaidInvoiceAmount"
  | "nextPaymentDate"
  | "regularMonthlyPaymentAmount"
  | "nextMonthlyInvoiceAmount"
  | "balance"
  | "stripeId"
  | "hasInvoices"
  | "hasUnpaidInvoices"
  | "discount"
  | "lastSurveyedOn"
  | "stripeBankAccountToken"
  | "hasAtLeastOnePayment"
  | "hasPlaidTransactions"
  | "hasPlaidItems"
  | "hasBills"
  | "hasReadyBills"
  | "hasAllReadyBills"
  | "hasNegotiableBill"
  | "hasPotentialAchPaymentMethod"
  | "onboarding"
  | "billCount"
  | "readyBillCount"
  | "defaultPaymentMethodType"
  | "hasDefaultPaymentMethod"
  | "hasBillsMissingInfo"
  | "answeredAcquisitionSurvey"
  | "trafficReferralUrl"
  | "discountedRate"
  | "billServices"
> & {
    creditCards: Array<{ __typename: "CreditCard" } & ICreditCardFragment>;
    defaultCreditCard?: Maybe<
      { __typename: "CreditCard" } & ICreditCardFragment
    >;
    bankAccounts: Array<{ __typename: "BankAccount" } & IBankAccountFragment>;
    defaultBankAccount?: Maybe<
      { __typename: "BankAccount" } & IBankAccountFragment
    >;
    unpaidInvoices: Array<{ __typename: "Invoice" } & IInvoiceFragment>;
    partner?: Maybe<
      { __typename: "Partner" } & Pick<IPartner, "id" | "name" | "referralCode">
    >;
    insuranceReferrals: Array<
      { __typename: "InsuranceReferral" } & Pick<
        IInsuranceReferral,
        "id" | "providerId" | "successful"
      >
    >;
    electricityReferrals: Array<
      { __typename: "ElectricityReferral" } & Pick<
        IElectricityReferral,
        "id" | "providerId" | "successful"
      >
    >;
    arcadiaPowerReferrals: Array<
      { __typename: "ArcadiaPowerReferral" } & Pick<
        IArcadiaPowerReferral,
        "id" | "providerId"
      >
    >;
  };

export type ICustomerAcquisitionSurveyFragment = {
  __typename: "CustomerAcquisitionSurvey";
} & Pick<
  ICustomerAcquisitionSurvey,
  "id" | "customerId" | "channel" | "details" | "createdAt"
>;

export type IDocumentFragment = { __typename: "Document" } & Pick<
  IDocument,
  "id" | "title" | "filename" | "url"
>;

export type IImageFragment = { __typename: "Image" } & Pick<
  IImage,
  | "id"
  | "imageProcessed"
  | "tinyUrl"
  | "iconUrl"
  | "thumbnailUrl"
  | "smallUrl"
  | "mediumUrl"
  | "largeUrl"
  | "grandeUrl"
  | "originalUrl"
>;

export type IInformationRequestFragment = {
  __typename: "InformationRequest";
} & Pick<
  IInformationRequest,
  "id" | "content" | "contentHtml" | "respondedAt"
> & {
    fields: Array<
      { __typename: "InformationRequestField" } & Pick<
        IInformationRequestField,
        "id" | "label" | "placeholder" | "dataType"
      >
    >;
  };

export type IInvoiceFragment = { __typename: "Invoice" } & Pick<
  IInvoice,
  | "id"
  | "issuedOn"
  | "dueOn"
  | "paid"
  | "createdAt"
  | "updatedAt"
  | "totalAmountDue"
  | "total"
  | "paidOn"
  | "paymentMethod"
  | "paymentDetails"
  | "path"
>;

export type IItemFragment = { __typename: "Item" } & Pick<
  IItem,
  | "id"
  | "type"
  | "name"
  | "savings"
  | "savingsStartOn"
  | "savingsEndOn"
  | "monthlySavings"
  | "duration"
  | "expired"
  | "prePrice"
  | "postPrice"
  | "previousId"
  | "previousIds"
  | "previousName"
  | "createdAt"
  | "updatedAt"
  | "replaced"
>;

export type IMessageFragment = { __typename: "Message" } & Pick<
  IMessage,
  | "id"
  | "body"
  | "bodyHtml"
  | "messageType"
  | "sentAt"
  | "createdAt"
  | "updatedAt"
  | "sensitive"
  | "direction"
  | "fromName"
  | "toName"
>;

export type INegotiationFragment = { __typename: "Negotiation" } & Pick<
  INegotiation,
  "id" | "billId" | "state" | "totalSavings" | "createdAt"
> & {
    billProvider: { __typename: "Provider" } & Pick<IProvider, "name"> & {
        logo?: Maybe<{ __typename: "Image" } & Pick<IImage, "mediumUrl">>;
      };
    items: Array<{ __typename: "Item" } & IItemFragment>;
    fixers: Array<{ __typename: "User" } & IUserFragment>;
  };

export type IOfferFragment = { __typename: "Offer" } & Pick<
  IOffer,
  | "id"
  | "negotiationId"
  | "state"
  | "content"
  | "contentHtml"
  | "daysUntilExpiration"
  | "createdAt"
  | "updatedAt"
  | "acceptedAt"
  | "rejectedAt"
>;

export type IPlaidItemFragment = { __typename: "PlaidItem" } & Pick<
  IPlaidItem,
  | "id"
  | "customerId"
  | "plaidAssignedItemId"
  | "institutionId"
  | "institutionName"
  | "accountId"
  | "accountName"
  | "accountMask"
  | "billCount"
  | "institutionLogo"
  | "institutionBrandColor"
  | "institutionUrl"
  | "createdAt"
  | "updatedAt"
  | "totalSavings"
  | "needsReauthentication"
>;

export type IPlaidTransactionFragment = {
  __typename: "PlaidTransaction";
} & Pick<
  IPlaidTransaction,
  | "id"
  | "providerId"
  | "billId"
  | "plaidItemId"
  | "customerId"
  | "accountId"
  | "accountMask"
  | "accountName"
  | "accountOfficialName"
  | "amount"
  | "category"
  | "categoryId"
  | "postedDate"
  | "authorizedDate"
  | "merchantName"
  | "name"
  | "paymentChannel"
  | "plaidTransactionId"
  | "plaidTransactionType"
  | "pending"
  | "pendingTransactionId"
  | "institutionName"
  | "hasPreviousProviderMatch"
  | "providerName"
  | "providerServices"
> & { providerLogo?: Maybe<{ __typename: "Image" } & IImageFragment> };

export type IProviderFragment = { __typename: "Provider" } & Pick<
  IProvider,
  | "id"
  | "name"
  | "services"
  | "downloadInstructions"
  | "billFields"
  | "scraperEnabled"
  | "totalRequiredProviderBillFields"
> & { logo?: Maybe<{ __typename: "Image" } & IImageFragment> };

export type IProviderBillFieldFragment = {
  __typename: "ProviderBillField";
} & Pick<
  IProviderBillField,
  | "id"
  | "providerId"
  | "label"
  | "name"
  | "placeholder"
  | "dataType"
  | "required"
  | "requiredResponseLength"
  | "helpText"
>;

export type ISurveyFragment = { __typename: "Survey" } & Pick<
  ISurvey,
  "id" | "rating" | "feedback"
> & {
    customer: { __typename: "Customer" } & Pick<
      ICustomer,
      "id" | "name" | "email" | "lastSurveyedOn"
    >;
  };

export type IUserFragment = { __typename: "User" } & Pick<
  IUser,
  "firstNameLastInitial"
>;

export type ICancelBillMutationVariables = Exact<{
  id: Scalars["ID"];
  reason: Scalars["String"];
  explanation?: Maybe<Scalars["String"]>;
}>;

export type ICancelBillMutation = { __typename: "Mutation" } & {
  payload?: Maybe<
    { __typename: "CancelBillPayload" } & Pick<
      ICancelBillPayload,
      "success" | "errors"
    > & { bill?: Maybe<{ __typename: "Bill" } & IBillFragment> }
  >;
};

export type ICreateBillMutationVariables = Exact<{
  bill: IBillAttributes;
  checkForDuplicates?: Maybe<Scalars["Boolean"]>;
  allowDuplicate?: Maybe<Scalars["Boolean"]>;
}>;

export type ICreateBillMutation = { __typename: "Mutation" } & {
  payload?: Maybe<
    { __typename: "CreateBillPayload" } & Pick<
      ICreateBillPayload,
      "success" | "errors" | "duplicateFound" | "duplicateBillId"
    > & { bill?: Maybe<{ __typename: "Bill" } & IBillFragment> }
  >;
};

export type ICreateCreditCardMutationVariables = Exact<{
  params: ICreditCardAttributes;
  paymentPlan?: Maybe<Scalars["String"]>;
  paymentPlanChosen?: Maybe<Scalars["Boolean"]>;
}>;

export type ICreateCreditCardMutation = { __typename: "Mutation" } & {
  createCreditCard?: Maybe<
    { __typename: "CreateCreditCardPayload" } & Pick<
      ICreateCreditCardPayload,
      "success" | "errors"
    > & {
        creditCard?: Maybe<{ __typename: "CreditCard" } & ICreditCardFragment>;
        customer: { __typename: "Customer" } & ICustomerFragment;
      }
  >;
};

export type ICreateCreditCardAndMakePaymentMutationVariables = Exact<{
  params: ICreditCardAttributes;
}>;

export type ICreateCreditCardAndMakePaymentMutation = {
  __typename: "Mutation";
} & {
  createCreditCardAndMakePayment?: Maybe<
    { __typename: "CreateCreditCardAndMakePaymentPayload" } & Pick<
      ICreateCreditCardAndMakePaymentPayload,
      "success" | "errors"
    > & { customer: { __typename: "Customer" } & ICustomerFragment }
  >;
};

export type ICreateDocumentMutationVariables = Exact<{
  billId: Scalars["ID"];
  document: IDocumentAttributes;
}>;

export type ICreateDocumentMutation = { __typename: "Mutation" } & {
  payload?: Maybe<
    { __typename: "CreateDocumentPayload" } & Pick<
      ICreateDocumentPayload,
      "errors"
    > & {
        document?: Maybe<
          { __typename: "Document" } & Pick<IDocument, "id" | "title" | "url">
        >;
        bill?: Maybe<{ __typename: "Bill" } & IBillFragment>;
      }
  >;
};

export type ICreatePaymentMutationVariables = Exact<{
  params: IPaymentAttributes;
}>;

export type ICreatePaymentMutation = { __typename: "Mutation" } & {
  createPayment?: Maybe<
    { __typename: "CreatePaymentPayload" } & Pick<
      ICreatePaymentPayload,
      "success" | "errors"
    > & {
        payment?: Maybe<{ __typename: "CreditCard" } & Pick<ICreditCard, "id">>;
        customer: { __typename: "Customer" } & ICustomerFragment;
      }
  >;
};

export type ISetBillAllowsContractMutationVariables = Exact<{
  id: Scalars["ID"];
  allowsContract: Scalars["Boolean"];
}>;

export type ISetBillAllowsContractMutation = { __typename: "Mutation" } & {
  payload?: Maybe<
    { __typename: "SetBillAllowsContractPayload" } & Pick<
      ISetBillAllowsContractPayload,
      "errors"
    > & {
        bill?: Maybe<
          { __typename: "Bill" } & Pick<IBill, "id" | "allowsContract">
        >;
      }
  >;
};

export type ISetBillAutoRenegotiateMutationVariables = Exact<{
  id: Scalars["ID"];
  autoRenegotiate: Scalars["Boolean"];
}>;

export type ISetBillAutoRenegotiateMutation = { __typename: "Mutation" } & {
  payload?: Maybe<
    { __typename: "SetBillAutoRenegotiatePayload" } & Pick<
      ISetBillAutoRenegotiatePayload,
      "errors"
    > & {
        bill?: Maybe<
          { __typename: "Bill" } & Pick<IBill, "id" | "autoRenegotiate">
        >;
      }
  >;
};

export type ISetBillDocumentlessMutationVariables = Exact<{
  billId: Scalars["ID"];
  documentless: Scalars["Boolean"];
}>;

export type ISetBillDocumentlessMutation = { __typename: "Mutation" } & {
  setBillDocumentless?: Maybe<
    { __typename: "SetBillDocumentlessPayload" } & Pick<
      ISetBillDocumentlessPayload,
      "success" | "errors"
    > & {
        bill?: Maybe<
          { __typename: "Bill" } & Pick<IBill, "id" | "documentless">
        >;
      }
  >;
};

export type ISetCreditCardDefaultMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ISetCreditCardDefaultMutation = { __typename: "Mutation" } & {
  setCreditCardDefault?: Maybe<
    { __typename: "SetCreditCardDefaultPayload" } & Pick<
      ISetCreditCardDefaultPayload,
      "success" | "errors"
    > & {
        creditCard?: Maybe<{ __typename: "CreditCard" } & ICreditCardFragment>;
      }
  >;
};

export type IUpdateBillMutationVariables = Exact<{
  id: Scalars["ID"];
  bill: IBillAttributes;
}>;

export type IUpdateBillMutation = { __typename: "Mutation" } & {
  payload?: Maybe<
    { __typename: "UpdateBillPayload" } & Pick<
      IUpdateBillPayload,
      "success" | "errors"
    > & { bill?: Maybe<{ __typename: "Bill" } & IBillFragment> }
  >;
};

export type IUpdateCustomerMutationVariables = Exact<{
  params: ICustomerAttributes;
}>;

export type IUpdateCustomerMutation = { __typename: "Mutation" } & {
  updateCustomer?: Maybe<
    { __typename: "UpdateCustomerPayload" } & Pick<
      IUpdateCustomerPayload,
      "errors"
    > & { customer?: Maybe<{ __typename: "Customer" } & ICustomerFragment> }
  >;
};

export type IFindAmortizationStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IFindAmortizationStatsQuery = { __typename: "Query" } & {
  amortizationStats: Array<
    { __typename: "AmortizationStat" } & Pick<
      IAmortizationStat,
      "month" | "totalSavings" | "billfixersCharge"
    >
  >;
};

export type IFindBillQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IFindBillQuery = { __typename: "Query" } & {
  bill?: Maybe<{ __typename: "Bill" } & IBillFragment>;
};

export type IFindCurrentCustomerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IFindCurrentCustomerQuery = { __typename: "Query" } & {
  customer: { __typename: "Customer" } & ICustomerFragment;
};

export type IFindPlaidItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IFindPlaidItemQuery = { __typename: "Query" } & {
  plaidItem?: Maybe<{ __typename: "PlaidItem" } & IPlaidItemFragment>;
};

export type IListAccountsQueryVariables = Exact<{
  open?: Maybe<Scalars["Boolean"]>;
}>;

export type IListAccountsQuery = { __typename: "Query" } & {
  accounts: { __typename: "AccountConnection" } & Pick<
    IAccountConnection,
    "totalItemMonthlySavings" | "totalItemAnnualSavings" | "totalOtcSavings"
  > & {
      edges: Array<
        { __typename: "AccountEdge" } & {
          node?: Maybe<{ __typename: "Account" } & IAccountFragment>;
        }
      >;
    };
};

export type IListBankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type IListBankAccountsQuery = { __typename: "Query" } & {
  listBankAccounts: Array<{ __typename: "BankAccount" } & IBankAccountFragment>;
};

export type IListBillItemsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type IListBillItemsQuery = { __typename: "Query" } & {
  items: Array<{ __typename: "Item" } & IItemFragment>;
};

export type IListBillsQueryVariables = Exact<{
  fromPlaid?: Maybe<Scalars["Boolean"]>;
  plaidItemId?: Maybe<Scalars["ID"]>;
}>;

export type IListBillsQuery = { __typename: "Query" } & {
  bills: Array<{ __typename: "Bill" } & IBillFragment>;
};

export type IListCreditCardsQueryVariables = Exact<{ [key: string]: never }>;

export type IListCreditCardsQuery = { __typename: "Query" } & {
  listCreditCards: Array<{ __typename: "CreditCard" } & ICreditCardFragment>;
};

export type IListElectricityInsuranceReferralsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IListElectricityInsuranceReferralsQuery = {
  __typename: "Query";
} & {
  electricReferrals: Array<
    { __typename: "ElectricityReferral" } & Pick<
      IElectricityReferral,
      "id" | "providerId" | "providerName" | "successful"
    > & { providerLogo?: Maybe<{ __typename: "Image" } & IImageFragment> }
  >;
  insuranceReferrals: Array<
    { __typename: "InsuranceReferral" } & Pick<
      IInsuranceReferral,
      "id" | "providerId" | "providerName" | "successful"
    > & { providerLogo?: Maybe<{ __typename: "Image" } & IImageFragment> }
  >;
};

export type IListElectricityReferralsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IListElectricityReferralsQuery = { __typename: "Query" } & {
  referrals: Array<
    { __typename: "ElectricityReferral" } & Pick<
      IElectricityReferral,
      "id" | "providerId" | "providerName" | "successful"
    > & { providerLogo?: Maybe<{ __typename: "Image" } & IImageFragment> }
  >;
};

export type IListInsuranceReferralsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IListInsuranceReferralsQuery = { __typename: "Query" } & {
  referrals: Array<
    { __typename: "InsuranceReferral" } & Pick<
      IInsuranceReferral,
      "id" | "providerId" | "providerName" | "successful"
    > & { providerLogo?: Maybe<{ __typename: "Image" } & IImageFragment> }
  >;
};

export type IListInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type IListInvoicesQuery = { __typename: "Query" } & {
  invoices: { __typename: "InvoiceConnection" } & {
    nodes: Array<{ __typename: "Invoice" } & IInvoiceFragment>;
  };
};

export type IListMessagesQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type IListMessagesQuery = { __typename: "Query" } & {
  messages: Array<{ __typename: "Message" } & IMessageFragment>;
};

export type IListNegotiablePlaidTransactionsQueryVariables = Exact<{
  plaidItemId: Scalars["ID"];
}>;

export type IListNegotiablePlaidTransactionsQuery = { __typename: "Query" } & {
  transactions: Array<
    { __typename: "PlaidTransaction" } & IPlaidTransactionFragment
  >;
};

export type IListNegotiationsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type IListNegotiationsQuery = { __typename: "Query" } & {
  negotiations: Array<{ __typename: "Negotiation" } & INegotiationFragment>;
};

export type IListPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type IListPaymentMethodsQuery = { __typename: "Query" } & {
  creditCards: Array<{ __typename: "CreditCard" } & ICreditCardFragment>;
  bankAccounts: Array<{ __typename: "BankAccount" } & IBankAccountFragment>;
};

export type IListPlaidItemsQueryVariables = Exact<{
  onlyCheckingAccounts?: Maybe<Scalars["Boolean"]>;
}>;

export type IListPlaidItemsQuery = { __typename: "Query" } & {
  listPlaidItems: { __typename: "PlaidItemConnection" } & {
    nodes: Array<{ __typename: "PlaidItem" } & IPlaidItemFragment>;
  };
};

export type IListPlaidTransactionsQueryVariables = Exact<{
  plaidItemId?: Maybe<Scalars["ID"]>;
}>;

export type IListPlaidTransactionsQuery = { __typename: "Query" } & {
  transactions: { __typename: "PlaidTransactionConnection" } & Pick<
    IPlaidTransactionConnection,
    "totalCount" | "hasProviderMatches"
  >;
};

export type IListProviderPlaidTransactionsQueryVariables = Exact<{
  onlyMostRecent?: Maybe<Scalars["Boolean"]>;
  withoutBills?: Maybe<Scalars["Boolean"]>;
  plaidItemId?: Maybe<Scalars["ID"]>;
}>;

export type IListProviderPlaidTransactionsQuery = { __typename: "Query" } & {
  transactions: Array<
    { __typename: "PlaidTransaction" } & IPlaidTransactionFragment
  >;
};

export type IListProvidersQueryVariables = Exact<{
  excludeInsurance?: Maybe<Scalars["Boolean"]>;
  excludeElectricity?: Maybe<Scalars["Boolean"]>;
}>;

export type IListProvidersQuery = { __typename: "Query" } & {
  providers: Array<
    { __typename: "Provider" } & Pick<
      IProvider,
      | "id"
      | "name"
      | "services"
      | "billFields"
      | "scraperEnabled"
      | "totalRequiredProviderBillFields"
    >
  >;
};

export type Is3SignedPostQueryVariables = Exact<{
  filename: Scalars["String"];
  acl: Scalars["String"];
}>;

export type Is3SignedPostQuery = { __typename: "Query" } & {
  s3SignedPost?: Maybe<
    { __typename: "S3SignedPost" } & Pick<
      IS3SignedPost,
      | "acl"
      | "key"
      | "policy"
      | "successActionStatus"
      | "xAmzAlgorithm"
      | "xAmzCredential"
      | "xAmzDate"
      | "xAmzSignature"
    >
  >;
};

export type ISessionDataQueryVariables = Exact<{ [key: string]: never }>;

export type ISessionDataQuery = { __typename: "Query" } & {
  currentCustomer: { __typename: "Customer" } & ICustomerFragment;
  providers: Array<
    { __typename: "Provider" } & Pick<
      IProvider,
      "id" | "name" | "services" | "billFields" | "scraperEnabled"
    >
  >;
};

export const ImageFragmentDoc = gql`
  fragment ImageFragment on Image {
    id
    imageProcessed
    tinyUrl
    iconUrl
    thumbnailUrl
    smallUrl
    mediumUrl
    largeUrl
    grandeUrl
    originalUrl
  }
`;
export const AccountFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    balance
    createdAt
    endsOn
    monthsRemaining
    monthlyPaymentAmount
    monthlyPaymentAmountWithDiscounts
    name
    shortName
    itemMonthlySavings
    itemAnnualSavings
    providerName
    providerId
    providerLogo {
      ...ImageFragment
    }
    item {
      id
      name
      type
    }
  }
  ${ImageFragmentDoc}
`;
export const AccountTransactionFragmentDoc = gql`
  fragment AccountTransactionFragment on AccountTransaction {
    id
    transactionType
    amount
    description
  }
`;
export const ProviderFragmentDoc = gql`
  fragment ProviderFragment on Provider {
    id
    name
    services
    downloadInstructions
    billFields
    scraperEnabled
    totalRequiredProviderBillFields
    logo {
      ...ImageFragment
    }
  }
  ${ImageFragmentDoc}
`;
export const DocumentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    id
    title
    filename
    url
  }
`;
export const OfferFragmentDoc = gql`
  fragment OfferFragment on Offer {
    id
    negotiationId
    state
    content
    contentHtml
    daysUntilExpiration
    createdAt
    updatedAt
    acceptedAt
    rejectedAt
  }
`;
export const InformationRequestFragmentDoc = gql`
  fragment InformationRequestFragment on InformationRequest {
    id
    content
    contentHtml
    respondedAt
    fields {
      id
      label
      placeholder
      dataType
    }
  }
`;
export const ProviderBillFieldFragmentDoc = gql`
  fragment ProviderBillFieldFragment on ProviderBillField {
    id
    providerId
    label
    name
    placeholder
    dataType
    required
    requiredResponseLength
    helpText
  }
`;
export const BillFragmentDoc = gql`
  fragment BillFragment on Bill {
    id
    createdAt
    updatedAt
    startedAt
    providerId
    autoRenegotiate
    allowsContract
    last4ssn
    passcode
    initialNotes
    totalSavings
    currentSavings
    monthlySavings
    earnedSavings
    grossEarnedSavings
    grossSavings
    futureSavings
    nextExpectedSavings
    otcSavings
    otcOnly
    nextSavingsDate
    savingsStartOn
    savingsEndOn
    documentCount
    negotiationsCount
    status
    title
    deliveringReminderEmails
    plaidItemId
    plaidItemInstitutionName
    plaidItemInstitutionLogo
    assumedPlaidTransactionAccountName
    readyToNegotiate
    documentless
    hasDocumentOrEquivalentInfo
    currentFixer {
      firstNameLastInitial
    }
    provider {
      ...ProviderFragment
    }
    documents {
      ...DocumentFragment
    }
    offers {
      ...OfferFragment
    }
    informationRequests {
      ...InformationRequestFragment
    }
    providerBillFields {
      ...ProviderBillFieldFragment
    }
  }
  ${ProviderFragmentDoc}
  ${DocumentFragmentDoc}
  ${OfferFragmentDoc}
  ${InformationRequestFragmentDoc}
  ${ProviderBillFieldFragmentDoc}
`;
export const CreditCardFragmentDoc = gql`
  fragment CreditCardFragment on CreditCard {
    id
    last4
    token
    expMonth
    expYear
    brand
    default
    name
    expired
    stripePaymentMethodId
  }
`;
export const BankAccountFragmentDoc = gql`
  fragment BankAccountFragment on BankAccount {
    id
    customerId
    last4
    stripeId
    accountHolderName
    bankName
    fingerprint
    default
    plaidItemId
    plaidItemInstitutionName
    plaidItemInstitutionLogo
    acknowledged
  }
`;
export const InvoiceFragmentDoc = gql`
  fragment InvoiceFragment on Invoice {
    id
    issuedOn
    dueOn
    paid
    createdAt
    updatedAt
    totalAmountDue
    total
    paidOn
    paymentMethod
    paymentDetails
    path
  }
`;
export const CustomerFragmentDoc = gql`
  fragment CustomerFragment on Customer {
    id
    email
    name
    firstName
    lastName
    phoneNumber
    allTimeSavings
    allTimeGrossSavings
    referralCode
    referredByCode
    referralAmountPaid
    referralCount
    requirePaymentMethod
    vip
    b2b
    legacyBilling
    paymentPlan
    paymentPlanChosen
    unpaidInvoiceAmount
    nextPaymentDate
    regularMonthlyPaymentAmount
    nextMonthlyInvoiceAmount
    balance
    stripeId
    hasInvoices
    hasUnpaidInvoices
    discount
    lastSurveyedOn
    stripeBankAccountToken
    hasAtLeastOnePayment
    hasPlaidTransactions
    hasPlaidItems
    hasBills
    hasReadyBills
    hasAllReadyBills
    hasNegotiableBill
    hasPotentialAchPaymentMethod
    onboarding
    billCount
    readyBillCount
    defaultPaymentMethodType
    hasDefaultPaymentMethod
    hasBillsMissingInfo
    answeredAcquisitionSurvey
    trafficReferralUrl
    discountedRate
    billServices
    creditCards {
      ...CreditCardFragment
    }
    defaultCreditCard {
      ...CreditCardFragment
    }
    bankAccounts {
      ...BankAccountFragment
    }
    defaultBankAccount {
      ...BankAccountFragment
    }
    unpaidInvoices {
      ...InvoiceFragment
    }
    partner {
      id
      name
      referralCode
    }
    insuranceReferrals {
      id
      providerId
      successful
    }
    electricityReferrals {
      id
      providerId
      successful
    }
    arcadiaPowerReferrals {
      id
      providerId
    }
  }
  ${CreditCardFragmentDoc}
  ${BankAccountFragmentDoc}
  ${InvoiceFragmentDoc}
`;
export const CustomerAcquisitionSurveyFragmentDoc = gql`
  fragment CustomerAcquisitionSurveyFragment on CustomerAcquisitionSurvey {
    id
    customerId
    channel
    details
    createdAt
  }
`;
export const MessageFragmentDoc = gql`
  fragment MessageFragment on Message {
    id
    body
    bodyHtml
    messageType
    sentAt
    createdAt
    updatedAt
    sensitive
    direction
    fromName
    toName
  }
`;
export const ItemFragmentDoc = gql`
  fragment ItemFragment on Item {
    id
    type
    name
    savings
    savingsStartOn
    savingsEndOn
    monthlySavings
    duration
    expired
    prePrice
    postPrice
    previousId
    previousIds
    previousName
    createdAt
    updatedAt
    replaced
  }
`;
export const UserFragmentDoc = gql`
  fragment UserFragment on User {
    firstNameLastInitial
  }
`;
export const NegotiationFragmentDoc = gql`
  fragment NegotiationFragment on Negotiation {
    id
    billId
    state
    totalSavings
    createdAt
    billProvider {
      name
      logo {
        mediumUrl
      }
    }
    items {
      ...ItemFragment
    }
    fixers {
      ...UserFragment
    }
  }
  ${ItemFragmentDoc}
  ${UserFragmentDoc}
`;
export const PlaidItemFragmentDoc = gql`
  fragment PlaidItemFragment on PlaidItem {
    id
    customerId
    plaidAssignedItemId
    institutionId
    institutionName
    accountId
    accountName
    accountMask
    billCount
    institutionLogo
    institutionBrandColor
    institutionUrl
    createdAt
    updatedAt
    totalSavings
    needsReauthentication
  }
`;
export const PlaidTransactionFragmentDoc = gql`
  fragment PlaidTransactionFragment on PlaidTransaction {
    id
    providerId
    billId
    plaidItemId
    customerId
    accountId
    accountMask
    accountName
    accountOfficialName
    amount
    category
    categoryId
    postedDate
    authorizedDate
    merchantName
    name
    paymentChannel
    plaidTransactionId
    plaidTransactionType
    pending
    pendingTransactionId
    institutionName
    hasPreviousProviderMatch
    providerName
    providerServices
    providerLogo {
      ...ImageFragment
    }
  }
  ${ImageFragmentDoc}
`;
export const SurveyFragmentDoc = gql`
  fragment SurveyFragment on Survey {
    id
    rating
    feedback
    customer {
      id
      name
      email
      lastSurveyedOn
    }
  }
`;
export const CancelBillDocument = gql`
  mutation CancelBill($id: ID!, $reason: String!, $explanation: String) {
    payload: cancelBill(
      input: { id: $id, reason: $reason, explanation: $explanation }
    ) {
      success
      errors
      bill {
        ...BillFragment
      }
    }
  }
  ${BillFragmentDoc}
`;
export type ICancelBillMutationFn = ApolloReactCommon.MutationFunction<
  ICancelBillMutation,
  ICancelBillMutationVariables
>;

/**
 * __useCancelBillMutation__
 *
 * To run a mutation, you first call `useCancelBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBillMutation, { data, loading, error }] = useCancelBillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      explanation: // value for 'explanation'
 *   },
 * });
 */
export function useCancelBillMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICancelBillMutation,
    ICancelBillMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICancelBillMutation,
    ICancelBillMutationVariables
  >(CancelBillDocument, baseOptions);
}
export type CancelBillMutationHookResult = ReturnType<
  typeof useCancelBillMutation
>;
export type CancelBillMutationResult =
  ApolloReactCommon.MutationResult<ICancelBillMutation>;
export type CancelBillMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ICancelBillMutation,
  ICancelBillMutationVariables
>;
export const CreateBillDocument = gql`
  mutation CreateBill(
    $bill: BillAttributes!
    $checkForDuplicates: Boolean
    $allowDuplicate: Boolean
  ) {
    payload: createBill(
      input: {
        bill: $bill
        checkForDuplicates: $checkForDuplicates
        allowDuplicate: $allowDuplicate
      }
    ) {
      bill {
        ...BillFragment
      }
      success
      errors
      duplicateFound
      duplicateBillId
    }
  }
  ${BillFragmentDoc}
`;
export type ICreateBillMutationFn = ApolloReactCommon.MutationFunction<
  ICreateBillMutation,
  ICreateBillMutationVariables
>;

/**
 * __useCreateBillMutation__
 *
 * To run a mutation, you first call `useCreateBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillMutation, { data, loading, error }] = useCreateBillMutation({
 *   variables: {
 *      bill: // value for 'bill'
 *      checkForDuplicates: // value for 'checkForDuplicates'
 *      allowDuplicate: // value for 'allowDuplicate'
 *   },
 * });
 */
export function useCreateBillMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateBillMutation,
    ICreateBillMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateBillMutation,
    ICreateBillMutationVariables
  >(CreateBillDocument, baseOptions);
}
export type CreateBillMutationHookResult = ReturnType<
  typeof useCreateBillMutation
>;
export type CreateBillMutationResult =
  ApolloReactCommon.MutationResult<ICreateBillMutation>;
export type CreateBillMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ICreateBillMutation,
  ICreateBillMutationVariables
>;
export const CreateCreditCardDocument = gql`
  mutation CreateCreditCard(
    $params: CreditCardAttributes!
    $paymentPlan: String
    $paymentPlanChosen: Boolean
  ) {
    createCreditCard(
      input: {
        creditCard: $params
        paymentPlan: $paymentPlan
        paymentPlanChosen: $paymentPlanChosen
      }
    ) {
      success
      errors
      creditCard {
        ...CreditCardFragment
      }
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CreditCardFragmentDoc}
  ${CustomerFragmentDoc}
`;
export type ICreateCreditCardMutationFn = ApolloReactCommon.MutationFunction<
  ICreateCreditCardMutation,
  ICreateCreditCardMutationVariables
>;

/**
 * __useCreateCreditCardMutation__
 *
 * To run a mutation, you first call `useCreateCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditCardMutation, { data, loading, error }] = useCreateCreditCardMutation({
 *   variables: {
 *      params: // value for 'params'
 *      paymentPlan: // value for 'paymentPlan'
 *      paymentPlanChosen: // value for 'paymentPlanChosen'
 *   },
 * });
 */
export function useCreateCreditCardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateCreditCardMutation,
    ICreateCreditCardMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateCreditCardMutation,
    ICreateCreditCardMutationVariables
  >(CreateCreditCardDocument, baseOptions);
}
export type CreateCreditCardMutationHookResult = ReturnType<
  typeof useCreateCreditCardMutation
>;
export type CreateCreditCardMutationResult =
  ApolloReactCommon.MutationResult<ICreateCreditCardMutation>;
export type CreateCreditCardMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreateCreditCardMutation,
    ICreateCreditCardMutationVariables
  >;
export const CreateCreditCardAndMakePaymentDocument = gql`
  mutation CreateCreditCardAndMakePayment($params: CreditCardAttributes!) {
    createCreditCardAndMakePayment(input: { creditCard: $params }) {
      success
      errors
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragmentDoc}
`;
export type ICreateCreditCardAndMakePaymentMutationFn =
  ApolloReactCommon.MutationFunction<
    ICreateCreditCardAndMakePaymentMutation,
    ICreateCreditCardAndMakePaymentMutationVariables
  >;

/**
 * __useCreateCreditCardAndMakePaymentMutation__
 *
 * To run a mutation, you first call `useCreateCreditCardAndMakePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditCardAndMakePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditCardAndMakePaymentMutation, { data, loading, error }] = useCreateCreditCardAndMakePaymentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCreditCardAndMakePaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateCreditCardAndMakePaymentMutation,
    ICreateCreditCardAndMakePaymentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateCreditCardAndMakePaymentMutation,
    ICreateCreditCardAndMakePaymentMutationVariables
  >(CreateCreditCardAndMakePaymentDocument, baseOptions);
}
export type CreateCreditCardAndMakePaymentMutationHookResult = ReturnType<
  typeof useCreateCreditCardAndMakePaymentMutation
>;
export type CreateCreditCardAndMakePaymentMutationResult =
  ApolloReactCommon.MutationResult<ICreateCreditCardAndMakePaymentMutation>;
export type CreateCreditCardAndMakePaymentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreateCreditCardAndMakePaymentMutation,
    ICreateCreditCardAndMakePaymentMutationVariables
  >;
export const CreateDocumentDocument = gql`
  mutation CreateDocument($billId: ID!, $document: DocumentAttributes!) {
    payload: createDocument(input: { billId: $billId, document: $document }) {
      document {
        id
        title
        url
      }
      bill {
        ...BillFragment
      }
      errors
    }
  }
  ${BillFragmentDoc}
`;
export type ICreateDocumentMutationFn = ApolloReactCommon.MutationFunction<
  ICreateDocumentMutation,
  ICreateDocumentMutationVariables
>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      document: // value for 'document'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateDocumentMutation,
    ICreateDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateDocumentMutation,
    ICreateDocumentMutationVariables
  >(CreateDocumentDocument, baseOptions);
}
export type CreateDocumentMutationHookResult = ReturnType<
  typeof useCreateDocumentMutation
>;
export type CreateDocumentMutationResult =
  ApolloReactCommon.MutationResult<ICreateDocumentMutation>;
export type CreateDocumentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreateDocumentMutation,
    ICreateDocumentMutationVariables
  >;
export const CreatePaymentDocument = gql`
  mutation CreatePayment($params: PaymentAttributes!) {
    createPayment(input: { payment: $params }) {
      success
      errors
      payment {
        id
      }
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragmentDoc}
`;
export type ICreatePaymentMutationFn = ApolloReactCommon.MutationFunction<
  ICreatePaymentMutation,
  ICreatePaymentMutationVariables
>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreatePaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreatePaymentMutation,
    ICreatePaymentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreatePaymentMutation,
    ICreatePaymentMutationVariables
  >(CreatePaymentDocument, baseOptions);
}
export type CreatePaymentMutationHookResult = ReturnType<
  typeof useCreatePaymentMutation
>;
export type CreatePaymentMutationResult =
  ApolloReactCommon.MutationResult<ICreatePaymentMutation>;
export type CreatePaymentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreatePaymentMutation,
    ICreatePaymentMutationVariables
  >;
export const SetBillAllowsContractDocument = gql`
  mutation SetBillAllowsContract($id: ID!, $allowsContract: Boolean!) {
    payload: setBillAllowsContract(
      input: { id: $id, allowsContract: $allowsContract }
    ) {
      errors
      bill {
        id
        allowsContract
      }
    }
  }
`;
export type ISetBillAllowsContractMutationFn =
  ApolloReactCommon.MutationFunction<
    ISetBillAllowsContractMutation,
    ISetBillAllowsContractMutationVariables
  >;

/**
 * __useSetBillAllowsContractMutation__
 *
 * To run a mutation, you first call `useSetBillAllowsContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillAllowsContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillAllowsContractMutation, { data, loading, error }] = useSetBillAllowsContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      allowsContract: // value for 'allowsContract'
 *   },
 * });
 */
export function useSetBillAllowsContractMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISetBillAllowsContractMutation,
    ISetBillAllowsContractMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ISetBillAllowsContractMutation,
    ISetBillAllowsContractMutationVariables
  >(SetBillAllowsContractDocument, baseOptions);
}
export type SetBillAllowsContractMutationHookResult = ReturnType<
  typeof useSetBillAllowsContractMutation
>;
export type SetBillAllowsContractMutationResult =
  ApolloReactCommon.MutationResult<ISetBillAllowsContractMutation>;
export type SetBillAllowsContractMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISetBillAllowsContractMutation,
    ISetBillAllowsContractMutationVariables
  >;
export const SetBillAutoRenegotiateDocument = gql`
  mutation SetBillAutoRenegotiate($id: ID!, $autoRenegotiate: Boolean!) {
    payload: setBillAutoRenegotiate(
      input: { id: $id, autoRenegotiate: $autoRenegotiate }
    ) {
      errors
      bill {
        id
        autoRenegotiate
      }
    }
  }
`;
export type ISetBillAutoRenegotiateMutationFn =
  ApolloReactCommon.MutationFunction<
    ISetBillAutoRenegotiateMutation,
    ISetBillAutoRenegotiateMutationVariables
  >;

/**
 * __useSetBillAutoRenegotiateMutation__
 *
 * To run a mutation, you first call `useSetBillAutoRenegotiateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillAutoRenegotiateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillAutoRenegotiateMutation, { data, loading, error }] = useSetBillAutoRenegotiateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      autoRenegotiate: // value for 'autoRenegotiate'
 *   },
 * });
 */
export function useSetBillAutoRenegotiateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISetBillAutoRenegotiateMutation,
    ISetBillAutoRenegotiateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ISetBillAutoRenegotiateMutation,
    ISetBillAutoRenegotiateMutationVariables
  >(SetBillAutoRenegotiateDocument, baseOptions);
}
export type SetBillAutoRenegotiateMutationHookResult = ReturnType<
  typeof useSetBillAutoRenegotiateMutation
>;
export type SetBillAutoRenegotiateMutationResult =
  ApolloReactCommon.MutationResult<ISetBillAutoRenegotiateMutation>;
export type SetBillAutoRenegotiateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISetBillAutoRenegotiateMutation,
    ISetBillAutoRenegotiateMutationVariables
  >;
export const SetBillDocumentlessDocument = gql`
  mutation SetBillDocumentless($billId: ID!, $documentless: Boolean!) {
    setBillDocumentless(
      input: { billId: $billId, documentless: $documentless }
    ) {
      success
      errors
      bill {
        id
        documentless
      }
    }
  }
`;
export type ISetBillDocumentlessMutationFn = ApolloReactCommon.MutationFunction<
  ISetBillDocumentlessMutation,
  ISetBillDocumentlessMutationVariables
>;

/**
 * __useSetBillDocumentlessMutation__
 *
 * To run a mutation, you first call `useSetBillDocumentlessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillDocumentlessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillDocumentlessMutation, { data, loading, error }] = useSetBillDocumentlessMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      documentless: // value for 'documentless'
 *   },
 * });
 */
export function useSetBillDocumentlessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISetBillDocumentlessMutation,
    ISetBillDocumentlessMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ISetBillDocumentlessMutation,
    ISetBillDocumentlessMutationVariables
  >(SetBillDocumentlessDocument, baseOptions);
}
export type SetBillDocumentlessMutationHookResult = ReturnType<
  typeof useSetBillDocumentlessMutation
>;
export type SetBillDocumentlessMutationResult =
  ApolloReactCommon.MutationResult<ISetBillDocumentlessMutation>;
export type SetBillDocumentlessMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISetBillDocumentlessMutation,
    ISetBillDocumentlessMutationVariables
  >;
export const SetCreditCardDefaultDocument = gql`
  mutation SetCreditCardDefault($id: ID!) {
    setCreditCardDefault(input: { id: $id }) {
      success
      errors
      creditCard {
        ...CreditCardFragment
      }
    }
  }
  ${CreditCardFragmentDoc}
`;
export type ISetCreditCardDefaultMutationFn =
  ApolloReactCommon.MutationFunction<
    ISetCreditCardDefaultMutation,
    ISetCreditCardDefaultMutationVariables
  >;

/**
 * __useSetCreditCardDefaultMutation__
 *
 * To run a mutation, you first call `useSetCreditCardDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCreditCardDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCreditCardDefaultMutation, { data, loading, error }] = useSetCreditCardDefaultMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetCreditCardDefaultMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ISetCreditCardDefaultMutation,
    ISetCreditCardDefaultMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ISetCreditCardDefaultMutation,
    ISetCreditCardDefaultMutationVariables
  >(SetCreditCardDefaultDocument, baseOptions);
}
export type SetCreditCardDefaultMutationHookResult = ReturnType<
  typeof useSetCreditCardDefaultMutation
>;
export type SetCreditCardDefaultMutationResult =
  ApolloReactCommon.MutationResult<ISetCreditCardDefaultMutation>;
export type SetCreditCardDefaultMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ISetCreditCardDefaultMutation,
    ISetCreditCardDefaultMutationVariables
  >;
export const UpdateBillDocument = gql`
  mutation UpdateBill($id: ID!, $bill: BillAttributes!) {
    payload: updateBill(input: { id: $id, bill: $bill }) {
      bill {
        ...BillFragment
      }
      success
      errors
    }
  }
  ${BillFragmentDoc}
`;
export type IUpdateBillMutationFn = ApolloReactCommon.MutationFunction<
  IUpdateBillMutation,
  IUpdateBillMutationVariables
>;

/**
 * __useUpdateBillMutation__
 *
 * To run a mutation, you first call `useUpdateBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillMutation, { data, loading, error }] = useUpdateBillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bill: // value for 'bill'
 *   },
 * });
 */
export function useUpdateBillMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdateBillMutation,
    IUpdateBillMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IUpdateBillMutation,
    IUpdateBillMutationVariables
  >(UpdateBillDocument, baseOptions);
}
export type UpdateBillMutationHookResult = ReturnType<
  typeof useUpdateBillMutation
>;
export type UpdateBillMutationResult =
  ApolloReactCommon.MutationResult<IUpdateBillMutation>;
export type UpdateBillMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IUpdateBillMutation,
  IUpdateBillMutationVariables
>;
export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($params: CustomerAttributes!) {
    updateCustomer(input: { customer: $params }) {
      errors
      customer {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragmentDoc}
`;
export type IUpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  IUpdateCustomerMutation,
  IUpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdateCustomerMutation,
    IUpdateCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IUpdateCustomerMutation,
    IUpdateCustomerMutationVariables
  >(UpdateCustomerDocument, baseOptions);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult =
  ApolloReactCommon.MutationResult<IUpdateCustomerMutation>;
export type UpdateCustomerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdateCustomerMutation,
    IUpdateCustomerMutationVariables
  >;
export const FindAmortizationStatsDocument = gql`
  query FindAmortizationStats {
    amortizationStats: findAmortizationStats {
      month
      totalSavings
      billfixersCharge
    }
  }
`;

/**
 * __useFindAmortizationStatsQuery__
 *
 * To run a query within a React component, call `useFindAmortizationStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAmortizationStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAmortizationStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAmortizationStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFindAmortizationStatsQuery,
    IFindAmortizationStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IFindAmortizationStatsQuery,
    IFindAmortizationStatsQueryVariables
  >(FindAmortizationStatsDocument, baseOptions);
}
export function useFindAmortizationStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindAmortizationStatsQuery,
    IFindAmortizationStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindAmortizationStatsQuery,
    IFindAmortizationStatsQueryVariables
  >(FindAmortizationStatsDocument, baseOptions);
}
export type FindAmortizationStatsQueryHookResult = ReturnType<
  typeof useFindAmortizationStatsQuery
>;
export type FindAmortizationStatsLazyQueryHookResult = ReturnType<
  typeof useFindAmortizationStatsLazyQuery
>;
export type FindAmortizationStatsQueryResult = ApolloReactCommon.QueryResult<
  IFindAmortizationStatsQuery,
  IFindAmortizationStatsQueryVariables
>;
export const FindBillDocument = gql`
  query FindBill($id: ID!) {
    bill: findBill(id: $id) {
      ...BillFragment
    }
  }
  ${BillFragmentDoc}
`;

/**
 * __useFindBillQuery__
 *
 * To run a query within a React component, call `useFindBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBillQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindBillQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindBillQuery,
    IFindBillQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IFindBillQuery, IFindBillQueryVariables>(
    FindBillDocument,
    baseOptions
  );
}
export function useFindBillLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindBillQuery,
    IFindBillQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<IFindBillQuery, IFindBillQueryVariables>(
    FindBillDocument,
    baseOptions
  );
}
export type FindBillQueryHookResult = ReturnType<typeof useFindBillQuery>;
export type FindBillLazyQueryHookResult = ReturnType<
  typeof useFindBillLazyQuery
>;
export type FindBillQueryResult = ApolloReactCommon.QueryResult<
  IFindBillQuery,
  IFindBillQueryVariables
>;
export const FindCurrentCustomerDocument = gql`
  query FindCurrentCustomer {
    customer: findCurrentCustomer {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useFindCurrentCustomerQuery__
 *
 * To run a query within a React component, call `useFindCurrentCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrentCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrentCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCurrentCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFindCurrentCustomerQuery,
    IFindCurrentCustomerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IFindCurrentCustomerQuery,
    IFindCurrentCustomerQueryVariables
  >(FindCurrentCustomerDocument, baseOptions);
}
export function useFindCurrentCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindCurrentCustomerQuery,
    IFindCurrentCustomerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindCurrentCustomerQuery,
    IFindCurrentCustomerQueryVariables
  >(FindCurrentCustomerDocument, baseOptions);
}
export type FindCurrentCustomerQueryHookResult = ReturnType<
  typeof useFindCurrentCustomerQuery
>;
export type FindCurrentCustomerLazyQueryHookResult = ReturnType<
  typeof useFindCurrentCustomerLazyQuery
>;
export type FindCurrentCustomerQueryResult = ApolloReactCommon.QueryResult<
  IFindCurrentCustomerQuery,
  IFindCurrentCustomerQueryVariables
>;
export const FindPlaidItemDocument = gql`
  query FindPlaidItem($id: ID!) {
    plaidItem: findPlaidItem(id: $id) {
      ...PlaidItemFragment
    }
  }
  ${PlaidItemFragmentDoc}
`;

/**
 * __useFindPlaidItemQuery__
 *
 * To run a query within a React component, call `useFindPlaidItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPlaidItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPlaidItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindPlaidItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindPlaidItemQuery,
    IFindPlaidItemQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IFindPlaidItemQuery,
    IFindPlaidItemQueryVariables
  >(FindPlaidItemDocument, baseOptions);
}
export function useFindPlaidItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindPlaidItemQuery,
    IFindPlaidItemQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindPlaidItemQuery,
    IFindPlaidItemQueryVariables
  >(FindPlaidItemDocument, baseOptions);
}
export type FindPlaidItemQueryHookResult = ReturnType<
  typeof useFindPlaidItemQuery
>;
export type FindPlaidItemLazyQueryHookResult = ReturnType<
  typeof useFindPlaidItemLazyQuery
>;
export type FindPlaidItemQueryResult = ApolloReactCommon.QueryResult<
  IFindPlaidItemQuery,
  IFindPlaidItemQueryVariables
>;
export const ListAccountsDocument = gql`
  query ListAccounts($open: Boolean) {
    accounts: listAccounts(open: $open) {
      totalItemMonthlySavings
      totalItemAnnualSavings
      totalOtcSavings
      edges {
        node {
          ...AccountFragment
        }
      }
    }
  }
  ${AccountFragmentDoc}
`;

/**
 * __useListAccountsQuery__
 *
 * To run a query within a React component, call `useListAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountsQuery({
 *   variables: {
 *      open: // value for 'open'
 *   },
 * });
 */
export function useListAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListAccountsQuery,
    IListAccountsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListAccountsQuery,
    IListAccountsQueryVariables
  >(ListAccountsDocument, baseOptions);
}
export function useListAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListAccountsQuery,
    IListAccountsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListAccountsQuery,
    IListAccountsQueryVariables
  >(ListAccountsDocument, baseOptions);
}
export type ListAccountsQueryHookResult = ReturnType<
  typeof useListAccountsQuery
>;
export type ListAccountsLazyQueryHookResult = ReturnType<
  typeof useListAccountsLazyQuery
>;
export type ListAccountsQueryResult = ApolloReactCommon.QueryResult<
  IListAccountsQuery,
  IListAccountsQueryVariables
>;
export const ListBankAccountsDocument = gql`
  query ListBankAccounts {
    listBankAccounts {
      ...BankAccountFragment
    }
  }
  ${BankAccountFragmentDoc}
`;

/**
 * __useListBankAccountsQuery__
 *
 * To run a query within a React component, call `useListBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBankAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListBankAccountsQuery,
    IListBankAccountsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListBankAccountsQuery,
    IListBankAccountsQueryVariables
  >(ListBankAccountsDocument, baseOptions);
}
export function useListBankAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListBankAccountsQuery,
    IListBankAccountsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListBankAccountsQuery,
    IListBankAccountsQueryVariables
  >(ListBankAccountsDocument, baseOptions);
}
export type ListBankAccountsQueryHookResult = ReturnType<
  typeof useListBankAccountsQuery
>;
export type ListBankAccountsLazyQueryHookResult = ReturnType<
  typeof useListBankAccountsLazyQuery
>;
export type ListBankAccountsQueryResult = ApolloReactCommon.QueryResult<
  IListBankAccountsQuery,
  IListBankAccountsQueryVariables
>;
export const ListBillItemsDocument = gql`
  query ListBillItems($billId: ID!) {
    items: listBillItems(billId: $billId) {
      ...ItemFragment
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useListBillItemsQuery__
 *
 * To run a query within a React component, call `useListBillItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBillItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBillItemsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useListBillItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IListBillItemsQuery,
    IListBillItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListBillItemsQuery,
    IListBillItemsQueryVariables
  >(ListBillItemsDocument, baseOptions);
}
export function useListBillItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListBillItemsQuery,
    IListBillItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListBillItemsQuery,
    IListBillItemsQueryVariables
  >(ListBillItemsDocument, baseOptions);
}
export type ListBillItemsQueryHookResult = ReturnType<
  typeof useListBillItemsQuery
>;
export type ListBillItemsLazyQueryHookResult = ReturnType<
  typeof useListBillItemsLazyQuery
>;
export type ListBillItemsQueryResult = ApolloReactCommon.QueryResult<
  IListBillItemsQuery,
  IListBillItemsQueryVariables
>;
export const ListBillsDocument = gql`
  query ListBills($fromPlaid: Boolean, $plaidItemId: ID) {
    bills: listBills(fromPlaid: $fromPlaid, plaidItemId: $plaidItemId) {
      ...BillFragment
    }
  }
  ${BillFragmentDoc}
`;

/**
 * __useListBillsQuery__
 *
 * To run a query within a React component, call `useListBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBillsQuery({
 *   variables: {
 *      fromPlaid: // value for 'fromPlaid'
 *      plaidItemId: // value for 'plaidItemId'
 *   },
 * });
 */
export function useListBillsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListBillsQuery,
    IListBillsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IListBillsQuery, IListBillsQueryVariables>(
    ListBillsDocument,
    baseOptions
  );
}
export function useListBillsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListBillsQuery,
    IListBillsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListBillsQuery,
    IListBillsQueryVariables
  >(ListBillsDocument, baseOptions);
}
export type ListBillsQueryHookResult = ReturnType<typeof useListBillsQuery>;
export type ListBillsLazyQueryHookResult = ReturnType<
  typeof useListBillsLazyQuery
>;
export type ListBillsQueryResult = ApolloReactCommon.QueryResult<
  IListBillsQuery,
  IListBillsQueryVariables
>;
export const ListCreditCardsDocument = gql`
  query ListCreditCards {
    listCreditCards {
      ...CreditCardFragment
    }
  }
  ${CreditCardFragmentDoc}
`;

/**
 * __useListCreditCardsQuery__
 *
 * To run a query within a React component, call `useListCreditCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCreditCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCreditCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCreditCardsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListCreditCardsQuery,
    IListCreditCardsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListCreditCardsQuery,
    IListCreditCardsQueryVariables
  >(ListCreditCardsDocument, baseOptions);
}
export function useListCreditCardsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListCreditCardsQuery,
    IListCreditCardsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListCreditCardsQuery,
    IListCreditCardsQueryVariables
  >(ListCreditCardsDocument, baseOptions);
}
export type ListCreditCardsQueryHookResult = ReturnType<
  typeof useListCreditCardsQuery
>;
export type ListCreditCardsLazyQueryHookResult = ReturnType<
  typeof useListCreditCardsLazyQuery
>;
export type ListCreditCardsQueryResult = ApolloReactCommon.QueryResult<
  IListCreditCardsQuery,
  IListCreditCardsQueryVariables
>;
export const ListElectricityInsuranceReferralsDocument = gql`
  query ListElectricityInsuranceReferrals {
    electricReferrals: listElectricityReferrals {
      __typename
      id
      providerId
      providerName
      providerLogo {
        ...ImageFragment
      }
      successful
    }
    insuranceReferrals: listInsuranceReferrals {
      __typename
      id
      providerId
      providerName
      providerLogo {
        ...ImageFragment
      }
      successful
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useListElectricityInsuranceReferralsQuery__
 *
 * To run a query within a React component, call `useListElectricityInsuranceReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListElectricityInsuranceReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListElectricityInsuranceReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListElectricityInsuranceReferralsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListElectricityInsuranceReferralsQuery,
    IListElectricityInsuranceReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListElectricityInsuranceReferralsQuery,
    IListElectricityInsuranceReferralsQueryVariables
  >(ListElectricityInsuranceReferralsDocument, baseOptions);
}
export function useListElectricityInsuranceReferralsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListElectricityInsuranceReferralsQuery,
    IListElectricityInsuranceReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListElectricityInsuranceReferralsQuery,
    IListElectricityInsuranceReferralsQueryVariables
  >(ListElectricityInsuranceReferralsDocument, baseOptions);
}
export type ListElectricityInsuranceReferralsQueryHookResult = ReturnType<
  typeof useListElectricityInsuranceReferralsQuery
>;
export type ListElectricityInsuranceReferralsLazyQueryHookResult = ReturnType<
  typeof useListElectricityInsuranceReferralsLazyQuery
>;
export type ListElectricityInsuranceReferralsQueryResult =
  ApolloReactCommon.QueryResult<
    IListElectricityInsuranceReferralsQuery,
    IListElectricityInsuranceReferralsQueryVariables
  >;
export const ListElectricityReferralsDocument = gql`
  query ListElectricityReferrals {
    referrals: listElectricityReferrals {
      __typename
      id
      providerId
      providerName
      providerLogo {
        ...ImageFragment
      }
      successful
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useListElectricityReferralsQuery__
 *
 * To run a query within a React component, call `useListElectricityReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListElectricityReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListElectricityReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListElectricityReferralsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListElectricityReferralsQuery,
    IListElectricityReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListElectricityReferralsQuery,
    IListElectricityReferralsQueryVariables
  >(ListElectricityReferralsDocument, baseOptions);
}
export function useListElectricityReferralsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListElectricityReferralsQuery,
    IListElectricityReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListElectricityReferralsQuery,
    IListElectricityReferralsQueryVariables
  >(ListElectricityReferralsDocument, baseOptions);
}
export type ListElectricityReferralsQueryHookResult = ReturnType<
  typeof useListElectricityReferralsQuery
>;
export type ListElectricityReferralsLazyQueryHookResult = ReturnType<
  typeof useListElectricityReferralsLazyQuery
>;
export type ListElectricityReferralsQueryResult = ApolloReactCommon.QueryResult<
  IListElectricityReferralsQuery,
  IListElectricityReferralsQueryVariables
>;
export const ListInsuranceReferralsDocument = gql`
  query ListInsuranceReferrals {
    referrals: listInsuranceReferrals {
      __typename
      id
      providerId
      providerName
      providerLogo {
        ...ImageFragment
      }
      successful
    }
  }
  ${ImageFragmentDoc}
`;

/**
 * __useListInsuranceReferralsQuery__
 *
 * To run a query within a React component, call `useListInsuranceReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInsuranceReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInsuranceReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListInsuranceReferralsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListInsuranceReferralsQuery,
    IListInsuranceReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListInsuranceReferralsQuery,
    IListInsuranceReferralsQueryVariables
  >(ListInsuranceReferralsDocument, baseOptions);
}
export function useListInsuranceReferralsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListInsuranceReferralsQuery,
    IListInsuranceReferralsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListInsuranceReferralsQuery,
    IListInsuranceReferralsQueryVariables
  >(ListInsuranceReferralsDocument, baseOptions);
}
export type ListInsuranceReferralsQueryHookResult = ReturnType<
  typeof useListInsuranceReferralsQuery
>;
export type ListInsuranceReferralsLazyQueryHookResult = ReturnType<
  typeof useListInsuranceReferralsLazyQuery
>;
export type ListInsuranceReferralsQueryResult = ApolloReactCommon.QueryResult<
  IListInsuranceReferralsQuery,
  IListInsuranceReferralsQueryVariables
>;
export const ListInvoicesDocument = gql`
  query ListInvoices {
    invoices: listInvoices {
      nodes {
        ...InvoiceFragment
      }
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useListInvoicesQuery__
 *
 * To run a query within a React component, call `useListInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListInvoicesQuery,
    IListInvoicesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListInvoicesQuery,
    IListInvoicesQueryVariables
  >(ListInvoicesDocument, baseOptions);
}
export function useListInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListInvoicesQuery,
    IListInvoicesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListInvoicesQuery,
    IListInvoicesQueryVariables
  >(ListInvoicesDocument, baseOptions);
}
export type ListInvoicesQueryHookResult = ReturnType<
  typeof useListInvoicesQuery
>;
export type ListInvoicesLazyQueryHookResult = ReturnType<
  typeof useListInvoicesLazyQuery
>;
export type ListInvoicesQueryResult = ApolloReactCommon.QueryResult<
  IListInvoicesQuery,
  IListInvoicesQueryVariables
>;
export const ListMessagesDocument = gql`
  query ListMessages($billId: ID!) {
    messages: listMessages(billId: $billId) {
      ...MessageFragment
    }
  }
  ${MessageFragmentDoc}
`;

/**
 * __useListMessagesQuery__
 *
 * To run a query within a React component, call `useListMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessagesQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useListMessagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IListMessagesQuery,
    IListMessagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListMessagesQuery,
    IListMessagesQueryVariables
  >(ListMessagesDocument, baseOptions);
}
export function useListMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListMessagesQuery,
    IListMessagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListMessagesQuery,
    IListMessagesQueryVariables
  >(ListMessagesDocument, baseOptions);
}
export type ListMessagesQueryHookResult = ReturnType<
  typeof useListMessagesQuery
>;
export type ListMessagesLazyQueryHookResult = ReturnType<
  typeof useListMessagesLazyQuery
>;
export type ListMessagesQueryResult = ApolloReactCommon.QueryResult<
  IListMessagesQuery,
  IListMessagesQueryVariables
>;
export const ListNegotiablePlaidTransactionsDocument = gql`
  query ListNegotiablePlaidTransactions($plaidItemId: ID!) {
    transactions: listNegotiablePlaidTransactions(plaidItemId: $plaidItemId) {
      ...PlaidTransactionFragment
    }
  }
  ${PlaidTransactionFragmentDoc}
`;

/**
 * __useListNegotiablePlaidTransactionsQuery__
 *
 * To run a query within a React component, call `useListNegotiablePlaidTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNegotiablePlaidTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNegotiablePlaidTransactionsQuery({
 *   variables: {
 *      plaidItemId: // value for 'plaidItemId'
 *   },
 * });
 */
export function useListNegotiablePlaidTransactionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IListNegotiablePlaidTransactionsQuery,
    IListNegotiablePlaidTransactionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListNegotiablePlaidTransactionsQuery,
    IListNegotiablePlaidTransactionsQueryVariables
  >(ListNegotiablePlaidTransactionsDocument, baseOptions);
}
export function useListNegotiablePlaidTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListNegotiablePlaidTransactionsQuery,
    IListNegotiablePlaidTransactionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListNegotiablePlaidTransactionsQuery,
    IListNegotiablePlaidTransactionsQueryVariables
  >(ListNegotiablePlaidTransactionsDocument, baseOptions);
}
export type ListNegotiablePlaidTransactionsQueryHookResult = ReturnType<
  typeof useListNegotiablePlaidTransactionsQuery
>;
export type ListNegotiablePlaidTransactionsLazyQueryHookResult = ReturnType<
  typeof useListNegotiablePlaidTransactionsLazyQuery
>;
export type ListNegotiablePlaidTransactionsQueryResult =
  ApolloReactCommon.QueryResult<
    IListNegotiablePlaidTransactionsQuery,
    IListNegotiablePlaidTransactionsQueryVariables
  >;
export const ListNegotiationsDocument = gql`
  query ListNegotiations($billId: ID!) {
    negotiations: listNegotiations(billId: $billId) {
      ...NegotiationFragment
    }
  }
  ${NegotiationFragmentDoc}
`;

/**
 * __useListNegotiationsQuery__
 *
 * To run a query within a React component, call `useListNegotiationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNegotiationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNegotiationsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useListNegotiationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IListNegotiationsQuery,
    IListNegotiationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListNegotiationsQuery,
    IListNegotiationsQueryVariables
  >(ListNegotiationsDocument, baseOptions);
}
export function useListNegotiationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListNegotiationsQuery,
    IListNegotiationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListNegotiationsQuery,
    IListNegotiationsQueryVariables
  >(ListNegotiationsDocument, baseOptions);
}
export type ListNegotiationsQueryHookResult = ReturnType<
  typeof useListNegotiationsQuery
>;
export type ListNegotiationsLazyQueryHookResult = ReturnType<
  typeof useListNegotiationsLazyQuery
>;
export type ListNegotiationsQueryResult = ApolloReactCommon.QueryResult<
  IListNegotiationsQuery,
  IListNegotiationsQueryVariables
>;
export const ListPaymentMethodsDocument = gql`
  query ListPaymentMethods {
    creditCards: listCreditCards {
      __typename
      ...CreditCardFragment
    }
    bankAccounts: listBankAccounts {
      __typename
      ...BankAccountFragment
    }
  }
  ${CreditCardFragmentDoc}
  ${BankAccountFragmentDoc}
`;

/**
 * __useListPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useListPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPaymentMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListPaymentMethodsQuery,
    IListPaymentMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListPaymentMethodsQuery,
    IListPaymentMethodsQueryVariables
  >(ListPaymentMethodsDocument, baseOptions);
}
export function useListPaymentMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListPaymentMethodsQuery,
    IListPaymentMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListPaymentMethodsQuery,
    IListPaymentMethodsQueryVariables
  >(ListPaymentMethodsDocument, baseOptions);
}
export type ListPaymentMethodsQueryHookResult = ReturnType<
  typeof useListPaymentMethodsQuery
>;
export type ListPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useListPaymentMethodsLazyQuery
>;
export type ListPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
  IListPaymentMethodsQuery,
  IListPaymentMethodsQueryVariables
>;
export const ListPlaidItemsDocument = gql`
  query ListPlaidItems($onlyCheckingAccounts: Boolean) {
    listPlaidItems(onlyCheckingAccounts: $onlyCheckingAccounts) {
      nodes {
        ...PlaidItemFragment
      }
    }
  }
  ${PlaidItemFragmentDoc}
`;

/**
 * __useListPlaidItemsQuery__
 *
 * To run a query within a React component, call `useListPlaidItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlaidItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlaidItemsQuery({
 *   variables: {
 *      onlyCheckingAccounts: // value for 'onlyCheckingAccounts'
 *   },
 * });
 */
export function useListPlaidItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListPlaidItemsQuery,
    IListPlaidItemsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListPlaidItemsQuery,
    IListPlaidItemsQueryVariables
  >(ListPlaidItemsDocument, baseOptions);
}
export function useListPlaidItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListPlaidItemsQuery,
    IListPlaidItemsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListPlaidItemsQuery,
    IListPlaidItemsQueryVariables
  >(ListPlaidItemsDocument, baseOptions);
}
export type ListPlaidItemsQueryHookResult = ReturnType<
  typeof useListPlaidItemsQuery
>;
export type ListPlaidItemsLazyQueryHookResult = ReturnType<
  typeof useListPlaidItemsLazyQuery
>;
export type ListPlaidItemsQueryResult = ApolloReactCommon.QueryResult<
  IListPlaidItemsQuery,
  IListPlaidItemsQueryVariables
>;
export const ListPlaidTransactionsDocument = gql`
  query ListPlaidTransactions($plaidItemId: ID) {
    transactions: listPlaidTransactions(plaidItemId: $plaidItemId) {
      totalCount
      hasProviderMatches
    }
  }
`;

/**
 * __useListPlaidTransactionsQuery__
 *
 * To run a query within a React component, call `useListPlaidTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlaidTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlaidTransactionsQuery({
 *   variables: {
 *      plaidItemId: // value for 'plaidItemId'
 *   },
 * });
 */
export function useListPlaidTransactionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListPlaidTransactionsQuery,
    IListPlaidTransactionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListPlaidTransactionsQuery,
    IListPlaidTransactionsQueryVariables
  >(ListPlaidTransactionsDocument, baseOptions);
}
export function useListPlaidTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListPlaidTransactionsQuery,
    IListPlaidTransactionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListPlaidTransactionsQuery,
    IListPlaidTransactionsQueryVariables
  >(ListPlaidTransactionsDocument, baseOptions);
}
export type ListPlaidTransactionsQueryHookResult = ReturnType<
  typeof useListPlaidTransactionsQuery
>;
export type ListPlaidTransactionsLazyQueryHookResult = ReturnType<
  typeof useListPlaidTransactionsLazyQuery
>;
export type ListPlaidTransactionsQueryResult = ApolloReactCommon.QueryResult<
  IListPlaidTransactionsQuery,
  IListPlaidTransactionsQueryVariables
>;
export const ListProviderPlaidTransactionsDocument = gql`
  query ListProviderPlaidTransactions(
    $onlyMostRecent: Boolean
    $withoutBills: Boolean
    $plaidItemId: ID
  ) {
    transactions: listProviderPlaidTransactions(
      onlyMostRecent: $onlyMostRecent
      withoutBills: $withoutBills
      plaidItemId: $plaidItemId
    ) {
      ...PlaidTransactionFragment
    }
  }
  ${PlaidTransactionFragmentDoc}
`;

/**
 * __useListProviderPlaidTransactionsQuery__
 *
 * To run a query within a React component, call `useListProviderPlaidTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProviderPlaidTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProviderPlaidTransactionsQuery({
 *   variables: {
 *      onlyMostRecent: // value for 'onlyMostRecent'
 *      withoutBills: // value for 'withoutBills'
 *      plaidItemId: // value for 'plaidItemId'
 *   },
 * });
 */
export function useListProviderPlaidTransactionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListProviderPlaidTransactionsQuery,
    IListProviderPlaidTransactionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListProviderPlaidTransactionsQuery,
    IListProviderPlaidTransactionsQueryVariables
  >(ListProviderPlaidTransactionsDocument, baseOptions);
}
export function useListProviderPlaidTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListProviderPlaidTransactionsQuery,
    IListProviderPlaidTransactionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListProviderPlaidTransactionsQuery,
    IListProviderPlaidTransactionsQueryVariables
  >(ListProviderPlaidTransactionsDocument, baseOptions);
}
export type ListProviderPlaidTransactionsQueryHookResult = ReturnType<
  typeof useListProviderPlaidTransactionsQuery
>;
export type ListProviderPlaidTransactionsLazyQueryHookResult = ReturnType<
  typeof useListProviderPlaidTransactionsLazyQuery
>;
export type ListProviderPlaidTransactionsQueryResult =
  ApolloReactCommon.QueryResult<
    IListProviderPlaidTransactionsQuery,
    IListProviderPlaidTransactionsQueryVariables
  >;
export const ListProvidersDocument = gql`
  query ListProviders(
    $excludeInsurance: Boolean
    $excludeElectricity: Boolean
  ) {
    providers: listProviders(
      excludeInsurance: $excludeInsurance
      excludeElectricity: $excludeElectricity
    ) {
      id
      name
      services
      billFields
      scraperEnabled
      totalRequiredProviderBillFields
    }
  }
`;

/**
 * __useListProvidersQuery__
 *
 * To run a query within a React component, call `useListProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProvidersQuery({
 *   variables: {
 *      excludeInsurance: // value for 'excludeInsurance'
 *      excludeElectricity: // value for 'excludeElectricity'
 *   },
 * });
 */
export function useListProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >(ListProvidersDocument, baseOptions);
}
export function useListProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListProvidersQuery,
    IListProvidersQueryVariables
  >(ListProvidersDocument, baseOptions);
}
export type ListProvidersQueryHookResult = ReturnType<
  typeof useListProvidersQuery
>;
export type ListProvidersLazyQueryHookResult = ReturnType<
  typeof useListProvidersLazyQuery
>;
export type ListProvidersQueryResult = ApolloReactCommon.QueryResult<
  IListProvidersQuery,
  IListProvidersQueryVariables
>;
export const s3SignedPostDocument = gql`
  query s3SignedPost($filename: String!, $acl: String!) {
    s3SignedPost(filename: $filename, acl: $acl) {
      acl
      key
      policy
      successActionStatus
      xAmzAlgorithm
      xAmzCredential
      xAmzDate
      xAmzSignature
    }
  }
`;

/**
 * __uses3SignedPostQuery__
 *
 * To run a query within a React component, call `uses3SignedPostQuery` and pass it any options that fit your needs.
 * When your component renders, `uses3SignedPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = uses3SignedPostQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *      acl: // value for 'acl'
 *   },
 * });
 */
export function uses3SignedPostQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Is3SignedPostQuery,
    Is3SignedPostQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    Is3SignedPostQuery,
    Is3SignedPostQueryVariables
  >(s3SignedPostDocument, baseOptions);
}
export function uses3SignedPostLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Is3SignedPostQuery,
    Is3SignedPostQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    Is3SignedPostQuery,
    Is3SignedPostQueryVariables
  >(s3SignedPostDocument, baseOptions);
}
export type s3SignedPostQueryHookResult = ReturnType<
  typeof uses3SignedPostQuery
>;
export type s3SignedPostLazyQueryHookResult = ReturnType<
  typeof uses3SignedPostLazyQuery
>;
export type s3SignedPostQueryResult = ApolloReactCommon.QueryResult<
  Is3SignedPostQuery,
  Is3SignedPostQueryVariables
>;
export const SessionDataDocument = gql`
  query SessionData {
    currentCustomer: findCurrentCustomer {
      ...CustomerFragment
    }
    providers: listProviders {
      id
      name
      services
      billFields
      scraperEnabled
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useSessionDataQuery__
 *
 * To run a query within a React component, call `useSessionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ISessionDataQuery,
    ISessionDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ISessionDataQuery,
    ISessionDataQueryVariables
  >(SessionDataDocument, baseOptions);
}
export function useSessionDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ISessionDataQuery,
    ISessionDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ISessionDataQuery,
    ISessionDataQueryVariables
  >(SessionDataDocument, baseOptions);
}
export type SessionDataQueryHookResult = ReturnType<typeof useSessionDataQuery>;
export type SessionDataLazyQueryHookResult = ReturnType<
  typeof useSessionDataLazyQuery
>;
export type SessionDataQueryResult = ApolloReactCommon.QueryResult<
  ISessionDataQuery,
  ISessionDataQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {}
};
export default result;

import React, { CSSProperties } from "react";
import Color from "src/definitions/enums/Color";

interface IProps {
  children: any;
  className?: string;
  style?: CSSProperties;
  padded?: boolean;
  // elevation?: ElevationLevel;
  /**
   * If true, the Segment will use the contrast background and have no elevation
   */
  contrast?: boolean;
  /**
   * If true, the Segment will show a border
   */
  bordered?: boolean;
  onClick?: () => void;
}

export default function SegmentButton(props: IProps) {
  const {
    children,
    className,
    style,
    contrast,
    bordered = true,
    onClick,
    // elevation = "Primary",
    padded = true,
  } = props;

  // const dpLevel = contrast || bordered ? "dp0" : `dp${elevation}`;

  return (
    <div
      onClick={onClick}
      className={`Segment ${className} ${padded ? "p-1" : ""} pointer`}
      style={{
        // boxShadow: (BoxShadow as any)[dpLevel as any],
        border: bordered ? "1px solid var(--border-grey)" : "",
        backgroundColor: contrast
          ? (Color.white700 as any)
          : (Color.trueWhite as any),
        borderRadius: "var(--border-radius",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

import { XIcon } from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { default as Select } from "react-select";
import { useCreateBillMutation } from "./graphql";
import NewButton from "./NewButton";
import Segment from "./Segment";
import SegmentButton from "./SegmentButton";
import { AppContext } from "./SessionData";

type TProviderOption = {
  value: string;
  label: string;
  services?: string[];
};

interface IProps {
  onSuccess?: () => void;
  onClose?: () => void;
  canClose?: boolean;
  hideHeader?: boolean;
  htmlId?: string;
  className?: string;
}

export default function BlankBillForm({
  onSuccess,
  onClose,
  canClose = true,
  hideHeader = false,
  htmlId,
  className
}: IProps) {
  const { currentCustomer } = useContext(AppContext);
  const [provider, setProvider] = useState<TProviderOption>();
  const { search } = useLocation();

  const { providers } = useContext(AppContext);

  const [createBillMutation] = useCreateBillMutation({
    refetchQueries: ["FindCurrentCustomer"]
  });

  const billVia = (): string => {
    if (localStorage.getItem("billCreatedVia") === "email") {
      localStorage.removeItem("billCreatedVia");
      return "email";
    } else if (search.includes("addBill=true")) {
      return "menu";
    } else if (currentCustomer.hasBills) {
      return "sidecard";
    } else if (!currentCustomer.hasBills) {
      return "empty_state_dashboard";
    } else {
      return "unknown";
    }
  };

  const createBill = async () => {
    const result = await createBillMutation({
      variables: {
        bill: {
          providerId: provider?.value,
          autoRenegotiate: true,
          createdVia: billVia()
        },
        checkForDuplicates: true
      },
      refetchQueries: [
        "ListBills",
        "FindCurrentCustomer",
        "ListProviderPlaidTransactions"
      ]
    });

    if (result.data?.payload?.success) {
      if (result.data.payload.duplicateFound) {
        // setDuplciateFound(true);
        // setDuplicateBillId(result.data.payload.duplicateBillId);
        return null;
      }

      if (onSuccess) {
        onSuccess();
      }
    }
  };

  return (
    <React.Fragment>
      <Segment className={`mb-6 ${className}`} htmlId={htmlId}>
        {!hideHeader && (
          <div className="flex items-center justify-between">
            {/* <FabButton
            iconName="plus"
            size={50}
            color={Color.primaryGreen as any}
          /> */}
            <div className="">
              <div className="text-lg font-semibold text-gray-bf-text">
                Lower a New Bill
              </div>
              <div className="text-sm text-gray-bf-text-light">
                Select a provider for us to get to work!
              </div>
            </div>
            {onClose && canClose && (
              <NewButton
                version="text"
                className="text-gray-500"
                onClick={onClose}
                size="xs"
              >
                <XIcon className="text-gray-400" />
              </NewButton>
            )}
          </div>
        )}
        <Select
          className="mt-8"
          name="provider"
          placeholder="Choose your provider"
          value={provider}
          onChange={(val: any) => setProvider(val)}
          options={providers.map((p) => ({
            value: p.id,
            label: p.name
          }))}
        />
        <div className="my-8 text-xs text-center uppercase text-gray-bf-text-light font-semibold tracking-widest">
          Or choose a common provider
        </div>
        <div className="grid grid-cols-2 grid-rows-3 md:grid-cols-3 md:grid-rows-2 gap-4">
          <SegmentButton
            onClick={() =>
              setProvider({ label: "Comcast Xfinity", value: "35" })
            }
            className="cursor-pointer mb-4 flex justify-center items-center"
            style={getSegmentStyle(provider && provider.value === "35")}
          >
            <div>
              <img
                src="https://cdn-billfixers.s3.us-east-1.amazonaws.com/images/customers/provider-logos/comcast-xfinity.png"
                className="w-3/5 mx-auto"
                alt="Comcast"
              />
            </div>
          </SegmentButton>
          <SegmentButton
            onClick={() => setProvider({ label: "DirecTV", value: "47" })}
            className="cursor-pointer mb-4 flex justify-center items-center"
            style={getSegmentStyle(provider && provider.value === "47")}
          >
            <img
              src="https://cdn-billfixers.s3.us-east-1.amazonaws.com/images/customers/provider-logos/directTV.png"
              className="w-3/5"
              alt="DirecTV"
            />
          </SegmentButton>
          <SegmentButton
            onClick={() => setProvider({ label: "Spectrum", value: "121" })}
            className="cursor-pointer mb-4 flex justify-center items-center"
            style={getSegmentStyle(provider && provider.value === "121")}
          >
            <img
              src="https://cdn-billfixers.s3.us-east-1.amazonaws.com/images/customers/provider-logos/spectrum.png"
              className="w-3/5"
              alt="Spectrum"
            />
          </SegmentButton>
          <SegmentButton
            onClick={() => setProvider({ label: "Verizon", value: "149" })}
            className="cursor-pointer mb-4 flex justify-center items-center"
            style={getSegmentStyle(provider && provider.value === "149")}
          >
            <img
              src="https://cdn-billfixers.s3.us-east-1.amazonaws.com/images/customers/provider-logos/verizon.png"
              className="w-3/5"
              alt="Verizon"
            />
          </SegmentButton>
          <SegmentButton
            onClick={() => setProvider({ label: "Optimum", value: "98" })}
            className="cursor-pointer mb-4 flex justify-center items-center"
            style={getSegmentStyle(provider && provider.value === "98")}
          >
            <img
              src="https://cdn-billfixers.s3.us-east-1.amazonaws.com/images/customers/provider-logos/optimum.png"
              className="w-3/5"
              alt="Optimum"
            />
          </SegmentButton>
          <SegmentButton
            onClick={() => setProvider({ label: "AT&T", value: "10" })}
            className="cursor-pointer mb-4 flex justify-center items-center"
            style={getSegmentStyle(provider && provider.value === "10")}
          >
            <img
              src="https://cdn-billfixers.s3.us-east-1.amazonaws.com/images/customers/provider-logos/att.png"
              alt="AT&T"
              className="w-3/5"
            />
          </SegmentButton>
        </div>
        <div className="flex justify-center mt-4">
          <NewButton
            className="font-semibold px-16 py-4"
            fullWidth
            disabled={!provider}
            onClick={createBill}
          >
            Continue
          </NewButton>
        </div>
      </Segment>
    </React.Fragment>
  );
}

function getSegmentStyle(active: boolean | undefined) {
  return {
    height: "100px",
    border: active
      ? "1px solid var(--bf-green)"
      : "1px solid var(--border-grey)"
  };
}

import { ExclamationIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import apollo from "src/apollo";
import Color from "src/definitions/enums/Color";
// import S3SignedPost from "src/graphql/queries/S3SignedPost.graphql";
import IChildren from "src/definitions/types/TChildren";
import { s3SignedPostDocument } from "./graphql";
import Loader from "./Loader";
// import Loader from "../Loader";

interface IProps {
  onUpload: (url: string) => void;
  children: IChildren;
  multiple?: boolean;
}

export default function DocumentUploader({
  onUpload,
  children,
  multiple = true
}: IProps) {
  const [uploading, setUploading] = useState(false);

  // const dispatch = useDispatch();

  const filesDropped = async (files: any) => {
    const promises = files.map(async (file: any) => {
      const { data } = await apollo.query({
        query: s3SignedPostDocument,
        variables: {
          filename: file.name,
          acl: "public-read"
        },
        fetchPolicy: "network-only"
      });

      if (data) {
        const creds = data.s3SignedPost;

        upload(file, creds);
      }
    });

    const handleRejection = (promise: Promise<null>) => {
      promise.catch((err) => {
        console.log({ err });

        // dispatch(
        //   addSnack({
        //     kind: "negative",
        //     text: "Error uploading file. Please try again.",
        //   })
        // );
      });
    };

    return await Promise.all(promises.map(handleRejection));
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple,
    onDrop: filesDropped,
    accept: ".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.heic,.HEIC"
  });

  const upload = (file: any, signedRequest: any) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("key", signedRequest.key);
    formData.append("success_action_status", signedRequest.successActionStatus);
    formData.append("acl", signedRequest.acl);
    formData.append("policy", signedRequest.policy);
    formData.append("x-amz-credential", signedRequest.xAmzCredential);
    formData.append("x-amz-algorithm", signedRequest.xAmzAlgorithm);
    formData.append("x-amz-date", signedRequest.xAmzDate);
    formData.append("x-amz-signature", signedRequest.xAmzSignature);
    formData.append("file", file);

    const options = {
      method: "POST",
      body: formData
    };

    fetch(`https://billfixers-tmp.s3.amazonaws.com`, options).then(
      (response) => {
        if (response.ok) {
          fileUploaded({
            tempUrl: `https://billfixers-tmp.s3.amazonaws.com/${signedRequest.key}`,
            name: file.name,
            file
          });
        } else {
          setUploading(false);
        }
      }
    );
  };

  const fileUploaded = async ({ tempUrl }: any) => {
    onUpload(tempUrl);
    setUploading(false);
    return null;
  };

  return (
    <React.Fragment>
      <div {...getRootProps()}>
        {fileRejections.length > 0 && (
          <div className="p-2 rounded-md bg-red-50 text-red-800 mb-2 flex items-center space-x-2">
            <ExclamationIcon className="h-5 w-5 text-red-700 animate-pulse" />
            <span>Please upload an image file or PDF</span>
          </div>
        )}
        <input {...getInputProps()} />
        {uploading ? (
          <Loader color={Color.green500} text="Uploading" />
        ) : (
          children
        )}
      </div>
    </React.Fragment>
  );
}

import React from "react";

interface IErrorsProps {
  errors: string[];
  className?: string;
}

export default function Errors({ errors, className }: IErrorsProps) {
  const errorsList = errors.map((error: string, index: number) => (
    <li key={index} className="my-05 text-red-900">
      {error}
    </li>
  ));
  return (
    <div className="bg-red-200 rounded py-4">
      <ul className="list-none p-0 m-0">{errorsList}</ul>
    </div>
  );
}

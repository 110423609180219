import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Store } from "redux";
import Routes from "src/Routes";
import apolloClient from "./apollo";

ReactGA.initialize("UA-52210428-1");
interface IProps {
  store: Store;
}

function App({ store }: IProps) {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </ApolloProvider>
  );
}

export default App;

import React, { useCallback, useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";
import Color from "src/definitions/enums/Color";

interface IProps {
  text?: string;
  color?: string | Color;
  phrases?: string[];
  duration?: number;
  fullHeight?: boolean;
  className?: string;
}

export default function Loader({
  text,
  color = Color.green500,
  phrases = [],
  duration = 5000,
  fullHeight = true,
  className,
}: IProps) {
  const [activePhrase, setActivePhrase] = useState(phrases[0]);

  const randomizePhrase = useCallback(() => {
    const random = Math.floor(Math.random() * phrases.length);
    return setActivePhrase(phrases[random]);
  }, [phrases]);

  useEffect(() => {
    const id = setInterval(randomizePhrase, duration);
    return () => {
      clearInterval(id);
    };
  }, [duration, randomizePhrase]);

  return (
    <div
      className={`flex justify-center items-center ${
        fullHeight ? "h-full" : ""
      } ${className}`}
    >
      <div className="flex flex-col justify-center items-center text-center">
        <BounceLoader color={color as any} size={60} />
        {text && <h4 className="mt-2">{text}</h4>}
        {phrases && <h4 className="mt-2">{activePhrase}</h4>}
      </div>
    </div>
  );
}

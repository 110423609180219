import { CheckIcon } from "@heroicons/react/outline";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import Toggle from "../Forms/Toggle";
import { useUpdateBillMutation } from "../graphql";
import NewButton from "../NewButton";
import { TCompactBillFormStep } from "./CompactBillForm";

export default function BillTogglesStep({
  bill,
  onComplete,
  onMakeChanges,
  completed
}: TCompactBillFormStep) {
  const [shakeFixMyBill, setShakeFixMyBill] = useState(false);

  useEffect(() => {
    const shakeTimeout = setTimeout(() => {
      setShakeFixMyBill(true);
    }, 5_000);
    return () => {
      clearTimeout(shakeTimeout);
      setShakeFixMyBill(false);
    };
  }, []);

  const [updateBillMutation] = useUpdateBillMutation();

  const updateBill = async (values: any) => {
    const result = await updateBillMutation({
      variables: {
        id: bill.id,
        bill: {
          allowsContract: values.allowsContract,
          autoRenegotiate: values.autoRenegotiate,
          readyToNegotiate: true
        }
      },
      refetchQueries: ["FindCurrentCustomer"]
    });

    if (result.data?.payload?.success) {
      onComplete();
    }
  };

  return (
    <AnimatePresence exitBeforeEnter={false}>
      {completed ? (
        <motion.div
          className="flex items-center justify-between"
          initial={{ opacity: 0, height: 0, scaleY: 0.2 }}
          animate={{
            opacity: 1,
            height: "auto",
            scaleY: 1
          }}
          exit={{ opacity: 0, height: 0, scaleY: 0.2 }}
          transition={{ duration: 0.3 }}
          key="preferencesFormComplete"
        >
          <div className="flex justify-center items-center mr-4">
            <div
              className="flex justify-center items-center rounded-full bg-bf-green-500 mr-4"
              style={{ padding: "1px" }}
            >
              <CheckIcon className="w-4 h-4 text-white" />
            </div>
            <div className="text-sm text-gray-pogo-gray">
              Negotiation preferences recorded
            </div>
          </div>
          <div
            className="text-xs uppercase tracking-wider font-semibold text-gray-400 hover:text-gray-pogo-gray cursor-pointer"
            onClick={onMakeChanges}
          >
            Change
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, height: 0, scaleY: 0.2 }}
          animate={{
            opacity: 1,
            height: "auto",
            scaleY: 1
          }}
          exit={{ opacity: 0, height: 0, scaleY: 0.2 }}
          transition={{ duration: 0.3 }}
          key="preferencesForm"
        >
          <Form
            initialValues={bill}
            onSubmit={updateBill}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Field
                    name="autoRenegotiate"
                    render={(fieldProps: any) => (
                      <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start mb-12 mt-4 sm:mt-0 sm:mb-2">
                        <Toggle
                          input={{
                            ...fieldProps.input
                          }}
                          meta={{}}
                          label="AutoFix"
                          labelPosition="right"
                          className="sm:mr-6 flex-1"
                        />
                        <div className="text-sm text-gray-bf-text-light text-center sm:text-right mt-2 sm:mt-0 w-2/3 sm:w-full">
                          BillFixers will track for discounts and renegotiate
                          for you when available.
                        </div>
                      </div>
                    )}
                  />
                  <Field
                    name="allowsContract"
                    render={(fieldProps: any) => (
                      <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start mb-8 sm:mb-2">
                        <Toggle
                          input={{
                            ...fieldProps.input
                          }}
                          meta={{}}
                          label="Contracts"
                          labelPosition="right"
                          className="sm:mr-6 flex-1"
                        />
                        <div className="text-sm text-gray-bf-text-light text-center sm:text-right mt-2 sm:mt-0 w-2/3 sm:w-full">
                          BillFixers will lock in savings with contracts up to
                          24 months.
                        </div>
                      </div>
                    )}
                  />
                  <NewButton
                    className={`w-full mt-4 font-semibold text-sm tracking-widest py-4 sm:py-2 animate__animated`}
                    style={
                      shakeFixMyBill
                        ? {
                            animation: "headShake",
                            animationDuration: "2s",
                            animationIterationCount: "infinite"
                          }
                        : {}
                    }
                    type="submit"
                    fullWidth
                  >
                    Finish
                  </NewButton>
                </form>
              );
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

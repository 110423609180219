import React, { ReactNode, ReactNodeArray, useEffect, useState } from "react";
import Loader from "src/components/Loader";
import { haltAndCatchFire } from "src/utils/haltAndCatchFire";
import {
  ICustomerFragment,
  IProviderFragment,
  useFindCurrentCustomerQuery,
  useListProvidersQuery
} from "./graphql";
import NewButton from "./NewButton";

interface IAppContext {
  currentCustomer: ICustomerFragment;
  providers: IProviderFragment[];
}

export const AppContext = React.createContext({} as IAppContext);

interface IProps {
  children: ReactNode | ReactNodeArray;
}

export default function SessionData({ children }: IProps) {
  // Sometimes it seems like customers get stuck loading, so this is an attempt to give them a way out
  const [allowReset, setAllowReset] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAllowReset(true);
    }, 10_000);
    return () => {
      setAllowReset(false);
    };
  }, []);

  const { loading: providersLoading, data: providersData } =
    useListProvidersQuery({
      variables: {
        excludeInsurance: true,
        excludeElectricity: true
      }
    });
  const { loading: customerLoading, data: customerData } =
    useFindCurrentCustomerQuery();

  const resetButton = (errorMessage?: string) => (
    <div className="flex flex-col items-center mt-4">
      <div className="text-center text-gray-pogo-gray">
        Hmm, this is taking a while. Let's try again.
      </div>
      <NewButton
        size="sm"
        className="mt-2"
        onClick={() => haltAndCatchFire(errorMessage)}
      >
        Restart
      </NewButton>
    </div>
  );

  if (providersLoading && !providersData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>
          <Loader />
          {allowReset && resetButton()}
        </div>
      </div>
    );
  }

  if (!providersData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>
          <Loader />
          {allowReset && resetButton()}
        </div>
      </div>
    );
  }

  if (customerLoading && !customerData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>
          <Loader />
          {allowReset && resetButton()}
        </div>
      </div>
    );
  }

  if (!customerData?.customer) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>
          <Loader />
          {allowReset && resetButton()}
        </div>
      </div>
    );
  }

  const currentCustomer = customerData.customer;
  const providers = providersData?.providers;

  return (
    <AppContext.Provider
      value={{
        currentCustomer,
        providers
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

import React from "react";
import {
  composeValidators,
  explicitLength,
  required
} from "src/components/Forms/InputValidations";
import CheckboxField from "./Forms/CheckboxField";
import TextInputField from "./Forms/TextInputField";
import { IProviderBillFieldFragment } from "./graphql";

interface IProps {
  providerBillField: IProviderBillFieldFragment;
}

export default function ProviderBillFieldRenderer({
  providerBillField
}: IProps) {
  // Put pbf_ in front of the field name so that we can properly determine which fields are
  // provider specific fields when submitting a create or update bill
  const name = `pbf_${providerBillField.name}`;

  const { requiredResponseLength } = providerBillField;

  const validation = () => {
    // Field is required and has a length requirement
    if (providerBillField.required && requiredResponseLength) {
      return composeValidators(
        required,
        explicitLength(requiredResponseLength)
      );
    } else if (providerBillField.required) {
      return composeValidators(required);
    } else if (requiredResponseLength) {
      return composeValidators(explicitLength(requiredResponseLength));
    }
  };

  // const formatWithPattern = (value: string) => {
  //   if (!providerBillField.formatHashString) return value;
  //   const format = providerBillField.formatHashString;
  //   let hashCount = 0;
  //   const formattedNumberAry = format.split("");
  //   console.log({ formattedNumberAry });

  //   for (let i = 0, ln = format.length; i < ln; i++) {
  //     if (format[i] === "#") {
  //       console.log({ valueHashCount: value[hashCount] });

  //       formattedNumberAry[i] = value[hashCount];
  //       hashCount += 1;
  //     }
  //   }
  //   // console.log({ formattedNumberAry });

  //   return formattedNumberAry.join("");
  // };

  const renderedField = () => {
    switch (providerBillField.dataType) {
      case "string":
        return (
          <TextInputField
            label={providerBillField.label}
            htmlId={name}
            name={name}
            placeholder={providerBillField.placeholder}
            validate={validation()}
            required={providerBillField.required}
            helpText={providerBillField.helpText}
          />
        );
      case "number":
        return (
          <TextInputField
            label={providerBillField.label}
            name={name}
            placeholder={providerBillField.placeholder}
            validate={validation()}
            required={providerBillField.required}
            type="number"
            helpText={providerBillField.helpText}
          />
        );
      case "boolean":
        return <CheckboxField label={providerBillField.label} name={name} />;

      default:
        return (
          <TextInputField
            label={providerBillField.label}
            name={name}
            placeholder={providerBillField.placeholder}
            validate={providerBillField.required && required}
            helpText={providerBillField.helpText}
          />
        );
    }
  };

  return renderedField();
}

// if (providerBillField.formatHashString) {
//   return (
//     <Field
//       name={name}
//       type="text"
//       validate={validation()}
//       render={({ input, meta }) => {
//         const touchedInvalid = (meta.touched && meta.invalid) || false;
//         return (
//           <div className="TextInputField">
//             <div className="flex items-center">
//               <label>{providerBillField.label}</label>
//               {providerBillField.required && (
//                 <div className="text-red-600 text-xl leading-none ml-1">
//                   *
//                 </div>
//               )}
//             </div>
//             <NumberFormat
//               isAllowed={validation()}
//               value={input.value}
//               placeholder={providerBillField.placeholder || ""}
//               onValueChange={(e) => {
//                 input.onChange(e.formattedValue);
//               }}
//               autoComplete="off"
//               format={providerBillField.formatHashString || undefined}
//               style={
//                 touchedInvalid
//                   ? {
//                       background: "hsla(7, 86%, 92%, 1)",
//                       color: "hsla(7, 95%, 33%, 1)"
//                     }
//                   : {}
//               }
//             />
//             {providerBillField.helpText && (
//               <div className="mt-1 text-sm text-gray-pogo-gray">
//                 {providerBillField.helpText}
//               </div>
//             )}
//           </div>
//         );
//       }}
//       placeholder={providerBillField.placeholder}
//       autoComplete="off"
//     />
//   );
// }
// return (
//   <div className={`TextInputField`}>
//     <div className="flex items-center">
//       <label>{providerBillField.label}</label>
//       {providerBillField.required && (
//         <div className="text-red-600 text-xl leading-none ml-1">*</div>
//       )}
//     </div>
//     <Field
//       name={name}
//       type={"text"}
//       autoComplete="off"
//       validate={validation()}
//       placeholder={providerBillField.placeholder}
//       render={({ input, meta }) => {
//         const touchedInvalid = (meta.touched && meta.invalid) || false;

//         return (
//           <div>
//             <input
//               className={`mb-0`}
//               value={input.value}
//               placeholder={providerBillField.placeholder || ""}
//               type={"text"}
//               onChange={(value) => input.onChange(value)}
//               onBlur={(event) => input.onBlur(event)}
//               style={
//                 touchedInvalid
//                   ? {
//                       background: "hsla(7, 86%, 92%, 1)",
//                       color: "hsla(7, 95%, 33%, 1)"
//                     }
//                   : {}
//               }
//             />
//             <span
//               className={touchedInvalid ? "bar bar-error" : "bar"}
//             />
//           </div>
//         );
//       }}
//     />
//     <span className="bar" />
//     {providerBillField.helpText && (
//       <div className="mt-1 text-sm text-gray-pogo-gray">
//         {providerBillField.helpText}
//       </div>
//     )}
//   </div>
// );

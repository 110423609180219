import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  from
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { host, protocol } from "src/config";

const fetcher = (...args: any) => {
  return (window as any).fetch(...args);
};

const httpLink = createHttpLink({
  uri: `${protocol}://${host}/customer/graphql`,
  fetch: fetcher,
  fetchOptions: {
    mode: "cors"
  }
});

const authLink = setContext((_: any, { headers }: any) => {
  // There was previously an Access-Control-Allow-Origin: *header present here,
  // but according to the SO answer, that's a bad idea: https://stackoverflow.com/questions/62569594/request-header-field-access-control-allow-origin-is-not-allowed-by-access-contr.
  return {
    headers: {
      ...headers,
      "X-Customer-Email": localStorage.getItem("email"),
      "X-Customer-Token": localStorage.getItem("token")
    }
  };
});

const afterwareLink = onError(({ networkError }: any) => {
  if (networkError && networkError.message === "Failed to fetch") {
    console.warn(networkError);
  }
});

const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  link: from([authLink, afterwareLink, link]),
  cache: new InMemoryCache()
});

export default client;

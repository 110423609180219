import React from "react";
import { FieldRenderProps } from "react-final-form";
import "./Toggle.css";

interface IProps {
  label?: string;
  labelPosition?: "top" | "bottom" | "left" | "right";
  className?: string;
  labelClassName?: string;
}

type TProps = IProps & FieldRenderProps<boolean | string, HTMLInputElement>;

export default function Toggle(props: TProps) {
  const {
    label,
    input,
    labelPosition = "top",
    className,
    labelClassName
  } = props;

  let checked = input.value === true || input.value === "true";

  const toggle = () => {
    input.onChange(!checked as any);
  };

  const keyDown = (evt: any) => {
    if (evt.keyCode && evt.keyCode === 32) {
      toggle();
    }
  };

  let containerClass: string;
  switch (labelPosition) {
    case "left":
      containerClass = "labelContainerLeft";
      break;
    case "right":
      containerClass = "labelContainerRight";
      break;
    case "top":
      containerClass = "labelContainerTop";
      break;
    case "bottom":
      containerClass = "labelContainerBottom";
      break;
    default:
      containerClass = "labelContainerLeft";
  }

  return (
    <div
      className={`${containerClass} ${className} pointer`}
      onClick={toggle}
      tabIndex={0}
      onKeyDown={keyDown}
    >
      {label && (
        <div className={`ml-2 text-gray-bf-text-light ${labelClassName}`}>
          {label}
        </div>
      )}
      <div>
        <div className={checked ? "containerActive" : "containerInactive"}>
          <div className={checked ? "toggleActive" : "toggleInactive"} />
        </div>
      </div>
    </div>
  );
}

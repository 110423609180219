import apollo from "src/apollo";
export const SESSION_LOG_IN = "SESSION_LOG_IN";
export const SESSION_LOG_OUT = "SESSION_LOG_OUT";

export function login(email: string, token: string) {
  localStorage.setItem("email", email);
  localStorage.setItem("token", token);

  return {
    type: SESSION_LOG_IN
  };
}

export function logout() {
  localStorage.removeItem("token");
  apollo.clearStore();
  // We have to do this because we have PWA features enabled with our serviceWorkerRegistration.ts
  // If we don't manually clear the caches, the cached version of the app will be served to the user
  // until they close all browser tabs running billfixers.
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  return {
    type: SESSION_LOG_OUT
  };
}

import { combineReducers } from "redux";
import session, { ISessionState } from "src/redux/reducers/session";

const rootReducer = combineReducers({
  session
});

export interface IReduxState {
  session: ISessionState;
}

export default rootReducer;

import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import Tippy from "@tippyjs/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Form } from "react-final-form";
import TextAreaField from "../Forms/TextAreaField";
import TextInputField from "../Forms/TextInputField";
import { useUpdateBillMutation } from "../graphql";
import NewButton from "../NewButton";
import { TCompactBillFormStep } from "./CompactBillForm";

export default function BillInfoStep({
  bill,
  completed,
  onComplete,
  onMakeChanges
}: TCompactBillFormStep) {
  const [updateBillMutation] = useUpdateBillMutation();

  const updateBill = async (values: any) => {
    const result = await updateBillMutation({
      variables: {
        id: bill.id,
        bill: {
          last4ssn: values.last4ssn,
          passcode: values.passcode,
          initialNotes: values.initialNotes
        }
      }
    });

    if (result.data?.payload?.success) {
      onComplete();
    }
  };

  return (
    <AnimatePresence exitBeforeEnter={false}>
      {completed ? (
        <motion.div
          className="flex items-center justify-between"
          initial={{ opacity: 0, height: 0, scaleY: 0.2 }}
          animate={{
            opacity: 1,
            height: "auto",
            scaleY: 1
          }}
          exit={{ opacity: 0, height: 0, scaleY: 0.2 }}
          transition={{ duration: 0.3 }}
          key="infoFormComplete"
        >
          <div className="flex justify-center items-center mr-4">
            <div
              className="flex justify-center items-center rounded-full bg-bf-green-500 mr-4"
              style={{ padding: "1px" }}
            >
              <CheckIcon className="w-4 h-4 text-white" />
            </div>
            <div className="text-sm text-gray-pogo-gray">Information saved</div>
          </div>
          <div
            className="text-xs uppercase tracking-wider font-semibold text-gray-400 hover:text-gray-pogo-gray cursor-pointer"
            onClick={onMakeChanges}
          >
            Change
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, height: 0, scaleY: 0.2 }}
          animate={{
            opacity: 1,
            height: "auto",
            scaleY: 1
          }}
          exit={{ opacity: 0, height: 0, scaleY: 0.2 }}
          transition={{ duration: 0.3 }}
          key="infoForm"
        >
          <Form
            initialValues={bill}
            onSubmit={updateBill}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="flex flex-col">
                    <div className="flex flex-col md:flex-row md:items-center">
                      {!bill.documentless && (
                        <React.Fragment>
                          {bill.provider.billFields.includes("last4ssn") && (
                            <TextInputField
                              label="Social Security"
                              name="last4ssn"
                              placeholder="Last 4 of SSN"
                              className="md:mr-4"
                            />
                          )}
                          {bill.provider.billFields.includes("passcode") && (
                            <div className="md:mr-2">
                              <TextInputField
                                label="Account Passcode"
                                name="passcode"
                                placeholder="Account PIN/Passcode"
                              />
                            </div>
                          )}
                          {bill.provider.billFields.some((v) =>
                            ["last4ssn", "passcode"].includes(v)
                          ) && (
                            <div className="hidden md:block">
                              <Tippy
                                content={
                                  <div>
                                    We ask for this because it is what your
                                    provider uses to authenticate access to your
                                    account. You can click the question mark to
                                    learn more.
                                  </div>
                                }
                              >
                                <a
                                  href="https://help.billfixers.com/knowledge-base/why-do-you-need-my-pin-or-passcode-to-negotiate/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <QuestionMarkCircleIcon className="w-6 h-6 text-gray-300 md:ml-2 mt-4" />
                                </a>
                              </Tippy>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                    <TextAreaField
                      label="Things we should know  (optional)"
                      name="initialNotes"
                      placeholder="'I recently tried to negotiate this bill myself.' or 'I've been a customer for 6 years.'"
                    />
                  </div>
                  <NewButton
                    className="w-full mt-4 font-semibold text-sm tracking-widest"
                    type="submit"
                    fullWidth
                  >
                    Continue
                  </NewButton>
                </form>
              );
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

import React from "react";
import { Field, Form } from "react-final-form";
import NewButton from "src/components/NewButton";
import NewContainer from "src/components/NewContainer";
import Segment from "src/components/Segment";
import { host, protocol } from "src/config";
import useCurrentCustomer from "src/hooks/useCurrentCustomer";

export default function ContinueAtBillfixers() {
  // const [formSubmitted, setFormSubmitted] = useState(false);
  const { currentCustomer } = useCurrentCustomer();

  const onSubmit = async (values: any) => {
    const url = `${protocol}://${host}/api/customers/reset_password_without_sending_email`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(values)
    });

    const result = await response.json();

    if (result.customer) {
      // setFormSubmitted(true);
      const baseUrl = `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_CUSTOMER_APP_URL}`;
      window.location.href = `${baseUrl}/new-password?reset_password_token=${result.customer.token}`;
    } else if (result.errors) {
      console.log(result.errors);
      // setFormSubmitted(false);
    }
  };

  return (
    <NewContainer className="w-full md:w-5/6">
      <Segment padding={1}>
        <div className="">
          <h3 className="text-gray-400 font-semibold text-base uppercase tracking-wider">
            Get ready to save!
          </h3>
          <p className="text-gray-pogo-gray my-4">
            Now that you've added your first bill with BillFixers, head to their
            app to create your account password and finish account setup.
          </p>
          <Form
            initialValues={{ email: currentCustomer?.email }}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Field
                    type="hidden"
                    name="email"
                    value={currentCustomer?.email}
                    component="input"
                  />
                  <NewButton fullWidth className="py-4 mt-6" type="submit">
                    Set Your Billfixers Password
                  </NewButton>
                </form>
              );
            }}
          />
          {/* <a
            href="https://customers.billfixers.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewButton onClick={() => null} size="sm">
              Finish BillFixers Setup
            </NewButton>
          </a> */}
        </div>
      </Segment>
    </NewContainer>
  );
}

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { Fragment, useState } from "react";
import { Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import Errors from "src/components/Errors";
import {
  useCreateCreditCardMutation,
  useCreatePaymentMutation
} from "src/components/graphql";
import NewButton from "src/components/NewButton";
import Url from "src/utils/Url";

interface IPaymentMethodFormProps {
  redirectTo?: string;
  makePayment: boolean;
  paymentPlan?: string;
  paymentPlanChosen?: boolean;
  afterComplete?: () => void;
}

export default function PaymentMethodForm({
  redirectTo = Url.paymentsPath(),
  makePayment,
  paymentPlan = "monthly",
  paymentPlanChosen,
  afterComplete
}: IPaymentMethodFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [prePaidCardDetected, setPrePaidCardDetected] = useState(false);

  const [submitEnabled, setSubmitEnabled] = useState(false);

  const history = useHistory();

  const [createCreditCardMutation] = useCreateCreditCardMutation();
  const [createPaymentMutation] = useCreatePaymentMutation();

  const createStripePaymentMethod = async (values: any) => {
    setFormErrors([]);

    const cardElement = elements!.getElement(CardElement)!;

    const paymentMethodResult = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (paymentMethodResult.paymentMethod) {
      setPrePaidCardDetected(false);
      // If it's a prepaid card, set the prepaid error state and return early. No more prepaid card ripoffs
      if (paymentMethodResult.paymentMethod.card?.funding === "prepaid") {
        setPrePaidCardDetected(true);
        return;
      }

      const paymentMethod = paymentMethodResult.paymentMethod.id;
      const {
        brand,
        last4,
        exp_month,
        exp_year
      } = paymentMethodResult.paymentMethod.card!;

      const result = await createCreditCardMutation({
        variables: {
          params: {
            stripePaymentMethodId: paymentMethod,
            brand,
            last4,
            expMonth: exp_month,
            expYear: exp_year
          },
          paymentPlan: "monthly",
          paymentPlanChosen: true
        },
        refetchQueries: ["FindCurrentCustomer", "ListPaymentMethods"],
        // This no-cache is necessary to prevent a situation where adding a card fails but Apollo
        // is doing some kind of optimistic caching thing and it makes everything appear as if
        // adding the card succeeded when it didn't. Line 26 of Payments/index.tsx is evaluating true
        // because of this caching optimism and it's making the page rerender the normal payments
        // screen even though there is no new credit card and it doesn't exist in the database.
        fetchPolicy: "no-cache"
      });

      if (!result || !result.data || !result.data.createCreditCard) {
        return;
      }

      const { success, errors, creditCard } = result.data.createCreditCard;

      if (!success) {
        setFormErrors(errors);

        return;
      }

      let paymentSuccess = true;

      // If the credit card was added successfully and makePayment is true,
      // fire the createPaymentMutation
      if (success && makePayment) {
        const paymentResult = await createPaymentMutation({
          variables: {
            params: {
              stripePaymentMethodId: creditCard?.stripePaymentMethodId!,
              token: creditCard?.stripePaymentMethodId!,
              paymentMethod: "credit_card",
              details: `${creditCard?.brand} - ${creditCard?.last4}`
            }
          }
        });

        if (
          !paymentResult ||
          !paymentResult.data ||
          !paymentResult.data.createPayment
        ) {
          return;
        }

        const { errors: paymentErrors } = paymentResult.data?.createPayment;

        if (paymentResult.data?.createPayment?.success) {
          paymentSuccess = true;
        } else {
          paymentSuccess = false;
          setFormErrors(paymentErrors);
        }
      }

      if (success && paymentSuccess) {
        document
          .querySelector(".content")
          ?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        history.push("continue-at-billfixers");
        afterComplete && afterComplete();
      } else {
        setFormErrors(errors);
      }
    }
  };

  return (
    <Fragment>
      {formErrors.length > 0 && <Errors errors={formErrors} className="mb-4" />}
      {prePaidCardDetected && (
        <div className="bg-yellow-100 text-yellow-900 rounded-md p-2 sm:p-4 mb-4">
          This appears to be a prepaid card, which unfortunately BillFixers
          cannot accept as a payment method.
        </div>
      )}
      <Form
        onSubmit={createStripePaymentMethod}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div
              className="p-4 rounded-lg"
              style={{ backgroundColor: "hsla(204, 45%, 98%, 1)" }}
            >
              <CardElement
                onChange={(evt) => {
                  if (evt.complete) {
                    setSubmitEnabled(true);
                  }
                }}
                options={{
                  style: {
                    base: { fontSize: "16px" },
                    invalid: { color: "#f1513b" }
                  },
                  hidePostalCode: false
                }}
              />
            </div>
            <NewButton
              className="!p-4 mt-8 mb-4 text-sm"
              size="sm"
              fullWidth
              type="submit"
              disabled={submitting || !submitEnabled}
              loading={submitting}
            >
              Confirm Payment Method
            </NewButton>
          </form>
        )}
      />
    </Fragment>
  );
}

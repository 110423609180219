export const required = (value?: string | null) =>
  value && value.trim() ? undefined : "Required";

export const minLength = (min: number) => (
  value: string | number | object = ""
) =>
  typeof value === "string" && value.length < min
    ? `Should be at least ${min} characters long`
    : undefined;

export const maxLength = (min: number) => (
  value: string | number | object = ""
) =>
  typeof value === "string" && value.length < min
    ? `Should be at least ${min} characters long`
    : undefined;

export const explicitLength = (length: number) => (
  value: string | number | null
) => {
  // For use in conditional validation when a minimum number of fields in a form must be answered,
  // if value doesn't exist (i.e. it's null), don't validate and just return null
  if (value) {
    return typeof value === "string" && value.length !== length
      ? `Should be ${length} characters long`
      : undefined;
  } else {
    return null;
  }
};

export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

import React from "react";
import { Field } from "react-final-form";
import "./TextInputField.css";

interface IProps {
  name: string;
  label: string;
  className?: string;
  validate?: any;
  required?: boolean;
  autoFocus?: boolean;
  autoComplete?: boolean | string;
  compact?: boolean;
  type?: string;
  placeholder?: string | null;
  helpText?: string | null;
  htmlId?: string;
  format?: (value: any, name: string) => any;
  parse?: (value: any, name: string) => any;
}

export default function TextInputField(props: IProps) {
  const _parse = (value: any) => {
    if (value === undefined) {
      value = null;
    }

    return value;
  };

  const {
    label,
    name,
    className,
    validate,
    autoFocus,
    autoComplete = false,
    compact,
    type,
    placeholder,
    required,
    helpText,
    htmlId,
    format,
    parse
  } = props;

  const autoCompleteValue = () => {
    if (typeof autoComplete === "boolean") {
      return autoComplete ? "on" : "off";
    } else if (typeof autoComplete === "string") {
      return autoComplete;
    }
  };

  return (
    <div className={`TextInputField ${className} ${compact ? "my-0" : ""}`}>
      <div className="flex items-center">
        <label htmlFor={name}>{label}</label>
        {required && (
          <div className="text-red-600 text-xl leading-none ml-1">*</div>
        )}
      </div>
      <Field
        name={name}
        type={type || "text"}
        autoComplete={autoCompleteValue()}
        parse={parse || _parse}
        format={format}
        validate={validate}
        autoFocus={autoFocus}
        placeholder={placeholder}
        // component="input"
        render={({ input, meta }) => {
          const touchedInvalidDirty =
            (meta.touched && meta.invalid && meta.dirty) || false;

          return (
            <div>
              <input
                id={htmlId}
                className={`mb-0`}
                name={name}
                value={input.value}
                placeholder={placeholder || ""}
                autoFocus={autoFocus}
                autoComplete={autoCompleteValue()}
                type={type}
                onChange={(value) => input.onChange(value)}
                onBlur={(event) => input.onBlur(event)}
                style={
                  touchedInvalidDirty
                    ? {
                        background: "hsla(7, 86%, 92%, 1)",
                        color: "hsla(7, 95%, 33%, 1)"
                      }
                    : {}
                }
              />
              <span className={touchedInvalidDirty ? "bar bar-error" : "bar"} />
            </div>
          );
        }}
      />
      <span className="bar" />
      {helpText && (
        <div className="mt-1 text-sm text-gray-pogo-gray">{helpText}</div>
      )}
    </div>
  );
}

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import NewContainer from "src/components/NewContainer";
import PaymentMethodForm from "src/components/PaymentMethodForm";
import Segment from "src/components/Segment";

export default function Payment() {
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  return (
    <NewContainer className="w-full md:w-5/6">
      <Segment padding={1}>
        <h3 className="text-gray-400 font-semibold text-base uppercase tracking-wider">
          Add Payment
        </h3>
        <p className="text-gray-pogo-gray text-base my-4">
          BillFixers charges a fee of 50% of the bill savings (charged monthly).
          For example, if they lower your bill from $100/mo to $50/mo, you'll be
          charged $25/mo.
        </p>
        <p className="text-gray-pogo-gray text-base my-4">
          You will only be charged if the negotiation is successful. No savings,
          no fee!
        </p>
        <Elements stripe={stripePromise}>
          <PaymentMethodForm makePayment={false} afterComplete={() => null} />
        </Elements>
      </Segment>
    </NewContainer>
  );
}

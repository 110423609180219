import React, { ReactNode } from "react";
import useDetermineRoute from "src/hooks/useDetermineRoute";

interface IProps {
  children: ReactNode;
}

export default function Layout({ children }: IProps) {
  useDetermineRoute();

  return <React.Fragment>{children}</React.Fragment>;
}

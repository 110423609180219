import React from "react";
import ReactGA from "react-ga";
import { batch, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import AddBill from "src/stages/AddBill";
import Payment from "src/stages/Payment";
import SignUp from "src/stages/SignUp";
import Layout from "./components/Layout";
import NewButton from "./components/NewButton";
import SessionData, { AppContext } from "./components/SessionData";
import { logout } from "./redux/actions/session";
import { IReduxState } from "./redux/reducers";
import ContinueAtBillfixers from "./stages/ContinueAtBillfixers";
import ShowBills from "./stages/ShowBills";
import recordGAPageView from "./utils/GoogleAnalytics/recordGAPageView";

export default function Routes() {
  const session = useSelector((s: IReduxState) => s.session);
  const history = useHistory();
  const dispatch = useDispatch();

  history.listen((location) => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.pageview(location.pathname);
      // The method below is for GA 4, which isn't supported by the ReactGA library
      recordGAPageView();
    }
  });

  if (!session.loggedIn) {
    return (
      <Switch>
        <Route path={["/sign-up", "/signup", "/"]} render={() => <SignUp />} />
      </Switch>
    );
  }

  return (
    <SessionData>
      <AppContext.Consumer>
        {() => {
          return (
            <Layout>
              <Switch>
                <Route path={["/show-bills"]}>
                  <ShowBills />
                </Route>
                <Route path={["/payment"]}>
                  <Payment />
                </Route>
                <Route path={["/continue-at-billfixers"]}>
                  <ContinueAtBillfixers />
                </Route>
                <Route path={["/add-bill", "/"]}>
                  <AddBill />
                </Route>
              </Switch>
              {process.env.NODE_ENV === "development" && (
                <div className="mt-12 flex justify-center items-center">
                  <NewButton
                    size="xs"
                    onClick={() =>
                      batch(() => {
                        dispatch(logout());
                        history.push("/");
                      })
                    }
                  >
                    Log Out
                  </NewButton>
                </div>
              )}
            </Layout>
          );
        }}
      </AppContext.Consumer>
    </SessionData>
  );
}

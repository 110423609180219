import React, { ReactNode } from "react";
import AltLoader from "./AltLoader";

export type TButtonVersion =
  | "primary"
  | "secondary"
  | "plain"
  | "danger"
  | "success"
  | "warning"
  | "text";

interface IProps {
  children: ReactNode;
  version?: TButtonVersion;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
  inverse?: boolean;
}

type TButtonsProps = IProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function NewButton(props: TButtonsProps) {
  const {
    children,
    version = "primary",
    size = "md",
    fullWidth = false,
    className,
    loading,
    inverse = false,
    ...restProps
  } = props;

  let calculatedClassNames = "";

  switch (version) {
    case "primary":
      if (inverse) {
        calculatedClassNames = calculatedClassNames.concat(
          " bg-bf-green-100 text-bf-green-600 hover:bg-bf-green-200 hover:text-bf-green-700 focus:shadow-outline-bf-green active:bg-bf-green-400 "
        );
      } else {
        calculatedClassNames = calculatedClassNames.concat(
          " bg-bf-green-500 text-bf-green-100 hover:bg-bf-green-400 focus:shadow-outline-bf-green active:bg-bf-green-600 "
        );
      }
      break;
    case "secondary":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-blue-100 text-blue-700 bg-blue-100 hover:bg-blue-50 focus:shadow-outline-blue active:bg-blue-200"
      );
      break;
    case "plain":
      calculatedClassNames = calculatedClassNames.concat(
        " border border-transparent shadow text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 "
      );
      break;
    case "danger":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-red-100 text-red-700 bg-red-100 hover:bg-red-50 focus:shadow-outline-red active:bg-red-200"
      );
      break;
    case "success":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-green-100 text-green-700 bg-green-100 hover:bg-green-50 focus:shadow-outline-green active:bg-green-200"
      );
      break;
    case "warning":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-yellow-100 text-yellow-700 bg-yellow-100 hover:bg-yellow-50 focus:shadow-outline-yellow active:bg-yellow-200"
      );
      break;
    case "text":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-transparent text-gray-bf-text hover:text-gray-bf-text-light "
      );
      break;
    default:
      break;
  }

  if (fullWidth) {
    calculatedClassNames = calculatedClassNames.concat(" w-full text-center ");
  }

  switch (size) {
    case "xs":
      calculatedClassNames = calculatedClassNames.concat(
        " text-xs px-2.5 py-1.5 leading-4 rounded tracking-wide "
      );
      break;
    case "sm":
      calculatedClassNames = calculatedClassNames.concat(
        " text-xs px-3 py-2 leading-4 rounded-md tracking-widest "
      );
      break;
    case "md":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-4 py-2 leading-5 rounded-md tracking-widest "
      );
      break;
    case "lg":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-4 py-2 leading-6 rounded-md tracking-widest "
      );
      break;
    case "xl":
      calculatedClassNames = calculatedClassNames.concat(
        " text-base px-6 py-3 leading-6 rounded-md tracking-widest "
      );
      break;
    case "2xl":
      calculatedClassNames = calculatedClassNames.concat(
        " text-base px-10 py-5 leading-8 rounded-lg tracking-widest "
      );
      break;
    default:
      break;
  }

  if (props.disabled) {
    calculatedClassNames = calculatedClassNames.concat(
      " opacity-50 cursor-not-allowed "
    );
  }

  return (
    <button
      {...restProps}
      className={`${calculatedClassNames} ${
        version !== "text" && "shadow"
      } font-semibold uppercase cursor-pointer inline-flex justify-center items-center border border-transparent focus:outline-none transition ease-in-out duration-150 ${className}`}
    >
      {loading && <AltLoader className="mr-2" dotSize={16} />}
      {children}
    </button>
  );
}

import { SESSION_LOG_IN, SESSION_LOG_OUT } from "src/redux/actions/session";

const initialState = {
  loggedIn: localStorage.getItem("token") ? true : false
};

export interface ISessionState {
  loggedIn: boolean;
}

function session(state = initialState, action: any) {
  switch (action.type) {
    case SESSION_LOG_IN: {
      return { ...state, loggedIn: true };
    }
    case SESSION_LOG_OUT: {
      return { ...state, loggedIn: false };
    }
    default: {
      return state;
    }
  }
}

export default session;

import React from "react";

export function useLocalStorageState(
  key: string,
  defaultValue: string | Function = "",
  { serialize = JSON.stringify, deserialize = JSON.parse } = {}
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      // the try/catch is here in case the localStorage value was set before
      // we had the serialization in place
      try {
        return deserialize(valueInLocalStorage);
      } catch (error) {
        window.localStorage.removeItem(key);
      }
    }
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  });

  const prevKeyRef = React.useRef(key);

  React.useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    window.localStorage.setItem(key, serialize(state));
  }, [key, state, serialize]);

  return [state, setState];
}

// export function useLocalStorageState(
//   key: string,
//   defaultValue = ""
// ): [string, React.Dispatch<React.SetStateAction<string>>] {
//   const [state, setState] = React.useState(
//     () => window.localStorage.getItem(key) || defaultValue
//   );

//   React.useLayoutEffect(() => {
//     window.localStorage.setItem(key, state);
//   }, [key, state]);

//   return [state, setState];
// }

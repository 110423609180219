import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useCurrentCustomer from "./useCurrentCustomer";

export default function useDetermineRoute() {
  const { currentCustomer } = useCurrentCustomer();
  const history = useHistory();

  useEffect(() => {
    if (currentCustomer && currentCustomer.hasDefaultPaymentMethod) {
      history.push("/continue-at-billfixers");
    }

    if (
      currentCustomer &&
      currentCustomer?.billCount > 0 &&
      !currentCustomer.hasDefaultPaymentMethod
    ) {
      history.push("/show-bills");
    }
  }, [currentCustomer, history]);

  return;
}

import { CheckIcon } from "@heroicons/react/outline";
import React from "react";
import { Field } from "react-final-form";
import "./CheckboxField.css";

interface IProps {
  label: string;
  name: string;
  className?: string;
  validate?: any;
}

export default function CheckboxField({
  label,
  name,
  className,
  validate
}: IProps) {
  return (
    <div className={`CheckboxField-Container ${className}`}>
      <label>{label}</label>
      <Field
        name={name}
        validate={validate}
        render={({ input, meta }) => {
          const checked = input.value === true || input.value === "true";

          const toggle = () => {
            input.onChange(!checked);
          };

          const keyDown = (evt: any) => {
            if (evt.keyCode && evt.keyCode === 32) {
              toggle();
            }
          };

          return (
            <div
              className="CheckboxField"
              role="checkbox"
              aria-checked
              tabIndex={0}
              onKeyDown={keyDown}
              onClick={toggle}
            >
              {checked && <CheckIcon className="text-bf-green-500 w-3 h-3" />}
            </div>
          );
        }}
      />
    </div>
  );
}

import { CheckIcon } from "@heroicons/react/outline";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import NewButton from "../NewButton";
import { TCompactBillFormStep } from "./CompactBillForm";
import DocumentlessStep from "./DocumentlessStep";
import DocumentUploadArea from "./DocumentUploadArea";

export default function DocumentStep({
  bill,
  onComplete,
  onMakeChanges,
  completed
}: TCompactBillFormStep) {
  const [showDocumentlessForm, setShowDocumentlessForm] = useState(false);
  // const [providerInstructions, setProviderInstructions] = useState<
  //   string | number
  // >(0);
  // const [updateBillMutation] = useUpdateBillMutation();

  // const updateBillWithDocuments = async (values: any) => {
  //   const result = await updateBillMutation({
  //     variables: {
  //       id: bill.id,
  //       bill: {
  //         documents: values.documents
  //       }
  //     },
  //     refetchQueries: ["ListBills", "FindBill"]
  //   });

  //   if (result.data?.payload?.success) {
  //     console.log("updateBill success");
  //   }
  // };

  // const toggleExpanded = () => {
  //   providerInstructions === 0
  //     ? setProviderInstructions("auto")
  //     : setProviderInstructions(0);
  // };

  return (
    <React.Fragment>
      <motion.div key={`bill_document_${bill.id}`} className="mb-6">
        <AnimatePresence exitBeforeEnter={false}>
          {completed ? (
            <motion.div
              initial={{ opacity: 0, height: 0, scaleY: 0.2 }}
              animate={{
                opacity: 1,
                height: "auto",
                scaleY: 1
              }}
              exit={{ opacity: 0, height: 0, scaleY: 0.2 }}
              transition={{ duration: 0.3 }}
              key={`documentStep_${bill.id}`}
            >
              {!showDocumentlessForm && (
                <DocumentUploadArea
                  bill={bill}
                  // refetch={["ListBills"]}
                  // helpText="Drag & drop a bill statement here"
                  // subHelpText="We need a copy of your bill before we can get to work."
                  // onComplete={closeDocumentSection}
                  showExistingDocuments
                />
              )}
              {bill.documentCount > 0 && (
                <NewButton
                  fullWidth
                  className="mt-4 font-semibold text-sm tracking-widest"
                  onClick={onComplete}
                >
                  Continue
                </NewButton>
              )}
              {!!bill.providerBillFields.length && bill.documentCount <= 0 && (
                <motion.div className="flex flex-col items-center justify-center mt-4">
                  <div className="flex justify-center">
                    <NewButton
                      version="text"
                      onClick={() =>
                        setShowDocumentlessForm(!showDocumentlessForm)
                      }
                      size="sm"
                    >
                      {showDocumentlessForm
                        ? "Actually, I do have my statement"
                        : "I don't have my bill statement"}
                    </NewButton>
                  </div>
                </motion.div>
              )}

              {showDocumentlessForm && (
                <DocumentlessStep
                  bill={bill}
                  onComplete={() => {
                    onComplete();
                  }}
                />
              )}
              {/* <ABTest
                abTestId="abt_4f280f5f4673041b0fba2f33e3041e0f"
                variantAId="abv_76f5f145bd824cff3c3e421d49f389ff"
                variantBId="abv_0b6f7922b15580577bcde53084dacd37"
              >
                <div className="mt-8">
                  <div
                    onClick={toggleExpanded}
                    className="text-xs text-gray-bf-text-light text-center uppercase tracking-widest font-semibold cursor-pointer"
                  >
                    Not sure how to find your bill statement?
                  </div>
                  <div className="text-sm">
                    <div
                      className="flex items-center justify-center mt-4"
                      onClick={toggleExpanded}
                      style={
                        providerInstructions === "auto"
                          ? {
                              transform: "rotate(180deg)",
                              transition: "150ms ease-out"
                            }
                          : { transition: "100ms ease-out" }
                      }
                    >
                      <Icon
                        name="caretDown"
                        className="pointer"
                        size={16}
                        color={Color.grey300 as any}
                      />
                    </div>
                    <AnimateHeight duration={250} height={providerInstructions}>
                      <div
                        className="my-4 ml-4 text-gray-bf-text"
                        dangerouslySetInnerHTML={{
                          __html: ProviderDownloadInstructions(bill.provider)
                        }}
                      />
                    </AnimateHeight>
                  </div>
                </div>
                <div />
              </ABTest> */}
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, height: 0, scaleY: 0.2 }}
              animate={{
                opacity: 1,
                height: "auto",
                scaleY: 1
              }}
              exit={{ opacity: 0, height: 0, scaleY: 0.2 }}
              transition={{ duration: 0.3 }}
              key="documentStepComplete"
              className="flex items-center justify-between"
            >
              <div className="flex items-center justify-between">
                <div
                  className="flex justify-center items-center rounded-full bg-bf-green-500 mr-4"
                  style={{ padding: "1px" }}
                >
                  <CheckIcon className="w-4 h-4 text-white" />
                </div>
                <div className="text-sm text-gray-pogo-gray">
                  Document info added
                </div>
              </div>
              <div
                className="text-xs uppercase tracking-wider font-semibold text-gray-400 hover:text-gray-pogo-gray cursor-pointer"
                onClick={onMakeChanges}
              >
                Change
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </React.Fragment>
  );
}

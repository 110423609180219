import React from "react";
import { useHistory } from "react-router-dom";
import CompactBillForm from "src/components/CompactBillForm/CompactBillForm";
import { useListBillsQuery } from "src/components/graphql";
import NewContainer from "src/components/NewContainer";

export default function ShowBills() {
  const history = useHistory();
  const { data } = useListBillsQuery();

  const bills = data?.bills.map((b) => (
    <CompactBillForm
      key={b.id}
      bill={b}
      onFixMyBill={() => history.push("/payment")}
    />
  ));

  return <NewContainer className="w-full md:w-5/6">{bills}</NewContainer>;
}

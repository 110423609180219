import React, { useState } from "react";
import { Form } from "react-final-form";
import { useUpdateBillMutation } from "../graphql";
import NewButton from "../NewButton";
import ProviderBillFieldRenderer from "../ProviderBillFieldRenderer";
import Well from "../Well";
import { TCompactBillFormStep } from "./CompactBillForm";

export default function DocumentlessStep({
  bill,
  onComplete
}: TCompactBillFormStep) {
  const [fieldValuesMissing, setFieldValuesMissing] = useState(false);
  const [fieldValuesProvided, setFieldValuesProvided] = useState(0);
  const [updateBillMutation] = useUpdateBillMutation();

  const updateBill = async (values: any) => {
    const pbfKeys = Object.keys(values).filter((k) => k.startsWith("pbf_"));
    let pbfObjects = pbfKeys.map((k) => {
      const strippedKey = k.substring(4);

      // Don't allow undefined, null, or empty strings as values
      if (values[k] === undefined || values[k] === null || values[k] === "") {
        return null;
      }

      return { [strippedKey]: values[k] };
    });

    // Remove any nulls we may have added above from empty string, undefined, and null check
    pbfObjects = pbfObjects.filter((v) => v !== null);

    // If we don't have enough fields provided by the customer to satisfy the amount we need,
    // don't let the form submission continue and toggle the state that shows we need more info
    if (pbfObjects.length < bill.provider.totalRequiredProviderBillFields) {
      setFieldValuesMissing(true);
      setFieldValuesProvided(pbfObjects.length);
      return null;
    } else {
      setFieldValuesMissing(false);
    }

    const result = await updateBillMutation({
      variables: {
        id: bill.id,
        bill: {
          documentless: true,
          providerSpecificFields: pbfObjects
        }
      },
      refetchQueries: ["FindCurrentCustomer"]
    });

    if (result.data?.payload?.success) {
      onComplete();
    }
  };

  const answersStillNeeded =
    bill.provider.totalRequiredProviderBillFields - fieldValuesProvided;

  const providerBillFieldMarkup = bill.providerBillFields.map((pbf) => (
    <ProviderBillFieldRenderer key={pbf.id} providerBillField={pbf} />
  ));

  return (
    <React.Fragment>
      <Well sunken className="text-gray-pogo-gray mt-2 mb-6">
        Without a bill statement, we'll need a few details about your bill so
        that we can get access to negotiate. Any fields marked with a red
        asterisk <span className="text-red-600 text-xl leading-none">*</span>{" "}
        are required.
      </Well>
      <Form
        initialValues={bill}
        onSubmit={updateBill}
        render={({ handleSubmit, invalid, pristine }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              {fieldValuesMissing && (
                <div className="rounded-md p-2 bg-yellow-200 text-yellow-900 mt-4">
                  <p>
                    We need responses to at least{" "}
                    {bill.provider.totalRequiredProviderBillFields} of the
                    fields below to negotiate your bill without a statement.
                  </p>
                  <p className="mt-2">
                    Please fill out {answersStillNeeded} more field
                    {answersStillNeeded > 1 && "s"} to continue.
                  </p>
                </div>
              )}
              {providerBillFieldMarkup}
              <NewButton
                className={`w-full mt-4 font-semibold text-sm tracking-widest py-4 sm:py-2`}
                type="submit"
                disabled={pristine || invalid}
                fullWidth
              >
                Continue
              </NewButton>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
}

import { CheckIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import CancelBill from "src/components/CancelBill";
import { IBillFragment } from "src/components/graphql";
import Segment from "src/components/Segment";
import { useLocalStorageState } from "src/hooks/useLocalStorageState";
import NewButton from "../NewButton";
import BillInfoStep from "./BillInfoStep";
import BillTogglesStep from "./BillTogglesStep";
import DocumentStep from "./DocumentStep";

export type TCompactBillFormStep = {
  bill: IBillFragment;
  completed?: boolean;
  onComplete: () => void;
  onMakeChanges?: () => void;
};

interface IProps {
  bill: IBillFragment;
  active?: boolean;
  onDocumentStepCompleted?: () => void;
  onInfoStepComplete?: () => void;
  onFixMyBill?: () => void;
}

export default function CompactBillForm({
  bill,
  active = true,
  onInfoStepComplete,
  onFixMyBill
}: IProps) {
  const [formComplete, setFormComplete] = useLocalStorageState(
    `${bill.provider.name}${bill.id}FormComplete`
  );
  const [infoStepCompleted, setInfoStepCompleted] = useLocalStorageState(
    `${bill.provider.name}${bill.id}InfoStepCompleted`
  );
  const [showDocumentStep, setShowDocumentStep] = useState(true);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    if (bill.documentCount === 0 && !bill.documentless) {
      setShowDocumentStep(true);
    } else if (bill.documentless) {
      setShowDocumentStep(false);
    } else if (infoStepCompleted === "true") {
      setShowDocumentStep(false);
    } else {
      setShowDocumentStep(true);
    }
  }, [bill.documentCount, bill.documentless, infoStepCompleted]);

  return (
    <React.Fragment>
      <motion.div layout key={bill.id} className="relative mb-8 w-full">
        <Segment style={{ padding: "1rem" }} htmlId={`bill_form_${bill.id}`}>
          <div className="h-full">
            <div className="grid grid-rows-1 grid-cols-1 md:grid-cols-4 gap-2 pt-6">
              <div className="col-span-1 flex flex-col items-center justify-start">
                <img
                  src={bill.provider.logo?.smallUrl}
                  alt={bill.provider.name}
                  className="mx-4 mb-4 md:mb-0"
                />
              </div>
              {isActive ? (
                <div className="md:col-span-3">
                  <DocumentStep
                    bill={bill}
                    onComplete={() => {
                      setShowDocumentStep(false);
                    }}
                    completed={showDocumentStep}
                    onMakeChanges={() => setShowDocumentStep(true)}
                  />
                  {!showDocumentStep && (
                    <React.Fragment>
                      <div className="my-6">
                        <BillInfoStep
                          bill={bill}
                          onComplete={() => {
                            setInfoStepCompleted("true");
                            if (onInfoStepComplete) {
                              onInfoStepComplete();
                            }
                            const element = window.document.getElementById(
                              `bill_form_${bill.id}`
                            );
                            element?.scrollIntoView({
                              behavior: "smooth",
                              block: "center"
                            });
                          }}
                          completed={infoStepCompleted === "true"}
                          onMakeChanges={() => setInfoStepCompleted("false")}
                        />
                      </div>
                      {infoStepCompleted && (
                        <div className="my-6">
                          <BillTogglesStep
                            bill={bill}
                            onComplete={() => {
                              setFormComplete("true");
                              if (onFixMyBill) {
                                onFixMyBill();
                              }
                              const element = window.document.getElementById(
                                `bill_${bill.id}`
                              );
                              element?.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                              });
                            }}
                            completed={formComplete === "true"}
                            onMakeChanges={() => setFormComplete("false")}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <div
                  className="md:col-span-3"
                  onClick={() => setIsActive(true)}
                >
                  <div className="flex h-full flex-col items-start justify-center cursor-pointer">
                    <div className="flex items-center justify-between mb-4">
                      <div
                        className={`flex justify-center items-center rounded-full ${
                          bill.documentCount > 0
                            ? "bg-bf-green-500"
                            : "bg-white border border-gray-400"
                        } mr-4`}
                        style={{ padding: "1px" }}
                      >
                        <CheckIcon className="w-4 h-4 text-white" />
                      </div>
                      <div className="text-sm text-gray-pogo-gray">
                        Document info added
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-4">
                      <div
                        className={`flex justify-center items-center rounded-full ${
                          infoStepCompleted
                            ? "bg-bf-green-500"
                            : "bg-white border border-gray-400"
                        } mr-4`}
                        style={{ padding: "1px" }}
                      >
                        <CheckIcon className="w-4 h-4 text-white" />
                      </div>
                      <div className="text-sm text-gray-pogo-gray">
                        Information saved
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div
                        className={`flex justify-center items-center rounded-full ${
                          formComplete
                            ? "bg-bf-green-500"
                            : "bg-white border border-gray-400"
                        } mr-4`}
                        style={{ padding: "1px" }}
                      >
                        <CheckIcon className="w-4 h-4 text-white" />
                      </div>
                      <div className="text-sm text-gray-pogo-gray">
                        Negotiation preferences recorded
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {formComplete && (
              <div className="flex justify-center items-center">
                <Link to="/payment" className="w-full">
                  <NewButton onClick={() => null} fullWidth>
                    Continue
                  </NewButton>
                </Link>
              </div>
            )}
            {/* {isActive && (
              <div
                className={`w-full px-4 mb-4 flex items-center justify-end`}
              >
                <CancelBill bill={bill} />
              </div>
            )} */}
          </div>
        </Segment>
      </motion.div>
    </React.Fragment>
  );
}

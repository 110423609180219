import apollo from "src/apollo";
import { host, protocol } from "src/config";

// USE AS A LAST RESORT TO NUKE LOCALSTORAGE AND LOGOUT
export function haltAndCatchFire(errorMessage?: string) {
  const storedEmail = localStorage.getItem("email");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
  apollo.clearStore();
  // We have to do this because we have PWA features enabled with our serviceWorkerRegistration.ts
  // If we don't manually clear the caches, the cached version of the app will be served to the user
  // until they close all browser tabs running billfixers.
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }

  const rotateToken = async () => {
    const url = `${protocol}://${host}/api/sessions/rotate_customer_token`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email: storedEmail })
    });

    if (response.status === 200) {
      console.log("200");
    }
  };

  rotateToken();

  window.location.href = "/";
}

import React from "react";
import { useHistory } from "react-router-dom";
import BlankBillForm from "src/components/BlankBillform";
import NewContainer from "src/components/NewContainer";

export default function AddBill() {
  const history = useHistory();

  return (
    <NewContainer className="w-full md:w-5/6">
      <BlankBillForm
        hideHeader
        onSuccess={() => {
          history.push("/show-bills");
        }}
        htmlId="blank_bill_form"
      />
      {/* <OneShotBillForm /> */}
    </NewContainer>
  );
}

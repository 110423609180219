import React from "react";

interface IProps {
  width?: number;
}

export default function PogoLogo({ width = 94 }: IProps) {
  return (
    <svg
      width={width}
      height={width * (47 / width)}
      viewBox="0 0 94 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00107411 12.5072C0.39194 4.90043 6.54575 0.5 12.4923 0.5C19.3904 0.5 25 6.10989 25 13.0045C25 19.8991 19.3883 25.5085 12.4901 25.5085C11.8878 25.5076 11.2863 25.4635 10.6903 25.3767V33.7753C10.6901 33.9676 10.6136 34.1519 10.4775 34.2878C10.3414 34.4238 10.1569 34.5 9.96459 34.5H6.04823C5.85635 34.4993 5.67256 34.4227 5.53708 34.2868C5.4016 34.151 5.32546 33.9671 5.32532 33.7753V14.0013C5.32518 13.8091 5.24874 13.6249 5.11278 13.489C4.97682 13.3531 4.79248 13.2767 4.60021 13.2766H0.727283C0.628312 13.2761 0.53045 13.2557 0.439644 13.2163C0.34884 13.177 0.266988 13.1195 0.199065 13.0476C0.131141 12.9757 0.0785635 12.8907 0.0445274 12.7978C0.0104913 12.7048 -0.00429258 12.606 0.00107411 12.5072ZM10.6925 19.9145C11.2473 20.063 11.819 20.14 12.3934 20.1437C16.1497 20.1437 19.6356 17.2912 19.6384 13.004C19.6384 9.06732 16.4339 5.86427 12.4951 5.86427C9.19605 5.86427 5.77336 8.23428 5.38854 12.4754C5.38073 12.5749 5.39359 12.6748 5.42634 12.769C5.45909 12.8633 5.51098 12.9497 5.57879 13.0229C5.6466 13.0961 5.72884 13.1545 5.82032 13.1944C5.9118 13.2342 6.01056 13.2548 6.11035 13.2545H9.96679C10.1593 13.2545 10.3438 13.331 10.4799 13.467C10.616 13.6031 10.6925 13.7875 10.6925 13.9799V19.9145Z"
        fill="#0E0E0E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 23.4999C25 17.4325 29.9345 12.5 35.9983 12.5C42.0627 12.5 47 17.4325 47 23.4999C47 29.5674 42.0623 34.5 35.9983 34.5C29.9345 34.5 25 29.5674 25 23.4999ZM32.8269 28.2464C33.7657 28.8737 34.8693 29.2087 35.9983 29.2087V29.2103C37.5114 29.2086 38.9619 28.6068 40.0319 27.5369C41.102 26.4671 41.704 25.0164 41.7062 23.5033C41.7068 22.374 41.3726 21.2699 40.7458 20.3308C40.119 19.3916 39.2278 18.6594 38.1849 18.2268C37.142 17.7942 35.9942 17.6807 34.8868 17.9006C33.7794 18.1205 32.7621 18.6639 31.9634 19.4622C31.1648 20.2604 30.6209 21.2777 30.4004 22.3851C30.1799 23.4926 30.2927 24.6405 30.7247 25.6839C31.1567 26.7271 31.8882 27.6189 32.8269 28.2464Z"
        fill="#0E0E0E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.3757 17.2267C68.7301 18.817 69.9708 21.1183 69.9708 23.6819C69.9704 25.8136 69.3703 27.9007 68.2412 29.6963C69.3905 31.3648 70 33.2184 70 35.3215C70 41.4875 65.0717 46.5 59.0141 46.5C56.2324 46.5007 53.5542 45.4271 51.5205 43.4959C49.4867 41.5648 48.2493 38.9202 48.058 36.0965C48.0513 35.9967 48.0648 35.8965 48.0978 35.8023C48.1307 35.7081 48.1823 35.6217 48.2494 35.5487C48.3166 35.4756 48.3976 35.4173 48.4878 35.3774C48.578 35.3376 48.6753 35.317 48.7736 35.317H52.6425C52.8202 35.3184 52.991 35.3868 53.1219 35.5089C53.253 35.6309 53.3348 35.7981 53.3516 35.9781C53.5141 37.4614 54.2086 38.9074 55.314 39.8669C56.0929 40.5419 57.0355 40.9914 58.0436 41.1688C59.0516 41.346 60.0879 41.2443 61.0441 40.8744C62.8836 40.1699 64.2104 38.4849 64.5959 36.5228C64.805 35.4844 64.7357 34.4079 64.3951 33.4063C62.7452 34.3566 60.8818 34.8563 58.9859 34.8572C52.9262 34.8572 48 29.8445 48 23.6785C48 17.5125 52.9283 12.5 58.9859 12.5H69.2578C69.4473 12.5005 69.6288 12.5774 69.7625 12.714C69.8961 12.8504 69.9711 13.0355 69.9708 13.2282V16.4985C69.971 16.594 69.9526 16.6887 69.9168 16.7769C69.8809 16.8653 69.8284 16.9454 69.7621 17.0131C69.6958 17.0808 69.6171 17.1345 69.5303 17.1711C69.4436 17.2077 69.3507 17.2266 69.2568 17.2267H67.3757ZM55.7469 28.5038C56.6844 29.1413 57.7867 29.4816 58.9145 29.4816C60.4258 29.4798 61.8747 28.8683 62.9435 27.781C64.0123 26.6938 64.6137 25.2196 64.6159 23.6819C64.6161 22.5344 64.2821 21.4126 63.6558 20.4584C63.0296 19.5041 62.1393 18.7603 61.0975 18.321C60.0557 17.8816 58.9093 17.7665 57.8032 17.9902C56.6971 18.2137 55.6811 18.7662 54.8836 19.5774C54.0861 20.3888 53.5429 21.4224 53.3228 22.5478C53.1026 23.6733 53.2155 24.8397 53.6469 25.8999C54.0784 26.9601 54.8092 27.8662 55.7469 28.5038Z"
        fill="#0E0E0E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72 23.5017C72 17.4355 76.9326 12.5 82.9982 12.5C89.0636 12.5 94 17.4351 94 23.5017C94 29.5683 89.0679 34.5 83.0002 34.5C76.9326 34.5 72 29.5677 72 23.5017ZM79.8259 28.2443C80.7649 28.8716 81.8688 29.2063 82.9982 29.2062V29.2078C84.5112 29.206 85.9619 28.6042 87.032 27.5345C88.1021 26.4648 88.7043 25.0145 88.7063 23.5017C88.707 22.3726 88.3728 21.2687 87.7458 20.3295C87.1189 19.3904 86.2275 18.6584 85.1843 18.2259C84.1412 17.7934 82.9932 17.6799 81.8857 17.8998C80.778 18.1198 79.7604 18.6632 78.9618 19.4614C78.163 20.2595 77.619 21.2766 77.3986 22.384C77.1781 23.4913 77.291 24.6391 77.7232 25.6823C78.1552 26.7254 78.887 27.617 79.8259 28.2443Z"
        fill="#0E0E0E"
      />
    </svg>
  );
}

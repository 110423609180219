import React, { CSSProperties } from "react";
import { isMobile } from "react-device-detect";

interface ISegmentProps {
  children: any;
  className?: string;
  htmlId?: string;
  style?: CSSProperties;
  padding?: number;
  /**
   * If true, the Segment will show a border
   */
  bordered?: boolean;
  elevated?: boolean;
}

export default function Segment(props: ISegmentProps) {
  const {
    children,
    className,
    htmlId,
    style,
    padding = isMobile ? 1 : 2,
    bordered,
    elevated = true,
  } = props;

  return (
    <React.Fragment>
      <div
        id={htmlId}
        className={`${className}`}
        style={{
          padding: `${padding}rem`,
          border: bordered ? "1px solid var(--border-grey)" : "",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          boxShadow: elevated ? "0px 15px 30px rgba(67, 72, 87, 0.1)" : "",
          ...style,
        }}
      >
        {children}
      </div>
    </React.Fragment>
  );
}
